import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'

const useStyle = createUseStyles((theme: LightTheme) => ({
  dot: {
    cursor: 'pointer',
    backgroundColor: ({ active }) =>
      active ? theme.colors.lightBlue : theme.colors.title,
    width: ({ active }) => (active ? 56 : 12),
    height: 6,
    borderRadius: 3,
    transition: 'width 0.5s ease-in-out 0s',
    '&:not(:first-child)': {
      marginLeft: 4
    }
  }
}))

export type OptionsDotProps = {
  active: boolean
  onClick: () => void
}

export const OptionsDot: FC<OptionsDotProps> = ({ active, onClick }) => {
  const theme = useTheme()
  const classes = useStyle({ theme, active })
  return <div className={classes.dot} onClick={onClick} />
}
