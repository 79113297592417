import { Locale } from '../../services'
import { config as mainConfig } from '../../config'

export const config = (locale: Locale) => ({
  version: '1.33.0',
  api: {
    apiKey: 'd1c0fc5b-b20d-48a6-9d76-1bdf963187b5',
    primarySeed: '26c5da37-28e3-4aba-a012-c2578d65a7e2',
    secondarySeed: '4957ea01-f5bd-4e31-adb8-5eb81a16a7cd',
    mediaGalleryId: '7db6ece1-299f-4f42-a7e9-897f4b6eb0c5',
    chatbotId: 'd014311e-6b95-4f67-894f-5a688b5c6c84',
    disableUsers: [],
    playerText: 'Sluit je aan bij de live uitzending',
    legalReference: 'Hyundai Belux'
  },
  general: {
    googleAnalytics: false,
    language: locale,
    textsURL: '',
    texts: {
      companyName: 'Hyundai',
      privacyPolicy: `https://www.hyundai.be/${locale}/legal.html`
    },
    privacyPolicyURL: `https://www.hyundai.be/${locale}/legal.html`,
    styles: {
      direction: 'ltr',
      theme: 'light',
      fonts: [
        {
          name: 'Hyundai Sans Head',
          urls: [
            {
              format: 'woff2',
              url:
                'https://static.whisbi.com/3b3b630f-f34f-4621-9c23-3c5425d5ca1e/fonts/HyundaiSansHead-Regular.woff2'
            }
          ],
          googleFont: false
        }
      ],
      fontFamily: '"Hyundai Sans Head", Arial, sans-serif',
      textColor: '#333',
      linkColor: '#002c5e',
      primaryColor: '#0274c8',
      primaryTextColor: '#fff',
      secondaryColor: '#fff',
      secondaryTextColor: '#666',
      buttonRadius: '0px',
      header: {
        fontFamily: '"Hyundai Sans Head", Arial, sans-serif',
        fontSize: '',
        fontWeight: '500',
        backgroundColor: '#002c5e',
        textTransform: '',
        textColor: '#fff'
      },
      titles: {
        fontFamily: '"Hyundai Sans Head", Arial, sans-serif',
        fontWeight: '500',
        fontSize: '16px'
      },
      inputs: { fontFamily: '"Hyundai Sans Head", Arial, sans-serif' },
      floatingButton: {
        borderColor: 'linear-gradient(#012C60, #01AAD2)',
        collapseType: 'icon',
        onDesktop: { marginY: '12px', marginX: '12px' },
        onMobile: { marginY: '12px', marginX: '12px' }
      }
    }
  },
  oneToOne: {
    pin: true,
    cmb: true,
    onDesktop: { connectForm: true, inbound: true },
    onMobile: { connectForm: true, inbound: true },
    defaultUserPictures: [],
    desktopDynamicPhones: true,
    autoOpen: {
      onDesktop: { enabled: false, closeable: true },
      onMobile: { enabled: false, closeable: true }
    },
    chatbot: {},
    visibilityType: 1,
    ratingType: 'none',
    form: {
      name: { visible: true, mandatory: false, hasTitle: true },
      email: { visible: false, mandatory: false },
      phone: { visible: true, mandatory: true },
      availableCountryCodes: ['BE', 'NL', 'FR', 'LU'],
      defaultCountryCode: mainConfig.country === 'LUX' ? 'LU' : 'BE',
      preferredCountryCodes: [],
      conversationalMode: true
    },
    schedule: {
      name: { visible: true, mandatory: false },
      email: { visible: false, mandatory: false },
      timeInterval: 30,
      maxBookingRange: 60,
      exceptions: {},
      availableHours: [
        [['10:00', '20:00']],
        [['10:00', '20:00']],
        [['10:00', '20:00']],
        [['10:00', '20:00']],
        [['10:00', '20:00']],
        [[]],
        [[]]
      ],
      dateFormat: 'dd-mm-yy'
    },
    texts: {
      header: '',
      formTitle: '',
      floatingButton: {
        default: { body: '', yesCTA: '', noCTA: '' },
        returning: { body: '', yesCTA: '', noCTA: '' }
      },
      liveHelpRibbon: '',
      thankYou: { mainTitle: '', subTitle: '' },
      outOfHours: { mainTitle: '', subTitle: '' }
    },
    styles: {
      body: {
        backgroundColor: '#EBEBEB',
        backgroundImage: {
          url: '',
          repeat: 'repeat',
          size: 'auto',
          position: 'center'
        }
      },
      liveHelpRibbon: { backgroundColor: '#e96413', textColor: '#fff' },
      floatingButton: { onMobile: { maxUserPictures: 3 } }
    }
  },
  oneToMany: {
    oneToOneDisplay: true,
    autoOpen: {
      onDesktop: { enabled: false, closeable: true },
      onMobile: { enabled: false, closeable: true }
    },
    chatbot: {
      minimumTime: 10,
      externalURL: '',
      pictureURL: '',
      minimumChats: 0,
      closeOnClick: false
    },
    maxInputLength: 140,
    texts: {
      header: '',
      floatingButton: {
        default: { body: '', yesCTA: '', noCTA: '' },
        returning: { body: '', yesCTA: '', noCTA: '' }
      },
      liveHelpRibbon: '',
      chatbot: {
        firstMessage: { body: '', bodyNoName: '', yesCTA: '', noCTA: '' },
        secondMessage: { body: '', bodyNoName: '', yesCTA: '' }
      }
    },
    styles: {
      body: { backgroundColor: '#EBEBEB' },
      floatingButton: {
        blinkLabel: {
          backgroundColor: '#007db4',
          textColor: '#fff',
          fontSize: ''
        }
      },
      liveHelpRibbon: { backgroundColor: '#e96413', textColor: '#fff' },
      videoOverlay: '0%'
    },
    activeViewerForm: { name: { maxLength: 20, maxWords: 2 } }
  }
})

export type WhisbiConfig = typeof config
