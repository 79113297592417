import React, { FC, MouseEvent } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { ModelItemWithPriceType } from '../../providers'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    margin: '8px 0px',
    cursor: 'pointer'
  },
  image: {
    width: 280,
    height: 120,
    minHeight: 120,
    objectFit: 'cover',
    background: theme.colors.background
  },
  imageContainer: {
    width: 280,
    height: 120
  },
  text: {
    cursor: 'pointer',
    marginTop: 8
  },
  price: {
    marginTop: 2
  },
  fullPrice: {
    cursor: 'pointer'
  },
  catalogPrice: {
    cursor: 'pointer',
    marginLeft: 4,
    textDecoration: 'line-through'
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      width: '100%'
    },
    image: {
      width: '100%',
      overflow: 'hidden'
    },
    imageContainer: {
      overflow: 'hidden',
      width: '100%'
    }
  }
}))

export interface ModelItemWithPriceProps extends ModelItemWithPriceType {
  familyName: string
}

export const ModelItemWithPrice: FC<ModelItemWithPriceProps> = ({
  className = '',
  value,
  image,
  text,
  price,
  catalogPrice,
  familyName,
  ...props
}) => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyle({ theme })

  const handleOnClickModel = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    history.push(`/family/${familyName}/car/${value}`)
    event.stopPropagation()
  }

  return (
    <Column
      {...props}
      className={`${className} ${classes.container}`}
      alignItems="flex-start"
      onClick={handleOnClickModel}
    >
      <Row className={classes.imageContainer}>
        <img className={classes.image} src={image} />
      </Row>
      <Text color="black" className={classes.text} text={text} preset="h5" />
      <Row className={classes.text}>
        <Text
          className={classes.fullPrice}
          color="second"
          text={`€ ${price}`}
        />
        <Text
          className={classes.catalogPrice}
          color="second"
          text={catalogPrice}
        />
      </Row>
    </Column>
  )
}
