import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { Color, LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'

export const useStyle = createUseStyles({
  container: {
    cursor: 'pointer'
  }
})

export type FullLogoProps = {
  color?: Color
  className?: string
  onClick?: () => void
}

export const FullLogo: FC<FullLogoProps> = ({ className, color, onClick }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const history = useHistory()
  const fill = color && theme.colors[color]

  const handleOnHome = () => {
    history.push('/')
    if (onClick) {
      onClick()
    }
  }
  return (
    <Row className={`${className} ${classes.container}`} onClick={handleOnHome}>
      <Icon name={IconName.LOGO} width={54} height={20} fill={fill} />
      <Icon name={IconName.LOGO_TEXT} fill={fill} />
    </Row>
  )
}
