import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { Column } from '../column'
import { Row } from '../row'
import { Text, TextProps } from '../text'
import { Button } from '../button'
import { LightTheme } from '../../theme'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxWidth: 'calc(100vw - 48px)',
    width: 397,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    padding: 24
  },
  text: {
    textAlign: 'left'
  },
  submit: { flex: 2 },
  moreButton: {
    flex: 1,
    textDecoration: 'none',
    marginLeft: 24
  },
  row: {
    marginTop: 24
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      width: 'calc(100vw - 96px)'
    }
  }
}))

export type CookiesBannerProps = {
  moreInfoLink: string
  onSubmit: () => void
}

export const CookiesBanner: FC<CookiesBannerProps> = ({
  moreInfoLink,
  onSubmit
}) => {
  const theme = useTheme()
  const classes = useStyle({ theme })
  const buttonSuccessTextProps: TextProps = {
    tx: 'Cookie.banner.02',
    text: 'I agree'
  }

  const buttonMoreTextProps: TextProps = {
    tx: 'Cookie.banner.03',
    text: 'More info'
  }

  const handleOnSubmit = () => {
    onSubmit()
  }

  return (
    <Column className={classes.container}>
      <Text tx="Cookie.banner.01" text="cookiesText" />
      <Row className={classes.row}>
        <Button
          className={classes.submit}
          preset="outlined"
          text={buttonSuccessTextProps}
          onClick={handleOnSubmit}
        />
        <a href={moreInfoLink} target="_blank" rel="noreferrer">
          <Button
            className={classes.moreButton}
            preset="outlined"
            borderColor="main"
            color="main"
            text={buttonMoreTextProps}
          />
        </a>
      </Row>
    </Column>
  )
}
