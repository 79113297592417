import React, {
  ImgHTMLAttributes,
  DetailedHTMLProps,
  HTMLAttributes,
  FC
} from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderWidth: 3,
    borderStyle: 'solid',
    borderImage: theme.colors.gradient,
    borderRadius: 56,
    padding: 2
  },
  image: {
    borderRadius: 44,
    width: 44,
    height: 44,
    objectFit: 'cover',
    overflow: 'hidden'
  }
}))

export type ChatAvatarImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export interface ChatAvatarProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  img: ChatAvatarImageProps
}

export const ChatAvatar: FC<ChatAvatarProps> = ({
  img: imgProps,
  className,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyle({ theme })
  return (
    <div {...props} className={`${className} ${classes.container}`}>
      <img {...imgProps} className={`${imgProps.className} ${classes.image}`} />
    </div>
  )
}
