import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'

import { Button, ButtonProps } from '../button'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

export type ChatFormProps = {
  name: string
  question: string
  button: ButtonProps
  timeTx?: string
  timeDefaultText: string
  className?: string
}

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 16,
    backgroundColor: theme.colors.white,
    padding: '12px 24px',
    width: 264
  },
  titleContainer: {
    width: '100%'
  },
  question: {
    maxWidth: 160,
    marginTop: 16,
    textAlign: 'left'
  },
  button: {
    marginTop: 16
  }
}))

export const ChatForm: FC<ChatFormProps> = ({
  className,
  name,
  question,
  timeTx,
  timeDefaultText,
  button: buttonProps
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  return (
    <Column
      className={`${className || ''} ${classes.container}`}
      alignItems="flex-start"
    >
      <Row className={classes.titleContainer} justifyContent="space-between">
        <Text text={name} preset="title" />
        <Text
          tx={timeTx}
          text={timeDefaultText}
          preset="title"
          color="hyundai"
        />
      </Row>
      <Text className={classes.question} text={question} />
      <Button
        {...buttonProps}
        className={`${buttonProps.className || ''} ${classes.button}`}
      />
    </Column>
  )
}
