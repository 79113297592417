import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'

import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  innerContainer: {
    cursor: 'pointer',
    width: 'calc(100% - 40px)',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.pink}`,
    padding: '14px 16px',
    borderRadius: 4
  },
  container: {
    cursor: 'pointer',
    width: '100%'
  },
  valueText: {
    marginTop: 4
  },
  label: {
    cursor: 'pointer',
    position: 'relative',
    transform: ({ value }) =>
      value ? 'scale(1)' : 'scale(1.1667) translate(6px, 3px)',
    transition: 'transform 0.5s ease-in-out'
  },
  itemsContainerRelative: {
    position: 'relative',
    width: '100%'
  },
  itemsContainer: {
    alignItems: 'flex-start',
    backgroundColor: theme.colors.pink,
    borderRadius: 4,
    padding: '8px 2px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 3
  },
  item: {
    cursor: 'pointer',
    padding: '8px 14px',
    width: 'calc(100% - 26px)',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.colors.white,
      color: theme.colors.lightBlue,
      textDecoration: 'underline'
    }
  },
  content: {
    width: '100%'
  },
  arrow: {
    transform: ({ visible }) => (visible ? 'rotate(270deg)' : 'rotate(90deg)'),
    transition: 'transform 0.5s ease-in-out'
  }
}))

export type DropdownItemProps = {
  text: string
  value: string
  tx?: string
}

export type DropdownProps = {
  className?: string
  label: string
  labelTx?: string
  tx?: boolean
  data: DropdownItemProps[]
  onChange: (value: string) => void
}

export const Dropdown: FC<DropdownProps> = ({
  className = '',
  data,
  label,
  labelTx,
  onChange
}) => {
  const [value, setValue] = useState<string>('')
  const [visible, setVisible] = useState(false)
  const theme = useTheme()
  const classes = useStyle({ theme, value, visible })
  const intl = useIntl()

  const handleOnOpen = () => {
    setVisible(!visible)
  }

  const handleOnChange = (item: DropdownItemProps) => () => {
    const text = intl.formatMessage({
      id: item.tx,
      defaultMessage: item.text
    })
    setValue(text)
    onChange(item.value)
    setVisible(false)
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      alignItems="flex-start"
      onClick={handleOnOpen}
    >
      <Row className={classes.innerContainer} justifyContent="space-between">
        <Column
          className={classes.content}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text
            className={classes.label}
            tx={labelTx}
            text={label}
            preset="text"
            color="title"
          />
          <Text
            className={classes.valueText}
            text={value}
            preset="h7"
            color="main"
          />
        </Column>
        <Row className={classes.arrow}>
          <Icon name={IconName.ARROW} />
        </Row>
      </Row>
      {visible && (
        <Column className={classes.itemsContainerRelative}>
          <Column className={classes.itemsContainer}>
            {data.map((item, index) => {
              return (
                <Text
                  key={`${label}_${index}`}
                  className={classes.item}
                  text={item.text}
                  tx={item.tx}
                  onClick={handleOnChange(item)}
                />
              )
            })}
          </Column>
        </Column>
      )}
    </Column>
  )
}
