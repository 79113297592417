import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const LogoText: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={105}
      height={16}
      viewBox="0 0 105 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.527 15.712H44.1c2.102 0 2.967-.604 2.924-3.616V.716h-3.281V11.42c0 1.284-.156 1.44-1.176 1.44h-5.758V.714h-3.28v14.997zm67.792.003h3.281V.718h-3.281v14.997zm-51.015-15h10.572c1.507 0 2.967.21 2.924 3.24v11.757h-3.281V5.032c0-1.29-.156-1.563-1.176-1.563h-5.758v12.243h-3.281V.715zm-30.578 0l4.113 6.13 4.064-6.13h3.984l-6.41 9.588v5.409h-3.28v-5.41L15.742.715h3.983zM.2.715h3.281v5.911h7.34V.716h3.282v14.996h-3.281v-6.12h-7.34v6.12H.2V.715zm77.19 4.142c0-.82-.504-1.388-1.386-1.388h-5.642v9.37h5.642c1.285-.065 1.386-.83 1.386-1.438V4.857zm3.32-.902v8.116c0 2.584-1.46 3.641-3.28 3.641H67.08V.715h10.35c2.794 0 3.28 1.766 3.28 3.24zM98.039.718v14.997h-3.281v-4.311h-7.485v4.311h-3.28V3.958c0-2.077.846-3.24 3.28-3.24h10.766zm-3.281 2.754h-6.162c-1.034.06-1.323.315-1.323 1.563v3.44h7.485V3.471z"
        fill={props.fill || theme.colors.hyundai || '#012C60'}
      />
    </svg>
  )
}

export default LogoText
