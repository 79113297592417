import React, { FC, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { LightTheme } from '../../theme'
import { Column, FullLogo, Icon, IconName, Row, Text } from '../../components'
import { useCars, useUnity, useWhisbi } from '../../providers'
import { config } from '../../config'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    zIndex: 4,
    backgroundColor: theme.colors.white,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  innerContainer: {
    padding: '80px 72px',
    height: 'calc(100vh - 160px)',
    width: 'calc(100vw - 144px)'
  },
  field: {
    width: '100%'
  },
  topField: {
    width: '100%'
  },
  icon: {
    cursor: 'pointer'
  },
  logo: {},
  back: {
    cursor: 'pointer'
  },
  '@media screen and (max-width: 1024px)': {
    innerContainer: {
      padding: 16,
      height: 'calc(100vh - 122px)',
      width: 'calc(100vw - 32px)',
      marginBottom: 90
    },
    topField: {
      flexDirection: 'row-reverse'
    },
    logo: {
      opacity: 0
    }
  }
}))

export type TestDriveFormSuccessProps = {}

export const TestDriveFormSuccess: FC<TestDriveFormSuccessProps> = () => {
  const { context: unityContext } = useUnity()
  const theme: LightTheme = useTheme()
  const { getCarByName } = useCars()
  const { openWhisbi, hideWhisbi } = useWhisbi()
  const classes = useStyle({ theme })
  const location = useLocation()
  const history = useHistory()
  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]
  const carData = getCarByName(familyName, carName)

  useEffect(() => {
    if (carData) {
      const tagManagerArgs = {
        dataLayer: {
          virtualPageTitle: 'Thank you screen',
          virtualPageURL: `${config.publicURL}/family/${familyName}/car/${carName}/test-drive-success`,
          event: 'VirtualPageview'
        },
        dataLayerName: 'dataLayer'
      }

      TagManager.dataLayer(tagManagerArgs)
    }
    hideWhisbi()
    return () => {
      openWhisbi()
      if (carData) {
        unityContext.send('ProjectContext(Clone)', 'SetCarState', carData.id)
      }
    }
  }, [])

  const handleOnClick = () => {
    history.push(`/family/${familyName}/car/${carName}/`)
  }

  return (
    <Column className={classes.container}>
      <Column className={classes.innerContainer} justifyContent="space-between">
        <Row className={classes.topField} justifyContent="space-between">
          <div />
          <FullLogo className={classes.logo} />
          <Icon
            className={classes.icon}
            name={IconName.CLOSE}
            onClick={handleOnClick}
          />
        </Row>
        <Column className={classes.field}>
          <Text
            tx="Testdrive.form.20"
            text="Thanks"
            preset="successMain"
            color="main"
          />
        </Column>
        <Row className={classes.field} justifyContent="flex-end">
          <Text
            className={classes.back}
            tx="Testdrive.form.21"
            text="Back to showroom"
            color="second"
            preset="h5"
            onClick={handleOnClick}
          />
        </Row>
      </Column>
    </Column>
  )
}
