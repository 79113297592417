import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Ar: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M15.847 18.95v-3.642l-3.586-1.793M19.434 13.515l-3.587 1.793M15.847 22.552v4.13l-3.586-1.762M19.434 24.92l-3.587 1.762M21 20.368l3.587 1.744L21 23.955M10.087 19.758L6.5 21.502l3.587 1.843M21 12.587l3.587-1.744L21 9M10.087 12.587L6.5 10.843 10.087 9M15.847 10.13V6l-3.586 1.762M19.434 7.762L15.847 6M6.5 17.39V21.5M6.5 14.956v-4.112M24.587 14.956v-4.112M24.587 18v4.112"
        stroke={props.fill || theme.colors.white}
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Ar
