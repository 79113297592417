import React, { FC } from 'react'

import { IconName, IconProps } from './types'
import { Loader } from './loader'
import { Logo } from './logo'
import { LogoText } from './logo-text'
import { SoundOff } from './sound-off'
import { Options } from './options'
import { Close } from './close'
import { Arrow } from './arrow'
import { Huinday } from './huindai'
import { Back } from './back'
import { Eco } from './eco'
import { CloseRound } from './close-round'
import { Search } from './search'
import { SoundOn } from './sound-on'
import { BeginArrow } from './begin-arrow'
import { Play } from './play'
import { Ar } from './ar'
import { PointerTranslate } from './pointer-translate'
import { PointerButton } from './pointer-button'
import { PointerUser } from './pointer-user'

export const Icon: FC<IconProps> = (props) => {
  switch (props.name) {
    case IconName.LOADER:
      return <Loader {...props} />
    case IconName.LOGO:
      return <Logo {...props} />
    case IconName.LOGO_TEXT:
      return <LogoText {...props} />
    case IconName.SOUND_OFF:
      return <SoundOff {...props} />
    case IconName.SOUND_ON:
      return <SoundOn {...props} />
    case IconName.OPTIONS:
      return <Options {...props} />
    case IconName.CLOSE:
      return <Close {...props} />
    case IconName.HUINDAY:
      return <Huinday {...props} />
    case IconName.ARROW:
      return <Arrow {...props} />
    case IconName.BACK:
      return <Back {...props} />
    case IconName.ECO:
      return <Eco {...props} />
    case IconName.CLOSE_ROUND:
      return <CloseRound {...props} />
    case IconName.SEARCH:
      return <Search {...props} />
    case IconName.BEGIN_ARROW:
      return <BeginArrow {...props} />
    case IconName.PLAY:
      return <Play {...props} />
    case IconName.AR:
      return <Ar {...props} />
    case IconName.POINTER_TRANSLATE:
      return <PointerTranslate {...props} />
    case IconName.POINTER_BUTTON:
      return <PointerButton {...props} />
    case IconName.POINTER_USER:
      return <PointerUser {...props} />
    default:
      return <div />
  }
}
