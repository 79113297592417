import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { isIOS } from 'react-device-detect'

import { useAudioBackground } from '../../providers'
import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    maxWidth: 'calc(100vw - 80px)',
    padding: 40
  },
  playContainer: {
    width: 100,
    height: 40
  },
  barContainer: {
    marginLeft: 8,
    backgroundColor: theme.colors.white,
    maxWidth: 554,
    height: 4,
    maxHeight: 4,
    width: '100%'
  },
  bar: {
    width: ({ width }) => width || 0,
    height: 4,
    maxHeight: 4,
    backgroundColor: theme.colors.lightBlue
  },
  '@media screen and (max-width: 1024px)': {
    barContainer: {
      maxWidth: 'calc(100vw - 40px)'
    },
    container: {
      bottom: 110
    }
  },
  '@media screen and (orientation: landscape)': {
    container: {
      left: 'auto',
      right: 0
    }
  }
}))

export type AudioPlayerProps = {
  audioUrl: string
}

export const AudioPlayer: FC<AudioPlayerProps> = ({ audioUrl }) => {
  const theme: LightTheme = useTheme()
  const [audio] = useState(new Audio(audioUrl))
  const [playing, setPlaying] = useState(false)
  const [widthBar, changeWidthBar] = useState(0)
  const classes = useStyle({ theme, width: `${widthBar}%` })
  const { muted } = useAudioBackground()

  useEffect(() => {
    const timer = setInterval(() => {
      changeWidthBar((audio.currentTime / audio.duration) * 100)
    }, 100)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    audio.muted = muted
  })

  useEffect(() => {
    audio.muted = muted
    if (!isIOS) {
      audio.play()
      setPlaying(true)
    }
    audio.loop = false
    return () => {
      audio.muted = true
      audio.pause()
      audio.currentTime = 0
      setPlaying(false)
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  const handleOnPlay = () => {
    audio.muted = muted
    audio.play()
    setPlaying(true)
  }

  const handleOnSkip = () => {
    audio.pause()
    audio.currentTime = 0
    setPlaying(false)
  }
  return (
    <Row className={classes.container} justifyContent="flex-start">
      <Row className={classes.playContainer}>
        {!playing ? (
          <Icon
            name={IconName.PLAY}
            onClick={handleOnPlay}
            height={40}
            width={40}
          />
        ) : (
          <Row onClick={handleOnSkip}>
            <Text text="Skip Intro" tx="Interior" color="white" />
            <Icon
              name={IconName.ARROW}
              fill={theme.colors.white}
              width={16}
              height={16}
            />
          </Row>
        )}
      </Row>
      <Row className={classes.barContainer} justifyContent="flex-start">
        <div className={classes.bar} />
      </Row>
    </Row>
  )
}
