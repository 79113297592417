import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { OverflowLoader, useLocale } from '../../components'
import { UnityContainer } from '../unity'
import { SelligentContextProvider, selligentContext } from '../../providers'
import { meta } from '../../seo-strings'
import { CarLoader } from '../../components/car-loader'

export const MainContainer = () => {
  const [loading, setLoading] = useState(true)
  const [loadingPercent, setLoadingPercent] = useState('0%')
  const { currentLocale } = useLocale()
  const title = meta.home.title[currentLocale]
  const desc = meta.home.desc[currentLocale]
  const previewTitle = meta.preview.title[currentLocale]
  const previewDescription = meta.preview.description[currentLocale]

  const handleOnChangeLoadingPercent = (percent: string) => {
    setLoadingPercent(percent)
  }

  const handleOnComplete = (state: boolean) => {
    setLoading(state)
  }
  return (
    <SelligentContextProvider.Provider value={selligentContext}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={previewTitle} />
        <meta property="og:description" content={previewDescription} />
      </Helmet>
      <div className="App">
        <OverflowLoader
          loading={loading}
          valueLoading={loadingPercent}
          onComplete={handleOnComplete}
          onChangeLoadingPercent={handleOnChangeLoadingPercent}
        />
        <CarLoader loading={loading} />
        <UnityContainer loading={loading} loadingPercent={loadingPercent} />
      </div>
    </SelligentContextProvider.Provider>
  )
}
