import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Color, LightTheme } from '../../theme'
import { Text, TextProps } from '../text'

interface ButtonPropsWithColor extends TextProps {
  color: Color
  borderColor: Color
  backgroundColor: Color
}

const useStyles = createUseStyles((theme: LightTheme) => ({
  outlined: {
    backgroundColor: theme.colors.white,
    color: ({ color }: ButtonPropsWithColor) =>
      theme.colors[color] || theme.colors.second,
    border: ({ borderColor }: ButtonPropsWithColor) =>
      `1px solid ${theme.colors[borderColor] || theme.colors.second}`,
    borderRadius: 24,
    padding: '8px 28px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      borderColor: theme.colors.lightBlue,
      color: theme.colors.white,
      padding: '8px 28px',
      borderRadius: 24
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  'outlined-big': {
    backgroundColor: theme.colors.transparent,
    cursor: 'pointer',
    color: theme.colors.second,
    border: `1px solid ${theme.colors.second}`,
    borderRadius: 24,
    padding: '16px 32px',
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      borderColor: theme.colors.lightBlue,
      color: theme.colors.white,
      padding: '16px 32px',
      borderRadius: 24
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  text: {
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filled: {
    backgroundColor: ({ backgroundColor }: ButtonPropsWithColor) =>
      theme.colors[backgroundColor] || theme.colors.lightBlue,
    cursor: 'pointer',
    color: theme.colors.white,
    borderRadius: 32,
    padding: '16px 32px',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.white,
      padding: '16px 32px',
      borderRadius: 32
    }
  },
  'mini-filled': {
    backgroundColor: ({ backgroundColor }: ButtonPropsWithColor) =>
      theme.colors[backgroundColor] || theme.colors.lightBlue,
    cursor: 'pointer',
    color: theme.colors.white,
    borderRadius: 32,
    padding: '12px 16px',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.white,
      padding: '12px 16px',
      borderRadius: 24
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  }
}))

const presets = {
  outlined: 'outlined',
  'outlined-big': 'outlined-big',
  filled: 'inhirit',
  'mini-filled': 'mini-filled'
}

export type ButtonPresets = keyof typeof presets

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: TextProps
  preset: ButtonPresets
  backgroundColor?: Color
  borderColor?: Color
  color?: Color
}

export const Button: FC<ButtonProps> = ({
  text: textProps,
  preset,
  className,
  borderColor,
  color,
  backgroundColor,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyles({ borderColor, color, backgroundColor, theme })
  return (
    <button {...props} className={`${className} ${classes[preset]}`}>
      <Text className={classes.text} {...textProps} />
    </button>
  )
}
