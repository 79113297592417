import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useAudioBackground, useUnity } from '../../providers'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { FooterLoader } from '../footer-loading'
import { useLocale } from '../locale'
import { Icon, IconName } from '../icon'
import { Animation } from '../logo-animation'

const useStyles = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    backgroundColor: theme.colors.main,
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: ({ fadeOut }) => (fadeOut ? 0 : 1),
    transition: 'opacity 0.5s ease-in-out'
  },
  mutedIcon: {
    position: 'absolute',
    top: 44,
    right: 120,
    cursor: 'pointer',
    zIndex: 3
  },
  bottomBlockContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 2
  },
  cookies: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    right: 0,
    zIndex: 2
  },
  '@media screen and (max-width: 1024px)': {
    mutedIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      cursor: 'pointer',
      zIndex: 3
    }
  }
}))

export type CarLoaderProps = {
  loading: boolean
}

export const CarLoader: FC<CarLoaderProps> = ({
  loading,
  children,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const { currentLocale } = useLocale()
  const { context: unityContext } = useUnity()
  const { handleOnMute, muted } = useAudioBackground()
  const [visible, changeVisible] = useState(true)
  const [fadeOut, changeFadeOut] = useState(false)

  useEffect(() => {
    const timeout = fadeOut
      ? setTimeout(() => {
          changeVisible(false)
        }, 1000)
      : changeVisible(true)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [fadeOut])

  const styleProps = {
    ...props,
    fadeOut,
    theme
  }
  const classes = useStyles(styleProps)

  const handleChangeOnMuted = () => {
    handleOnMute(!muted)
  }

  const handleOnStartLoading = (state: number) => {
    if (!loading) {
      if (state) {
        changeFadeOut(true)
      } else {
        changeVisible(true)
        changeFadeOut(false)
      }
    }
  }

  unityContext.on('UnityStartLoadingHandler', handleOnStartLoading)

  const iconName = muted ? IconName.SOUND_OFF : IconName.SOUND_ON

  if (visible) {
    return (
      <Column className={classes.container} justifyContent="flex-end">
        <Icon
          className={classes.mutedIcon}
          name={iconName}
          onClick={handleChangeOnMuted}
        />
        <Animation
          secondStart
          currentLocale={currentLocale}
          onLoad={() => {}}
        />
        <Column className={classes.bottomBlockContainer}>
          <FooterLoader />
        </Column>
      </Column>
    )
  }
  return <></>
}
