import { Locale } from './services'

export const meta: any = {
  preview: {
    title: {
      [Locale.NL]: 'Bezoek onze digitale showroom',
      [Locale.FR]: 'Visitez notre showroom digital'
    },
    description: {
      [Locale.NL]:
        'Ontdek het volledige Hyundai gamma in onze digitale showroom en vraag jouw persoonlijke offerte aan.',
      [Locale.FR]:
        'Découvrez toute la gamme Hyundai dans notre showroom digital et demandez votre offre personnalisée.'
    }
  },
  home: {
    title: {
      [Locale.NL]: 'Welkom in de Hyundai Digital Showroom',
      [Locale.FR]: 'Bienvenue dans le Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Als jij niet naar het Autosalon kan, brengen we het Autosalon naar jou. Maak kennis met onze onze digitale showroom.',
      [Locale.FR]:
        "Si vous ne pouvez pas aller au Salon de l'auto, c'est le Salon de l'auto qui vient à vous."
    }
  },
  City: {
    title: {
      [Locale.NL]: 'Stadswagens - Hyundai Digital Showroom',
      [Locale.FR]: 'Citadines - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Ontdek ons breed gamma aan stadswagens in de Hyundai Digital Showroom. Gezellig van thuis uit!',
      [Locale.FR]:
        'Découvrez notre large gamme de citadines dans le Hyundai Digital Showroom depuis chez vous !'
    }
  },
  SUV: {
    title: {
      [Locale.NL]: "SUV's - Hyundai Digital Showroom",
      [Locale.FR]: 'SUV - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        "Ontdek ons breed gamma aan SUV's in de Hyundai Digital Showroom. Gezellig van thuis uit!",
      [Locale.FR]:
        "Découvrez notre large gamme de SUV's dans le Hyundai Digital Showroom depuis chez vous !"
    }
  },
  Family: {
    title: {
      [Locale.NL]: 'Familiewagens - Hyundai Digital Showroom',
      [Locale.FR]: 'Familiales - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Ontdek ons breed gamma aan familiewagens in de Hyundai Digital Showroom. Gezellig van thuis uit!',
      [Locale.FR]:
        'Découvrez notre large gamme de Familiales dans le Hyundai Digital Showroom depuis chez vous !'
    }
  },
  i10: {
    title: {
      [Locale.NL]: 'Hyundai i10 - Hyundai Digital Showroom',
      [Locale.FR]: 'Hyundai i10 - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De Hyundai i10 is uitgerust met de laatste nieuwe tech snufjes. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'La Hyundai i10 est équipée avec des options technologiques dernier cri. Découvrez-la dans notre Digital Showroom.'
    }
  },
  i20: {
    title: {
      [Locale.NL]: 'Hyundai i20 - Hyundai Digital Showroom',
      [Locale.FR]: 'Hyundai i20 - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De verbeterde veiligheidsuitrusting maakt de straten voor iedereen veiliger. Ontdek de i20 in onze Digitale Showroom',
      [Locale.FR]:
        'La i20 offre des technologies sophistiquées et une connectivité parfaite. Découvrez-la dans notre Digital Showroom'
    }
  },
  i30: {
    title: {
      [Locale.NL]: 'Hyundai i30 - Hyundai Digital Showroom',
      [Locale.FR]: 'Hyundai i30 - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De nieuwe i30 biedt een nooit geziene waaier aan motoren. Ontdek ze in onze Digitale Showroom',
      [Locale.FR]:
        'La nouvelle i30 offre de nombreuses options de motorisation sans précédent. Découvrez-les dans notre Digital Showroom'
    }
  },
  i30_wagon: {
    title: {
      [Locale.NL]: 'i30 Wagon - Hyundai Digital Showroom',
      [Locale.FR]: 'i30 Wagon - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De nieuwe i30 Wagon is klaar voor alles wat jouw levensstijl van een auto vraagt. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'La nouvelle i30 Wagon est conçue autour de l’espace et de la polyvalence. Découvrez-la dans notre Digital Showroom'
    }
  },
  i30_fastback: {
    title: {
      [Locale.NL]: 'i30 Fastback - Hyundai Digital Showroom',
      [Locale.FR]: 'i30 Fastback - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Maak een statement met de nieuwe i30 Fastback. Ontdek de Hyundai i30 Fastback in onze Digitale Showroom',
      [Locale.FR]:
        'Affirmez votre style avec la nouvelle i30 Fastback. Découvrez la Hyundai i30 Fastback dans notre Digital Showroom'
    }
  },
  ioniq_hybrid: {
    title: {
      [Locale.NL]: 'IONIQ Hybrid - Hyundai Digital Showroom',
      [Locale.FR]: 'IONIQ Hybrid - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De Hyundai IONIQ Hybrid is uitgerust met een uiterst krachtige elektromotor. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'Difficile de décrire le plaisir de conduite, il faut l’expérimenter. Découvrez la IONIQ Hybrid dans le Digital Showroom'
    }
  },
  'ioniq_plug-in_hybrid': {
    title: {
      [Locale.NL]: 'IONIQ Plug-in Hybrid - Hyundai Digital Showroom',
      [Locale.FR]: 'IONIQ Plug-in Hybrid - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Voor mensen die graag het beste van beide werelden hebben. Ontdek de IONIQ Plug-in Hybrid in onze Digitale Showroom',
      [Locale.FR]:
        'Pour ceux qui veulent avoir le meilleur de deux mondes. Découvrez la IONIQ Plug-in Hybrid dans notre Digital Showroom'
    }
  },
  ioniq_electric: {
    title: {
      [Locale.NL]: 'IONIQ Electric - Hyundai Digital Showroom',
      [Locale.FR]: 'IONIQ Electric - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De wagen met praktisch rijbereik voor je dagelijks gebruik. Ontdek de Hyundai IONIQ Electric in onze Digitale Showroom',
      [Locale.FR]:
        'Moteur électrique haute puissance. Découvrez la Hyundai IONIQ Electric dans notre Digital Showroom'
    }
  },
  kona: {
    title: {
      [Locale.NL]: 'Hyundai KONA - Hyundai Digital Showroom',
      [Locale.FR]: 'Hyundai KONA - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Ontdek de Hyundai KONA in onze Digitale Showroom, een compacte SUV die je helemaal naar je hand kan zetten',
      [Locale.FR]:
        'Un SUV compact que vous pouvez personnaliser à volonté. Découvrez le Hyundai KONA dans notre Digital Showroom'
    }
  },
  kona_hybrid: {
    title: {
      [Locale.NL]: 'KONA Hybrid - Hyundai Digital Showroom',
      [Locale.FR]: 'KONA Hybrid - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De nieuwe KONA Hybrid schakelt naadloos tussen de benzinemotor en de elektromotor. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'Le KONA Hybrid bascule rapidement entre son moteur essence et électrique. Découvrez-la dans notre Digital Showroom'
    }
  },
  kona_electric: {
    title: {
      [Locale.NL]: 'KONA Electric - Hyundai Digital Showroom',
      [Locale.FR]: 'KONA Electric - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'Elektrisch rijden zonder toegevingen. 100% jouw stijl. Ontdek de Hyundai KONA Electric in onze Digitale Showroom.',
      [Locale.FR]:
        'Roulez à l’électrique sans compromis. 100% votre style. Découvrez le Hyundai KONA Electric dans notre Digital Showroom'
    }
  },
  tucson: {
    title: {
      [Locale.NL]: 'Hyundai Tucson - Hyundai Digital Showroom',
      [Locale.FR]: 'Hyundai Tucson - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De gloednieuwe Tucson is nog sterker, nog slimmer en nog beter ontworpen. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'Le tout nouveau Tucson est encore plus robuste, intelligent et mieux conçu. Découvrez-le dans notre Digital Showroom'
    }
  },
  tucson_hybrid: {
    title: {
      [Locale.NL]: 'Tucson Hybrid - Hyundai Digital Showroom',
      [Locale.FR]: 'Tucson Hybrid - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        "Onze Tucson is nu ook in 'mild hybrid', hybride en plug-in hybride beschikbaar. Ontdek hem in onze Digitale Showroom",
      [Locale.FR]:
        'Le Tucson est disponible en version "mild hybrid", hybride & plug-in hybride. Découvrez-le dans notre Digital Showroom'
    }
  },
  santa_fe_hybrid: {
    title: {
      [Locale.NL]: 'Santa Fe Hybrid - Hyundai Digital Showroom',
      [Locale.FR]: 'Santa Fe Hybrid - Hyundai Digital Showroom'
    },
    desc: {
      [Locale.NL]:
        'De nieuwe Santa Fe Hybrid tilt de brandstofefficiëntie naar een hoger niveau. Ontdek hem in onze Digitale Showroom',
      [Locale.FR]:
        'Le nouveau Santa Fe Hybrid porte le rendement énergétique au niveau supérieur. Découvrez-le dans notre Digital Showroom'
    }
  }
}
