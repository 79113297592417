import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import Unity from 'react-unity-webgl'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'

import { Column, IntroPanel, Row, useLocale } from '../../components'
import {
  FamilyPage,
  CityPage,
  InteriorPage,
  TestRequestPage,
  TestDriveFormSuccess
} from '../../pages'
import { useUnity } from '../../providers'
import { config } from '../../config'
import { LightTheme } from '../../theme'
import { ArTest } from '../../pages/ar-test'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  innerContainer: {
    position: 'relative',
    zIndex: 1,
    width: '100%'
  },
  intro: {
    position: 'fixed',
    left: 0,
    right: 0
  },
  portalContainer: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    zIndex: 2,
    height: '100%',
    right: 0,
    bottom: 0,
    width: ({ open }) => (!open ? 0 : 'auto')
  },
  unity: {},
  '@media screen and (max-width: 1024px)': {
    container: {
      height: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    innerContainer: {
      height: '100%'
    },
    unity: {
      height: '100vh'
    },
    portalContainer: {
      left: 0,
      right: 'auto',
      overflowY: 'scroll',
      height: 'calc(100% - 134px)',
      top: ({ open }) => (open ? 100 : '100%'),
      transition: 'top 0.5s ease-in-out',
      width: () => '100%',
      maxWidth: 428,
      zIndex: 3
    }
  },
  '@media screen and (max-width: 455px)': {
    portalContainer: {
      top: ({ open }) => (open ? 234 : '100%'),
      maxWidth: '100%'
    },
    intro: {
      top: 64
    }
  },
  '@media screen and (max-height: 455px)': {
    intro: {
      top: 64
    }
  }
}))

export type LoadEvent = {
  state: boolean
  percent: string // How percent, for example 10%
}

export type UnityContainerProps = {
  loading: boolean
  loadingPercent: string
}

export const UnityContainer: FC<UnityContainerProps> = ({
  loading,
  loadingPercent
}) => {
  const { context: unityContext } = useUnity()
  const history = useHistory()
  const location = useLocation()
  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]

  const { currentLocale } = useLocale()
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const classes = useStyle({ open, theme })

  useEffect(() => {
    const mode = config.country === 'LUX' ? 1 : 0
    unityContext.send('ProjectContext(Clone)', 'SetLuxemburgPricing', mode)
    unityContext.send('ProjectContext(Clone)', 'SetLanguage', currentLocale)
  }, [])

  const handleOnOpen = (state: boolean) => {
    setOpen(state)
  }

  const handleOnOpenCity = (carId: string) => {
    unityContext.send('ProjectContext(Clone)', 'SetCarState', carId)
  }

  const handleOnOpenHub = () => {
    unityContext.send('ProjectContext(Clone)', 'SetHubState')
  }

  const handleOnBackTestDrive = () => {
    history.push(`/family/${familyName}/car/${carName}`)
  }

  const styleHeight = '100vh'
  const styleWidth = '100vw'
  return (
    <Column alignItems="flex-start" className={classes.container}>
      <Column className={classes.innerContainer}>
        <Unity
          className={classes.unity}
          unityContext={unityContext}
          height={styleHeight}
          width={styleWidth}
        />
        <Row className={classes.intro}>
          <IntroPanel />
        </Row>
        <Switch>
          <Route path="/family/:family_id/car/:car_id/interior">
            {!loading && loadingPercent === '100%' && (
              <InteriorPage onOpen={handleOnOpen} />
            )}
          </Route>
          <Route path="/family/:family_id/car/:car_id/test-drive-request">
            {!loading && loadingPercent === '100%' && (
              <TestRequestPage onBack={handleOnBackTestDrive} />
            )}
          </Route>
          <Route path="/family/:family_id/car/:car_id/test-drive-success">
            {!loading && loadingPercent === '100%' && <TestDriveFormSuccess />}
          </Route>

          <Route exact path="/family/:family_id/car/:car_id">
            {!loading && loadingPercent === '100%' && (
              <CityPage onOpen={handleOnOpen} onOpenCity={handleOnOpenCity} />
            )}
          </Route>

          <Route exact path="/test-ar">
            {!loading && loadingPercent === '100%' && <ArTest />}
          </Route>

          <Route exact path="/">
            {!loading && loadingPercent === '100%' && (
              <FamilyPage onOpen={handleOnOpen} onOpenHub={handleOnOpenHub} />
            )}
          </Route>
          <Redirect to="/" />
        </Switch>
        <Column
          id="details-menu"
          justifyContent="flex-start"
          className={classes.portalContainer}
        ></Column>
      </Column>
    </Column>
  )
}
