import React, { FC, useCallback, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import { useCars, useUnity, useWhisbi } from '../../providers'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Dropdown } from '../dropdown'
import { Row } from '../row'
import { Text, TextProps } from '../text'
import { TextField } from '../text-field'
import { TextArea } from '../text-area'
import { Checkbox } from '../checkbox'
import { Search } from '../search'
import { Button } from '../button'
import { useLocale } from '../locale'
import { TestDriveData } from '../../services'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    width: '100%',
    minHeight: '100vh'
  },
  innerContainer: {
    margin: '116px 0'
  },
  title: {
    padding: 16
  },
  textArea: {
    marginTop: 16
  },
  columnDataContainer: {
    width: 376,
    margin: '40px 20px 0px 20px'
  },
  titleCategories: {
    margin: '4px 0'
  },
  remarks: {
    marginTop: 64
  },
  field: {
    marginTop: 12
  },
  error: {
    marginTop: 16
  },
  button: {
    marginTop: 16,
    width: '100%'
  },
  mobileColumn: {},
  '@media screen and (max-width: 786px)': {
    mobileColumn: {
      width: '100%',
      flexDirection: 'column'
    },
    container: {
      width: 'calc(100vw - 32px)',
      padding: '40px 16px',
      justifyContent: 'flex-start'
    },
    columnDataContainer: {
      width: '100%',
      margin: 0
    },
    remarks: {
      marginTop: 16
    },
    title: {
      textAlign: 'left'
    },
    innerContainer: {
      margin: '0px 0'
    }
  }
}))

const initialState: TestDriveData = {
  clientType: '',
  model: '',
  gender: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  description: '',
  dealer: '',
  language: 'NL',
  country: 'BE',
  policy: false
}

export type TestRequestFormProps = {}

export const TestRequestForm: FC<TestRequestFormProps> = () => {
  const { context: unityContext } = useUnity()
  const { hideWhisbi, openWhisbi } = useWhisbi()
  const location = useLocation()
  const history = useHistory()
  const {
    dealers,
    getDealers,
    getCarByName,
    sentRequestToTestDrive
  } = useCars()
  const { currentLocale } = useLocale()
  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]
  const carData = getCarByName(familyName, carName)
  const carTitle = carData ? carData.title : 'Not found'
  const [testDriveData, setTestDriveData] = useState<TestDriveData>({
    ...initialState,
    model: carTitle
  })
  const [error, setError] = useState('')
  const [errorTx, setErrorTx] = useState('')
  const delayedQuery = useCallback(
    _.debounce((city: string) => getDealers(city), 2000),
    []
  )

  useEffect(() => {
    unityContext.send('ProjectContext(Clone)', 'SetCaptureAllKeyboardInput', 0)
    hideWhisbi()
    getDealers('')

    return () => {
      openWhisbi()
    }
  }, [])

  const buttonTextProps: TextProps = {
    preset: 'h7',
    color: 'second',
    tx: 'test.description.sent',
    text: 'Sent'
  }

  const moreInfoLink = isMobile
    ? `https://m.hyundai.be/${currentLocale}/legal`
    : `https://www.hyundai.be/${currentLocale}/legal.html`

  const intl = useIntl()
  const theme = useTheme()
  const classes = useStyle({ theme })
  const dataTypeClient = [
    {
      text: 'Private',
      value: 'Private',
      tx: 'test.client.private'
    },
    {
      text: 'Entity',
      value: 'Entity',
      tx: 'test.client.entity'
    }
  ]
  const genderClient = [
    {
      text: 'Male',
      value: 'M',
      tx: 'test.gender.male'
    },
    {
      text: 'Female',
      value: 'F',
      tx: 'test.gender.female'
    }
  ]

  const handleOnChangeSearch = (city: string) => {
    if (city.length > 2 || city.length === 0) {
      delayedQuery(city)
    }
  }

  const handleOnChange = (prop: keyof TestDriveData) => (
    value: string | boolean
  ) => {
    const newState = { ...testDriveData, [prop]: value }
    setTestDriveData(newState)
  }

  const handleOnSubmit = () => {
    const notFilled = Object.entries(testDriveData).filter((item) => {
      if (item[0] === 'description') {
        return false
      }
      return Boolean(!item[1])
    })

    if (notFilled.length > 0) {
      setErrorTx('test.error')
      setError('Please, fill the email and opt fields.')
    } else {
      sentRequestToTestDrive(testDriveData)
      history.push(`/family/${familyName}/car/${carName}/test-drive-success`)
      setErrorTx('')
      setError('')
    }
  }

  const message = intl.formatMessage({
    id: 'test.title',
    defaultMessage: 'Request a Testdrive with'
  })

  const headerWithModal = `${message} ${carTitle}`

  return (
    <Column className={classes.container}>
      <Column className={classes.innerContainer} alignItems="flex-start">
        <Text className={classes.title} text={headerWithModal} preset="h4" />
        <Row className={classes.mobileColumn} alignItems="flex-start">
          <Column
            alignItems="flex-start"
            className={classes.columnDataContainer}
          >
            <Text
              className={classes.titleCategories}
              color="second"
              preset="h5"
              text="Personal information"
              tx="test.personal.title"
            />
            <Dropdown
              className={classes.field}
              data={dataTypeClient}
              label="Type de Client"
              labelTx="test.personal.type.client"
              onChange={handleOnChange('clientType')}
            />
            <Dropdown
              className={classes.field}
              data={genderClient}
              labelTx="test.personal.type.record"
              label="DHR or MVR?"
              onChange={handleOnChange('gender')}
            />
            <TextField
              id="firstName"
              className={classes.field}
              labelTx="test.personal.first.name"
              label="First Name"
              onChange={handleOnChange('firstName')}
            />
            <TextField
              id="lastName"
              className={classes.field}
              labelTx="test.personal.last.name"
              label="Last Name"
              onChange={handleOnChange('lastName')}
            />
            <TextField
              id="email"
              className={classes.field}
              labelTx="test.personal.email"
              label="Email"
              onChange={handleOnChange('email')}
            />
            <TextField
              id="phoneNumber"
              className={classes.field}
              labelTx="test.personal.phone.number"
              label="Tel"
              onChange={handleOnChange('phoneNumber')}
            />
          </Column>
          <Column
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.columnDataContainer}
          >
            <Text
              className={classes.titleCategories}
              color="second"
              preset="h5"
              text="Choose hyundai-verdeler"
              tx="test.distributor.title"
            />
            <Search
              className={classes.field}
              data={dealers}
              placeholder="Rechercher une opération"
              placeholderTx="test.distributor.search"
              onChange={handleOnChangeSearch}
              onClick={handleOnChange('dealer')}
            />
            <Text
              className={classes.remarks}
              color="second"
              preset="h5"
              text="Any Remarks?"
              tx="test.description.title"
            />
            <TextArea
              className={classes.textArea}
              placeholder="Write here your remarks"
              placeholderTx="test.description.placeholder"
              onChange={handleOnChange('description')}
            />
            <Checkbox
              className={classes.field}
              label="Policy Privacy OPT-in"
              labelTx="test.description.policy"
              placeholder="privacy opt in"
              placeholderTx="test.description.policy.placeholder"
              href={moreInfoLink}
              onChange={handleOnChange('policy')}
            />
            <Text
              className={classes.error}
              tx={errorTx}
              text={error}
              preset="text"
              color="red"
            />
            <Button
              className={classes.button}
              text={buttonTextProps}
              preset="outlined-big"
              onClick={handleOnSubmit}
            />
          </Column>
        </Row>
      </Column>
    </Column>
  )
}
