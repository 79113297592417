import React, { ChangeEvent, FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { createUseStyles, useTheme } from 'react-jss'
import { Dealer } from '../../services/api.types'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { SearchItem } from './search-item'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(100% - 8px)',
    borderRadius: 4,
    backgroundColor: theme.colors.pink
  },
  inputContainer: {
    width: 'calc(100% - 32px)',
    padding: '4px 16px'
  },
  input: {
    width: '100%',
    ...theme.typography.h7,
    color: theme.colors.main,
    backgroundColor: theme.colors.transparent,
    marginTop: 4,
    border: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  bottomRelativeContainer: {
    position: 'relative',
    width: '100%'
  },
  bottomContainer: {
    width: '100%',
    marginTop: 12,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1
  },
  borderedContainer: {
    width: 'calc(100% - 28px)',
    padding: 8,
    border: `2px solid ${theme.colors.lightGrey}`,
    borderRadius: 4,
    backgroundColor: theme.colors.white,
    alignItems: 'flex-start',
    margin: '8px 0'
  },
  scrolledArea: {
    width: '100%',
    overflowY: 'scroll',
    position: 'absolute',
    top: 8,
    zIndex: 1,
    maxHeight: 260
  },
  searchValue: {
    height: 20
  },
  searchDescription: {
    height: 11
  }
}))

export type SearchProps = {
  data: Dealer[]
  className?: string
  placeholder: string
  placeholderTx?: string
  onChange?: (city: string) => void
  onClick: (id: string) => void
  onFocus?: () => void
}
export type ActiveItem = {
  name: string
  postCode: string
  city: string
}

export const Search: FC<SearchProps> = ({
  data,
  className = '',
  placeholder,
  placeholderTx,
  onClick,
  onChange,
  onFocus
}) => {
  const intl = useIntl()
  const message = intl.formatMessage({
    id: placeholderTx || 'test.description.placeholder',
    defaultMessage: placeholder || 'Write here your remarks'
  })
  const theme = useTheme()
  const classes = useStyle({ theme })
  const [value, setValue] = useState<string>('')
  const [activeItem, changeActiveItem] = useState<ActiveItem>({
    name: '',
    postCode: '',
    city: ''
  })
  const [modalVisible, changeModalVisible] = useState<boolean>(false)
  const handleModalVisible = () => {
    if (onFocus) {
      onFocus()
    }
    changeModalVisible(true)
  }
  const handleModalHide = () => {
    changeModalVisible(false)
    setValue('')
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue } = event.target

    if (onChange) {
      onChange(eventValue)
    }

    if (eventValue.length > 0) {
      handleModalVisible()
    }
    setValue(eventValue)
  }

  const handleOnClick = (
    id: string,
    name: string,
    postCode: string,
    city: string
  ) => {
    const item = {
      id,
      name,
      postCode,
      city
    }
    changeActiveItem(item)
    setValue(name)
    onClick(String(id))
    handleModalHide()
  }

  return (
    <>
      <Column className={`${className} ${classes.container}`}>
        <Row className={classes.inputContainer} justifyContent="space-between">
          <input
            id="search"
            autoComplete="hidden"
            autoCorrect="off"
            className={classes.input}
            value={value}
            placeholder={message}
            onChange={handleOnChange}
            onFocus={handleModalVisible}
          />
          {modalVisible ? (
            <Icon
              name={IconName.CLOSE}
              onClick={handleModalHide}
              height={32}
              width={32}
            />
          ) : (
            <Icon name={IconName.SEARCH} />
          )}
        </Row>

        <Column className={classes.bottomRelativeContainer}>
          {modalVisible && (
            <div className={classes.scrolledArea}>
              <Column>
                {data.map((item) => (
                  <SearchItem
                    key={`dealer_${item.id}`}
                    id={item.thirdPartyId}
                    city={item.city}
                    postCode={item.postCode}
                    name={item.name}
                    onClick={handleOnClick}
                  />
                ))}
              </Column>
            </div>
          )}
        </Column>
      </Column>
      <Column className={classes.borderedContainer}>
        <Text
          text="Gekozen dealer"
          tx="chosen.dealer"
          preset="greyPlaceholder"
          color="main"
        />
        <Text
          className={classes.searchValue}
          text={activeItem.name}
          preset="subscription"
          color="main"
        />
        <Text
          className={classes.searchDescription}
          text={`${activeItem.postCode}  ${activeItem.city}`}
          preset="greyPlaceholder"
          color="main"
        />
      </Column>
    </>
  )
}
