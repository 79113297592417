import { useState, useEffect } from 'react'
import { isIOS } from 'react-device-detect'

import { config } from '../../config'

export type AudioOptions = {
  muted?: boolean
  playing?: boolean
  loop?: boolean
  volume?: number
}

export const useAudio = (url: string, options: AudioOptions) => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(options.playing)
  const [muted, setMuted] = useState(Boolean(options.muted))
  audio.loop = Boolean(options.loop)

  const toggle = (state?: boolean) => {
    if (typeof state === undefined) {
      if (isIOS) {
        if (!playing) {
          audio.play()
        } else {
          audio.pause()
        }
      }
      setPlaying(!playing)
    } else {
      if (isIOS) {
        if (state) {
          audio.play()
        } else {
          audio.pause()
        }
      }
      setPlaying(Boolean(state))
    }
  }

  const stopAndRemoveAudio = () => {
    audio.muted = true
    audio.currentTime = 0
    audio.pause()
    audio.remove()
  }

  const handleMuted = (state: boolean) => {
    audio.muted = state
    setMuted(state)
  }

  const handleChangeAudio = (newUrl: string, nextOptions?: AudioOptions) => {
    try {
      if (newUrl !== audio.src) {
        audio.currentTime = 0
        audio.loop =
          typeof nextOptions?.loop === 'boolean' ? nextOptions.loop : audio.loop
        audio.volume = nextOptions?.volume || audio.volume
        audio.setAttribute('src', newUrl)
        audio.load()
        audio.play()
      }
    } catch (error) {
      if (config.env === 'development') {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }

  useEffect(() => {
    audio.muted = muted
    if (!isIOS) {
      if (playing) {
        audio.play()
      } else {
        audio.pause()
      }
    }
  }, [playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.muted = true
      audio.pause()
      audio.currentTime = 0
      setPlaying(false)
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return {
    playing,
    toggle,
    muted,
    handleMuted,
    handleChangeAudio,
    stopAndRemoveAudio
  }
}
