import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Logo: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={68}
      height={35}
      viewBox="0 0 68 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.747 5.46c4.575 2.528 9.754 6.974 9.214 12.902-.626 5.493-5.696 9.329-10.227 11.639-13.228 6.386-31.807 6.581-45.466 1.002C7.651 29.063 2.472 25.729.53 20.716-.958 16.597.854 12.304 3.896 9.45c6.366-5.841 14.48-7.846 22.83-9.001 9.063-1.111 18.6-.13 26.713 2.68 1.813.676 3.603 1.417 5.308 2.332zm-7.683.055c.318.183.7.251.89.595.17.388-.17.64-.36.89-4.386 3.703-9.407 5.76-14.534 7.565-2.605.685-5.233 1.92-8.135 1.234-.679-.182-1.292-.662-1.674-1.371-.72-2.148.254-4.388 1.101-6.262 1.123-2.354 3.115-5.12 5.869-5.164 5.932-.046 11.526.914 16.843 2.513zM23 5.238l-.086.32c-5.58 6.4-9.25 13.825-12.73 21.209-.678.641-1.336-.215-1.952-.493-3.098-2.054-5.898-5.371-5.092-9.309C4.435 11.83 9.676 9.09 14.046 7.23c2.588-.985 5.284-1.777 8.126-2.227.297 0 .68-.042.828.236zm37.63 5.111c2.636 2.186 5.018 5.163 4.21 8.762-1.318 5.313-7.06 8.14-11.632 9.94-2.488.835-4.997 1.693-7.718 1.949-.17-.02-.447.022-.49-.235l.064-.3c3.87-4.392 6.805-9.319 9.378-14.31 1.19-2.25 2.297-4.563 3.317-6.834.149-.171.298-.257.468-.321.957.086 1.637.836 2.403 1.35zM40.89 21.877c.51 2.82-.87 5.162-2.016 7.435-1.274 2.115-3.376 3.934-5.901 3.66-5.667-.09-11.208-1.022-16.345-2.682a1.015 1.015 0 01-.594-.455c-.106-.318.043-.614.255-.818 4.691-4.07 10.273-5.958 15.792-7.936 2.06-.636 4.245-1.319 6.56-1 .954.137 1.91.841 2.25 1.796z"
        fill={props.fill || theme.colors.hyundai || '#012C60'}
      />
    </svg>
  )
}

export default Logo
