import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Loader: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  const { cos, sin, PI } = Math
  const radius = 140
  const position = radius + 20
  const startAngle = 181
  const t1 = 0
  const percent = 100 - (Number(props.value) || 0)
  const fullArcCircleAngle = (percent / 100) * 360 - 1
  const angle = (fullArcCircleAngle / 180) * PI
  const angleRadian = angle % (2 * PI)
  const φ = (startAngle - fullArcCircleAngle / 180) * PI

  const fMatrixTimes = ([[a, b], [c, d]]: number[][], [x, y]: number[]) => [
    a * x + b * y,
    c * x + d * y
  ]
  const fRotateMatrix = (x: number) => [
    [cos(x), -sin(x)],
    [sin(x), cos(x)]
  ]
  const fVecAdd = ([a1, a2]: number[], [b1, b2]: number[]) => [a1 + b1, a2 + b2]

  const rotMatrix = fRotateMatrix(φ)
  const [sX, sY] = fVecAdd(
    fMatrixTimes(rotMatrix, [radius * cos(t1), radius * sin(t1)]),
    [position, position]
  )
  const [eX, eY] = fVecAdd(
    fMatrixTimes(rotMatrix, [
      radius * cos(t1 + angleRadian),
      radius * sin(t1 + angleRadian)
    ]),
    [position, position]
  )

  const fA = angleRadian > PI ? 1 : 0
  const fS = angleRadian > 0 ? 1 : 0

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={320}
      height={320}
      viewBox="0 0 320 320"
      fill="none"
      {...props}
    >
      <circle
        cx={160}
        cy={160}
        r={150}
        stroke={theme.colors.second}
        strokeWidth={20}
        strokeMiterlimit={2.366}
        strokeDasharray="2 20"
      />
      {percent > 0 && (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d={`M ${sX} ${sY} A ${radius} ${radius} ${φ} ${fA} ${fS} ${eX} ${eY}`}
          stroke={theme.colors.white}
          strokeWidth="40"
          fill="none"
        />
      )}
    </svg>
  )
}
