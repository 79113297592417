import React, { FC, MouseEvent, useRef, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { ModelItem, ModelItemType } from '../model-item'
import { Row } from '../row'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 676,
    transition: 'height 0.5s ease-in-out',
    position: 'relative'
  },
  leftIcon: {
    transform: 'rotate(180deg)'
  },
  icon: {
    marginTop: 60
  },
  innerContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 6,
      backgroundColor: theme.colors.title
    }
  },
  '@media screen and (max-width: 676px)': {
    container: {
      margin: '8px 0',
      width: window.innerWidth - 52
    }
  }
}))

export type ModelCarouselScrollProps = {
  testDrive?: boolean
  carName?: string
  data: ModelItemType[]
  familyName: string
  length?: number
  onClickModel: (
    familyName: string,
    value: string,
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const ModelCarouselScroll: FC<ModelCarouselScrollProps> = ({
  testDrive,
  carName,
  data,
  familyName,
  onClickModel
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(248)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, height })

  const handleSetRef = (ref: HTMLDivElement) => {
    if (ref) {
      if (height < ref.offsetHeight) {
        setHeight(ref.offsetHeight)
      }
    }
  }

  const handleOnScrollPrev = () => {
    const row = scrollRef.current
    if (row) {
      row.scroll({
        behavior: 'smooth',
        left: row.scrollLeft - row.offsetWidth / 3
      })
    }
  }

  const handleOnScrollNext = () => {
    const row = scrollRef.current
    if (row) {
      row.scroll({
        behavior: 'smooth',
        left: row.scrollLeft + row.offsetWidth / 3
      })
    }
  }

  return (
    <Row
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Icon
        className={`${classes.leftIcon} ${classes.icon}`}
        name={IconName.ARROW}
        fill={theme.colors.second}
        onClick={handleOnScrollPrev}
      />
      <Row
        className={classes.innerContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
        ref={scrollRef}
      >
        {data.map((modelItem) => (
          <ModelItem
            testDrive={testDrive}
            active={modelItem.value === carName}
            key={modelItem.id}
            {...modelItem}
            familyName={familyName}
            ref={handleSetRef}
            onClick={onClickModel}
          />
        ))}
      </Row>
      <Icon
        className={classes.icon}
        name={IconName.ARROW}
        fill={theme.colors.second}
        onClick={handleOnScrollNext}
      />
    </Row>
  )
}
