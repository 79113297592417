import React, {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactNode
} from 'react'
import { createUseStyles } from 'react-jss'

const useStyle = createUseStyles({
  row: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: (props: RowProps) => props.alignItems || 'center',
    justifyContent: (props: RowProps) => props.justifyContent || 'center'
  }
})

export interface RowProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  alignItems?: string
  justifyContent?: string
  className?: string
}

export const Row = forwardRef<HTMLDivElement, RowProps>(
  ({ alignItems, justifyContent, className, children, ...props }, ref) => {
    const classes = useStyle({ alignItems, justifyContent })

    return (
      <div {...props} className={`${className || ''} ${classes.row}`} ref={ref}>
        {children}
      </div>
    )
  }
)
