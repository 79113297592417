import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { useUnity } from '../../providers'
import { config } from '../../config'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { FullLogo } from '../full-logo'
import { Icon, IconName } from '../icon'
import { useLocale } from '../locale'
import { ModelCarouselScroll } from '../model-carousel-scroll'
import { ModelSlider } from '../model-slider'
import { Row } from '../row'
import { Switcher, SwitcherItem } from '../switcher'
import { ModelMenuProps } from './model-menu.types'
import { Link } from '../link'
import { Button } from '../button'
import { Text, TextProps } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.lightGrey,
    zIndex: 4,
    visibility: ({ visible }) => (visible ? 'visible' : 'hidden'),
    opacity: ({ visible }) => (visible ? 1 : 0),
    transition: 'visibility 0.5s, opacity 0.5s ease-in-out 0s',
    overflowY: 'auto'
  },
  relativeContainer: {
    width: 'calc(100% - 128px)',
    minHeight: 'calc(100vh - 128px)',
    padding: 32,
    margin: 32,
    backgroundColor: theme.colors.white
  },
  close: {
    cursor: 'pointer'
  },
  scrollContainer: {
    maxWidth: 680,
    overflowY: 'auto',
    paddingRight: 4,
    marginBottom: 84,
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 6,
      backgroundColor: theme.colors.title
    }
  },
  title: {
    marginTop: 16,
    textAlign: 'left'
  },
  description: {
    maxWidth: 560,
    marginTop: 12,
    textAlign: 'left'
  },
  topRow: {
    width: '100%'
  },
  categoriesContainer: {
    flex: 1,
    borderBottom: `2px solid ${theme.colors.title}`,
    cursor: 'pointer',
    paddingBottom: 30
  },
  privacyContainer: {
    position: 'fixed',
    bottom: 80,
    right: 80
  },
  link: {
    marginTop: 4
  },
  text: {
    marginTop: 12,
    cursor: 'pointer'
  },
  linkContainer: {
    marginTop: 20
  },
  sendButtonContainer: {
    marginTop: 16,
    width: '100%'
  },
  sendButton: {
    width: 280
  }
}))

export const ModelMenuBig: FC<ModelMenuProps> = ({
  languages,
  data,
  visible,
  testDrive,
  onClose
}) => {
  const { context: unityContext } = useUnity()
  const { currentLocale, handleOnGetLocale } = useLocale()
  const history = useHistory()
  const [categoryId, changeCategoryId] = useState('null')
  const [car, setCar] = useState({ familyName: '', carName: '' })

  const theme = useTheme()
  const classes = useStyle({ theme, visible })

  const language = languages.filter((item) => item.name === currentLocale)[0]
  const languageId = language ? language.id : ''

  const isLux = config.country === 'LUX'

  const sendButtonText: TextProps = {
    tx: 'testdrive.extra.layer.04',
    text: 'Continue',
    color: 'second',
    preset: 'h7'
  }

  const moreInfoLink = isMobile
    ? `https://m.hyundai.be/${currentLocale}/legal`
    : `https://www.hyundai.be/${currentLocale}/legal.html`

  const dealerLink = `https://www.hyundai.be/${currentLocale}/dealer-locator/`

  const goToHyundaiLink = `https://www.hyundai.be/${currentLocale}/`

  const handleOnChangeLanguage = (item: SwitcherItem) => {
    handleOnGetLocale(item.name)
    unityContext.send('ProjectContext(Clone)', 'SetLanguage', item.name)
  }

  const handleOnOpen = (id: string) => {
    changeCategoryId(id)
  }

  const handleOnClose = () => {
    changeCategoryId('null')
  }

  const handleOnCloseOnClickModel = (familyName: string, carName: string) => {
    if (testDrive) {
      setCar({ familyName, carName })
    } else {
      history.push(`/family/${familyName}/car/${carName}`)
      onClose()
    }
  }

  const handleOnOpenTestDrive = () => {
    const { familyName, carName } = car
    if (familyName && carName) {
      history.push(`/family/${familyName}/car/${carName}/test-drive-request`)
    }
  }

  return (
    <div className={classes.container}>
      <Column className={classes.relativeContainer} justifyContent="flex-start">
        <Row className={classes.topRow} justifyContent="space-between">
          <Column>
            {!isLux && (
              <Switcher
                data={languages}
                defaultValue={languageId}
                onClick={handleOnChangeLanguage}
              />
            )}
          </Column>
          <FullLogo onClick={onClose} />
          <Icon
            className={classes.close}
            name={IconName.CLOSE}
            onClick={onClose}
          />
        </Row>
        <Column
          className={classes.scrollContainer}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {testDrive && (
            <>
              <Text
                className={classes.title}
                tx="testdrive.extra.layer.01"
                text="Boek een proefrit"
                preset="h4"
                color="black"
              />
              <Text
                className={classes.description}
                tx="testdrive.extra.layer.02"
                text="De beste manier om overtuigd te worden van de kwaliteit van Hyundai-voertuigen, is door een proefrit te maken!"
                preset="h7"
                color="title"
              />
              <Text
                className={classes.title}
                tx="testdrive.extra.layer.03"
                text="Kies je favoriete Hyundai"
                preset="h4"
                color="black"
              />
            </>
          )}
          <Column
            className={classes.categoriesContainer}
            justifyContent="flex-start"
          >
            {data.map((category) => {
              const open = categoryId === category.id
              const name = category.name.toLowerCase()
              return (
                <ModelSlider
                  key={`categories.${category.id}`}
                  name={category.name}
                  id={category.id}
                  open={open}
                  onOpen={handleOnOpen}
                  onClose={handleOnClose}
                  onCloseModal={onClose}
                >
                  <ModelCarouselScroll
                    testDrive={testDrive}
                    carName={car.carName}
                    familyName={name}
                    data={category.data}
                    onClickModel={handleOnCloseOnClickModel}
                  />
                </ModelSlider>
              )
            })}
          </Column>
          {testDrive && (
            <Row
              className={classes.sendButtonContainer}
              justifyContent="flex-end"
            >
              <Button
                className={classes.sendButton}
                text={sendButtonText}
                preset="outlined-big"
                onClick={handleOnOpenTestDrive}
              />
            </Row>
          )}
          {!testDrive && (
            <>
              <Column className={classes.linkContainer} alignItems="flex-start">
                <Link
                  suffix
                  href={dealerLink}
                  className={classes.link}
                  tx="Menu.02"
                  text="Find a dealer near you"
                  preset="h6"
                  color="lightBlue"
                />
                <Link
                  suffix
                  className={classes.link}
                  href={goToHyundaiLink}
                  tx="Menu.03"
                  text="Go to hyundai.be"
                  preset="h6"
                  color="lightBlue"
                />
              </Column>
              <Column
                className={classes.privacyContainer}
                alignItems="flex-start"
              >
                <Link
                  className={classes.text}
                  href={moreInfoLink}
                  tx="menu.discover.privacy"
                  text="PRIVACY & COOKIE POLICY"
                  preset="h7"
                  color="title"
                />
                <Link
                  className={classes.text}
                  href={moreInfoLink}
                  tx="menu.discover.cookie"
                  text="CHANGE COOKIE PREFERENCES"
                  preset="h7"
                  color="title"
                />
              </Column>
            </>
          )}
        </Column>
      </Column>
    </div>
  )
}
