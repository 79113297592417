import React, { FC, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import TagManager from 'react-gtm-module'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'

import {
  CityCategoryPicker,
  DetailsButton,
  DetailsButtonHeader,
  DetailsMenu,
  DetailsMenuModal,
  ModelListWithPrice,
  Text,
  TextPresets,
  useLocale
} from '../../components'
import { useCars, useUnity } from '../../providers'
import { MainPage } from '../main'
import { config } from '../../config'
import { meta } from '../../seo-strings'

const useStyle = createUseStyles({
  container: {
    right: ({ open }) => (open ? 456 : 80),
    transition: 'right 0.25s ease-in-out 0s'
  },
  titleCategory: {
    margin: '0'
  },
  category: {
    display: 'flex'
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      right: () => 0,
      transition: 'none'
    },
    titleCategory: {
      margin: '8px 0'
    },
    category: {
      display: 'none'
    }
  }
})

export type FamilyPageProps = {
  onOpen: (state: boolean) => void

  onOpenHub: () => void
}

export const FamilyPage: FC<FamilyPageProps> = ({ onOpenHub, onOpen }) => {
  const isSmall = window.innerWidth < 1028
  const [isMobileDevice, changeMobileDevice] = useState(isSmall || isMobile)

  const handleOnResize = () => {
    changeMobileDevice(window.innerWidth < 1028 || isMobile)
  }

  window.addEventListener('resize', handleOnResize)

  const { context: unityContext, changeBack } = useUnity()
  const { currentLocale } = useLocale()
  const { getFamilyById } = useCars()

  const [familyId, changeFamilyId] = useState('')
  const [open, changeOpen] = useState(false)
  const [category, changeCategory] = useState(0)

  const titleDefault = meta.home.title[currentLocale]
  const descDefault = meta.home.desc[currentLocale]
  const [title, changeTitle] = useState(titleDefault)
  const [desc, changeDesc] = useState(descDefault)
  const data = getFamilyById(familyId)
  const ecoData = {
    ...data,
    items: data.items.filter((item) => item.eco)
  }
  const nextData = category === 1 ? ecoData : data

  useEffect(() => {
    changeBack(false)
    unityContext.send('ProjectContext(Clone)', 'SetHubState')
    onOpenHub()
    onOpen(false)
  }, [])
  const detailsRef = useRef<HTMLDivElement>(null)
  const classes = useStyle({ open })

  const handleOnClick = (state?: boolean) => {
    if (typeof state === 'boolean') {
      changeOpen(state)
      onOpen(state)
    } else {
      onOpen(!open)
      changeOpen(!open)
    }
  }

  const handleOnChangeFamily = (id: string) => {
    const family = getFamilyById(id)
    const tagManagerArgs = {
      dataLayer: {
        virtualPageTitle: family.categoryName,
        virtualPageURL: `${config.publicURL}/${family.categoryName}`,
        event: 'VirtualPageview'
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
    changeFamilyId(id)
    changeOpen(true)
    onOpen(true)
  }

  unityContext.on('selectFamily', handleOnChangeFamily)

  const firstElementCategoryPicker = {
    eco: false,
    tx: 'family.details.all',
    text: 'All'
  }

  const secondElementCategoryPicker = {
    eco: true,
    tx: 'family.details.eco',
    text: 'Price'
  }

  const handleOnClickCategory = (id: number) => {
    changeCategory(id)
  }

  const titleComponent = (small: boolean) => {
    const titlePreset: TextPresets = small ? 'h4' : 'h1'
    return (
      <Text
        className={classes.titleCategory}
        text={data.categoryName}
        color="main"
        preset={titlePreset}
      />
    )
  }

  useEffect(() => {
    try {
      if (data.categoryName) {
        changeTitle(meta[data.categoryName].title[currentLocale])
        changeDesc(meta[data.categoryName].desc[currentLocale])
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [data.categoryName])

  return (
    <MainPage back={false} classNameHeader={classes.container}>
      {familyId && (
        <>
          {isMobileDevice ? (
            <DetailsButtonHeader open={open} onClick={handleOnClick}>
              {titleComponent(isMobileDevice)}
            </DetailsButtonHeader>
          ) : (
            <DetailsButton open={open} onClick={handleOnClick} />
          )}
          <DetailsMenuModal>
            <DetailsMenu open={open} ref={detailsRef}>
              {!isMobileDevice && titleComponent(isMobileDevice)}
              {ecoData.items.length > 0 && (
                <CityCategoryPicker
                  firstElement={firstElementCategoryPicker}
                  secondElement={secondElementCategoryPicker}
                  onClick={handleOnClickCategory}
                />
              )}
              <ModelListWithPrice data={nextData} />
            </DetailsMenu>
          </DetailsMenuModal>
        </>
      )}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
    </MainPage>
  )
}
