import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useUnity, useWhisbi } from '../../providers'
import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { IntroPanelItem } from './intro-panel-item'

import pointerTranslate from './pointer-translate.json'
import pointerButton from './pointer-button.json'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 27,
    border: `1px solid ${theme.colors.white}`,
    backgroundColor: 'rgba(255, 255, 255, 0.48544)',
    backdropFilter: 'blur(26px)'
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 16,
    cursor: 'pointer',
    zIndex: 1
  },
  separator: {
    height: 222,
    width: 1,
    backgroundColor: theme.colors.white
  },
  item: {
    padding: '64px 56px'
  },
  '@media screen and (max-width: 455px)': {
    container: {
      flexDirection: 'column'
    },
    close: {
      right: 8,
      top: 8
    },
    item: {
      padding: 36
    },
    separator: {
      height: 1,
      width: 222
    }
  },
  '@media screen and (max-height: 455px)': {
    container: {
      flexDirection: 'row'
    },
    close: {
      right: 8,
      top: 8
    },
    item: {
      padding: 36
    }
  },
  '@media screen and (max-width: 600px) and (min-width: 456px)': {
    container: {
      flexDirection: 'row'
    },
    close: {
      right: 8,
      top: 8
    },
    item: {
      padding: 36
    }
  },
  '@media screen and (max-width: 960px) and (min-width: 600px)': {
    container: {
      flexDirection: 'row'
    }
  }
}))

export type IntroPanelProps = {
  className?: string
}

export const IntroPanel: FC<IntroPanelProps> = ({ className = '' }) => {
  const storage = window.localStorage
  const [intro, setIntro] = useState(true)
  const [loading, setLoading] = useState(true)
  const theme = useTheme()
  const classes = useStyle({ theme })
  const { openWhisbi, hideWhisbi } = useWhisbi()
  const { context: unityContext } = useUnity()

  useEffect(() => {
    const introString = storage.getItem('intro')
    if (typeof introString === 'string') {
      const nextIntro = JSON.parse(introString)
      setIntro(nextIntro)
      if (nextIntro) {
        hideWhisbi()
      }
    }
  }, [])

  const handleOnLoading = () => {
    setLoading(false)
  }

  unityContext.on('ReadyForTutorialHandler', handleOnLoading)

  if (!intro || loading) {
    return <></>
  }

  const handleOnClose = () => {
    setIntro(false)
    storage.setItem('intro', JSON.stringify(false))
    openWhisbi()
  }

  return (
    <Row className={`${className} ${classes.container}`}>
      <Icon
        className={classes.close}
        name={IconName.CLOSE_ROUND}
        height={32}
        width={32}
        onClick={handleOnClose}
      />
      <IntroPanelItem
        className={classes.item}
        data={pointerTranslate}
        time={0}
        text="Kijk rond, klik en scroll om onze showroom te ontdekken."
        tx="onboarding.01"
      />
      <div className={classes.separator} />
      <IntroPanelItem
        className={classes.item}
        data={pointerButton}
        time={6000}
        text="Vraag een proefrit aan"
        tx="onboarding.03"
      />
    </Row>
  )
}
