import React, { FC } from 'react'

export type IOSArItemProps = {
  link: string
}

export const IOSArItem: FC<IOSArItemProps> = ({ link }) => {
  return (
    <div>
      <a rel="ar" href={link}>
        {link}
      </a>
    </div>
  )
}
