import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { Icon, IconProps } from '../icon'
import { Text, TextProps } from '../text'

const useStyles = createUseStyles((theme: LightTheme) => ({
  default: {
    backgroundColor: theme.colors.white,
    borderRadius: 20,
    cursor: 'pointer',
    padding: 12,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  text: {
    marginLeft: 4
  },
  transparent: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.transparent,
    padding: 4,
    cursor: 'pointer',
    border: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  }
}))

const presets = { default: 'default', transparent: 'transparent' }

export type ButtonWithIconPresets = keyof typeof presets

export interface ButtonWithIconProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: TextProps
  preset?: ButtonWithIconPresets
  icon?: IconProps
  iconRight?: IconProps
}

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  text: textProps,
  preset = 'default',
  className = '',
  icon,
  iconRight,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyles({ theme })
  return (
    <button {...props} className={`${className} ${classes[preset]}`}>
      {icon && <Icon {...icon} />}
      <Text className={classes.text} {...textProps} />
      {iconRight && <Icon {...iconRight} />}
    </button>
  )
}
