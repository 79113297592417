import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useIntl } from 'react-intl'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Link } from '../link'
import { ModelInfoPrice } from '../../providers'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%'
  },
  rowPriceContainer: {
    marginTop: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.title}`,
    '&:not(:first-child)': {
      marginTop: 8
    }
  },
  rowPriceLeft: {
    textAlign: 'left',
    flex: 2.5
  },
  rowPriceRight: {
    textAlign: 'right',
    flex: 1
  },
  rowPrice: {
    width: '100%'
  },
  fullPrice: {
    width: '100%',
    marginTop: 32
  },
  discountTimeContainer: {
    marginTop: 32
  },
  discountLeftText: {
    marginTop: 0
  },
  requestText: {
    textAlign: 'left',
    marginTop: 32
  },
  fullPriceLeft: {
    textAlign: 'left',
    flex: 2.5
  },
  fullPriceRight: {
    textAlign: 'right',
    flex: 1
  }
}))

export type ModelInfoPricesProps = ModelInfoPrice

export const ModelInfoPrices: FC<ModelInfoPricesProps> = ({
  prices,
  fullPrice,
  discountTime,
  offer
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const classes = useStyle({ theme })

  const messageDays = intl.formatMessage({
    id: 'city.details.discount.days',
    defaultMessage: 'days'
  })

  return (
    <Column alignItems="flex-start" className={classes.container}>
      {prices.map((price, index) => (
        <Column
          key={`price_${index}`}
          className={classes.rowPriceContainer}
          alignItems="flex-start"
        >
          <Row
            className={classes.rowPrice}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Text
              className={classes.rowPriceLeft}
              tx={price.name}
              text={price.name}
              preset="h6"
              color="main"
            />
            <Text
              className={classes.rowPriceRight}
              text={price.value}
              preset="h6"
              color="title"
            />
          </Row>
          <Row
            className={classes.rowPrice}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Text
              className={classes.rowPriceLeft}
              tx={price.discountName}
              text={price.discountName}
              preset="h6"
              color="main"
            />
            <Text
              className={classes.rowPriceRight}
              text={price.discountPrice}
              preset="h6"
              color="lightBlue"
            />
          </Row>
        </Column>
      ))}
      <Row className={classes.fullPrice} justifyContent="space-between">
        <Text
          className={classes.fullPriceLeft}
          tx="city.details.full.price"
          text="ALL IN OFFER"
          preset="h4"
        />
        <Text
          className={classes.fullPriceRight}
          text={fullPrice}
          preset="h5"
          color="second"
        />
      </Row>
      <Column
        className={classes.discountTimeContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Text tx="city.details.discount.only" text="Only" preset="h4" />
        <Text text={`${discountTime} ${messageDays}`} preset="h2" />
        <Text
          className={classes.discountLeftText}
          tx="city.details.discount.left"
          text="left"
          preset="h4"
        />
        <Link
          className={classes.requestText}
          href={offer}
          tx="city.details.discount.request"
          text="left"
          preset="h5"
          color="lightBlue"
        />
      </Column>
    </Column>
  )
}
