import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Locale } from '../../services'
import { LightTheme } from '../../theme'
import { Row } from '../row'
import { Text } from '../text'

export type SwitcherItem = {
  id: string
  name: Locale
  value: string
}

export type SwitcherProps = {
  data: SwitcherItem[]
  /**
   * defaultValue: id from switcher item model
   */
  defaultValue: string
  onClick: (item: SwitcherItem) => void
}

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 24,
    backgroundColor: theme.colors.main,
    padding: 8
  },
  default: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    padding: '4px 16px'
  },
  active: {
    color: theme.colors.lightBlue,
    textDecoration: 'underline'
  }
}))

export const Switcher: FC<SwitcherProps> = ({
  data,
  defaultValue,
  onClick
}) => {
  const theme = useTheme()
  const classes = useStyle({ theme })
  const [value, changeValue] = useState(defaultValue)

  const handleOnChange = (item: SwitcherItem) => () => {
    changeValue(item.id)
    onClick(item)
  }

  return (
    <Row className={classes.container}>
      {data.map((item) => {
        const className =
          value === item.id
            ? `${classes.active} ${classes.default}`
            : classes.default
        return (
          <Text
            className={className}
            color="white"
            key={item.id}
            text={item.name}
            preset="text"
            onClick={handleOnChange(item)}
          />
        )
      })}
    </Row>
  )
}
