const handleOnReportWebVitals = (onPerfEntry) => ({
  getCLS,
  getFID,
  getFCP,
  getLCP,
  getTTFB
}) => {
  getCLS(onPerfEntry)
  getFID(onPerfEntry)
  getFCP(onPerfEntry)
  getLCP(onPerfEntry)
  getTTFB(onPerfEntry)
}

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(handleOnReportWebVitals(onPerfEntry))
  }
}

export default reportWebVitals
