import React, { forwardRef, MouseEvent } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    paddingBottom: 12,
    '&:not(:first-child)': {
      marginLeft: 12
    }
  },
  image: {
    objectFit: 'cover',
    background: theme.colors.background,
    borderRadius: 12,
    opacity: ({ testDrive, active }) => (testDrive && !active ? 0.6 : 1),
    '&:hover': {
      opacity: 1
    }
  },
  text: {
    marginTop: 12,
    textAlign: 'left'
  }
}))

export type ModelItemType = {
  id: string
  image: string
  text: string
  value: string
}

export interface ModelItemProps extends ModelItemType {
  testDrive?: boolean
  active: boolean
  familyName: string
  onClick: (
    familyName: string,
    carName: string,
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const ModelItem = forwardRef<HTMLDivElement, ModelItemProps>(
  (
    {
      active,
      id,
      image,
      value,
      text,
      familyName,
      testDrive,
      onClick,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const classes = useStyle({ active, testDrive, theme })

    const handleOnClickModel = (
      event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
      onClick(familyName, value, event)
      event.stopPropagation()
    }

    return (
      <Column
        {...props}
        ref={ref}
        className={classes.container}
        alignItems="flex-start"
        onClick={handleOnClickModel}
      >
        <img className={classes.image} src={image} width={160} height={160} />
        <Text className={classes.text} text={text} preset="h5" />
      </Column>
    )
  }
)

export default ModelItem
