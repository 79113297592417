import React, { FC, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Lottie, { EventListener, Options } from 'react-lottie'
import { Column } from '../column'
import { Locale } from '../../services'

import animationDataNL from './animation-data.json'
import animationDataFR from './fr-animation.json'
import logoAnimation from './logo-animation.json'

const useStyle = createUseStyles({
  lottie: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  topBlockContainer: {
    flex: 1,
    width: '100vw',
    height: '100vh',
    position: 'relative'
  }
})

export type AnimationProps = {
  currentLocale: Locale
  secondStart: boolean
  onLoad: () => void
}

export const Animation: FC<AnimationProps> = ({
  secondStart,
  currentLocale,
  onLoad
}) => {
  useEffect(() => {
    if (secondStart) {
      onLoad()
    }
  }, [])

  const classes = useStyle()

  const animationData =
    currentLocale === Locale.FR ? animationDataFR : animationDataNL

  const defaultOptions: Options = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      className: classes.lottie,
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const logoOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: logoAnimation,
    rendererSettings: {
      className: classes.lottie,
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const lottieEventListeners: ReadonlyArray<EventListener> = [
    {
      eventName: 'complete',
      callback: onLoad
    }
  ]

  if (secondStart) {
    return (
      <Column className={classes.topBlockContainer}>
        <Lottie
          isClickToPauseDisabled
          options={logoOptions}
          height="100vh"
          width="100vw"
        />
      </Column>
    )
  }

  return (
    <Column className={classes.topBlockContainer} justifyContent="flex-end">
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height="100vh"
        width="100vw"
        eventListeners={lottieEventListeners}
      />
    </Column>
  )
}
