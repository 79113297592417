import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const PointerUser: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={180}
      height={84}
      viewBox="0 0 180 84"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <circle
          cx={83}
          cy={45}
          r={8}
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
        <circle
          cx={82.5}
          cy={50.5}
          r={23.5}
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
        <rect
          x={49}
          y={3}
          width={67}
          height={15}
          rx={7.5}
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
        <path
          d="M101 66.687v0c-9.594-11.38-27.275-11.268-37 0v0"
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
        <path
          d="M122.181 56.712c4.905 4.116 5.534 11.471 1.402 16.396a11.601 11.601 0 01-7.891 4.125l-12.573.683a1.718 1.718 0 01-1.863-1.564c-.118-1.37 1.04-1.97 1.544-2.166l4.801-1.86 1.331 1.116a.94.94 0 001.323-.118.94.94 0 00-.114-1.324l-1.752-1.47-13.01-10.917a1.724 1.724 0 01-.209-2.427c.612-.73 1.7-.827 2.426-.218l10.618 8.91a.94.94 0 001.323-.119.939.939 0 00-.113-1.323l-3.71-3.113a1.724 1.724 0 01-.208-2.427c.612-.729 1.7-.826 2.426-.216l4.189 3.514a.94.94 0 001.323-.118.939.939 0 00-.113-1.323L110.1 58.04a1.723 1.723 0 01-.208-2.426c.612-.73 1.7-.827 2.426-.217l3.71 3.113a.94.94 0 001.323-.119.939.939 0 00-.113-1.323l-1.795-1.506a1.723 1.723 0 01-.208-2.427c.612-.729 1.7-.826 2.425-.217l4.521 3.794z"
          fill={theme.colors.background}
        />
        <path
          d="M115.773 78.731l.027-.001.027-.003a13.1 13.1 0 008.905-4.655c4.661-5.555 3.957-13.857-1.586-18.509l-4.522-3.793a3.223 3.223 0 00-5.293 2.52l-.049-.041a3.224 3.224 0 00-4.539.402 3.22 3.22 0 00-.744 2.347 3.23 3.23 0 00-4.397 3.052l-5.041-4.23a3.224 3.224 0 00-4.54.402 3.224 3.224 0 00.394 4.54l12.281 10.305-4.438 1.72-.002.001c-.347.135-.985.43-1.534.993a3.364 3.364 0 00-.96 2.699v.001a3.22 3.22 0 003.461 2.931l12.55-.68z"
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={props.fill || theme.colors.white} d="M0 0h180v84H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PointerUser
