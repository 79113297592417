import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { isMobile, isTablet } from 'react-device-detect'

import { LightTheme } from '../../theme'
import { Icon, IconName, IconProps } from '../icon'
import { Row } from '../row'
import { TextProps } from '../text'
import { ButtonWithIcon } from '../button-with-icon'
import { Column } from '../column'
import { HeaderProps } from './header.types'
import { Button } from '../button'
import { useAudioBackground } from '../../providers'

const useStyle = createUseStyles((theme: LightTheme) => ({
  counterContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2
  },
  link: {
    textDecoration: 'none'
  },
  panelContainer: {
    borderRadius: 32,
    padding: '4px 12px',
    backgroundColor: ({ menu }) => menu && theme.colors.main
  },
  panelIconContainer: {
    padding: '0px 12px',
    cursor: 'pointer'
  },
  textVisitors: {
    width: 'calc(100% - 36px)',
    borderRadius: 0
  },
  bottomMenu: {
    padding: '8px 16px',
    width: 'calc(100% - 32px)'
  },
  button: {
    width: 140
  }
}))

export const HeaderSmall: FC<HeaderProps> = ({
  ar,
  back,
  className,
  menu,
  onClick,
  onBackClick,
  onTestDriveClick
}) => {
  const { muted, handleOnMute } = useAudioBackground()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, menu })
  const backButtonTextProps: TextProps = {
    text: 'BACK',
    tx: 'header.back',
    color: 'title'
  }

  const testDriveButtonTextProps: TextProps = {
    text: 'Test Drive',
    tx: 'testdrive.extra.button.01',
    color: 'white'
  }

  const icon: IconProps = {
    name: IconName.BACK,
    color: theme.colors.white
  }

  const iconMuteUrl = muted ? IconName.SOUND_OFF : IconName.SOUND_ON

  const handleOnMuted = () => {
    handleOnMute(!muted)
  }

  const backButton = back ? (
    <ButtonWithIcon
      text={backButtonTextProps}
      icon={icon}
      onClick={onBackClick}
    />
  ) : (
    <Button
      backgroundColor="second"
      className={classes.button}
      preset="mini-filled"
      text={testDriveButtonTextProps}
      onClick={onTestDriveClick}
    />
  )

  const devices = isMobile || isTablet

  return (
    <Column
      className={`${className || ''} ${classes.counterContainer}`}
      justifyContent="space-between"
    >
      <Row className={classes.bottomMenu} justifyContent="space-between">
        {backButton}
        <Row className={classes.panelContainer}>
          {menu && (
            <>
              <Icon
                className={classes.panelIconContainer}
                name={iconMuteUrl}
                onClick={handleOnMuted}
              />
              {devices && ar && (
                <a href={ar}>
                  <Icon
                    className={classes.panelIconContainer}
                    name={IconName.AR}
                  />
                </a>
              )}
              <Icon
                className={classes.panelIconContainer}
                name={IconName.OPTIONS}
                onClick={onClick}
              />
            </>
          )}
        </Row>
      </Row>
    </Column>
  )
}
