import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Eco: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.728 7.407c-.434.02-.868.027-1.301.062-1.514.12-2.987.411-4.367 1.074-2.034.978-3.362 2.566-4.1 4.667-.406 1.156-.59 2.353-.675 3.57a.33.33 0 01-.084.22c-.019-.098-.043-.195-.055-.294-.226-1.778-.235-3.548.306-5.278.823-2.632 2.674-4.21 5.281-4.973 1.672-.49 3.38-.573 5.094-.303.908.143 1.797.4 2.694.61.09.02.178.054.26.094.248.121.296.305.089.478-1.352 1.134-2.064 2.698-2.88 4.192-.496.907-1.003 1.805-1.695 2.585-1.147 1.292-2.602 1.805-4.304 1.68-1.136-.082-2.22-.393-3.281-.792-.037-.014-.07-.04-.125-.075 1.445-4.437 4.412-7.014 9.188-7.412l-.045-.105z"
        fill={props.fill || theme.colors.green}
      />
    </svg>
  )
}

export default Eco
