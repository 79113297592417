import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Color, LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    borderRadius: 30,
    padding: ({ eco }) => (eco ? '12px 34px' : '16px 34px'),
    width: 'calc(100% / 2 - 50px)',
    backgroundColor: ({ active }) =>
      active ? theme.colors.second : theme.colors.pink,
    transition: 'backgroundColor 0.5s ease-in-out 0s'
  },
  icon: {
    marginLeft: 8
  },
  text: {
    cursor: 'pointer'
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      width: 'calc(100% / 2 - 50px)'
    }
  }
}))

export interface CityCategoryPickerItemProps {
  active: boolean
  className?: string
  eco?: boolean
  tx?: string
  text: string
  onClick: () => void
}

export const CityCategoryPickerItem: FC<CityCategoryPickerItemProps> = ({
  active,
  className,
  eco,
  tx,
  text,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, active, eco })
  const justifyContent = eco ? 'flex-end' : 'center'
  const color: Color = active ? 'white' : 'title'

  return (
    <Row
      className={`${className || ''} ${classes.container}`}
      justifyContent={justifyContent}
      onClick={onClick}
    >
      <Text
        className={classes.text}
        tx={tx}
        text={text}
        preset="h7"
        color={color}
      />
      {eco && <Icon className={classes.icon} name={IconName.ECO} />}
    </Row>
  )
}
