import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(100% - 36px)',
    padding: '8px 16px',
    border: `2px solid ${theme.colors.lightGrey}`,
    borderRadius: 4,
    backgroundColor: theme.colors.white
  },
  dot: {
    width: 32,
    height: 32,
    borderRadius: 5,
    backgroundColor: theme.colors.lightGrey
  },
  header: {
    textAlign: 'left'
  },
  textContainer: {
    alignItems: 'flex-start',
    padding: '0 16px'
  }
}))

export type SearchItemProps = {
  id: string
  name: string
  city: string
  postCode: string
  onClick: (id: string, name: string, zip: string, city: string) => void
}

export const SearchItem: FC<SearchItemProps> = ({
  id,
  name,
  city,
  postCode,
  onClick
}) => {
  const theme = useTheme()
  const classes = useStyle({ theme })

  const handleOnClick = () => {
    onClick(id, name, postCode, city)
  }

  const address = `${postCode}  ${city}`

  return (
    <Row
      className={classes.container}
      justifyContent="flex-start"
      onClick={handleOnClick}
    >
      <Icon name={IconName.HUINDAY} />
      <Column className={classes.textContainer}>
        <Text
          className={classes.header}
          text={name}
          preset="subscription"
          color="main"
        />
        <Text text={address} preset="greyPlaceholder" color="main" />
      </Column>
    </Row>
  )
}
