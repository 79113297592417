import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import numeral from 'numeral'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    maxWidth: 776
  },
  bar: {
    width: ({ value }: ProgressBarProps) => `calc(${value} - 16px)`,
    backgroundColor: theme.colors.second,
    height: 3
  },
  title: {
    color: theme.colors.second,
    padding: 12
  },
  progressContainer: {
    width: '100%',
    opacity: ({ value }) => (value === '0%' || value === '100%' ? 0 : 1),
    transition: 'opacity 0.5s ease-in-out'
  },
  barContainer: {
    padding: 12,
    width: '100%'
  },
  skipContainer: {
    width: '100%'
  },
  skip: {
    visibility: ({ skipIntro }) => (skipIntro ? 'visible' : 'hidden'),
    opacity: ({ skipIntro }) => (skipIntro ? 1 : 0),
    transition: 'opacity 0.25s, visibility 0.25s ease-in-out'
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      maxWidth: 'calc(100vw - 48px)'
    },
    barContainer: {
      padding: '12px 24px'
    }
  }
}))

export type ProgressBarProps = {
  className?: string
  skipIntro: boolean
  value: string
  onSkip: () => void
}

export const ProgressBar: FC<ProgressBarProps> = ({
  className = '',
  skipIntro,
  value,
  onSkip
}) => {
  const theme = useTheme()
  const classes = useStyle({ theme, value, skipIntro })
  return (
    <Column className={`${className} ${classes.container}`}>
      {value && (
        <Column className={classes.progressContainer}>
          <Text
            className={classes.title}
            text={numeral(value).format('0%')}
            preset="h3"
          />
          <Row className={classes.barContainer} justifyContent="flex-start">
            <div className={classes.bar} />
          </Row>
        </Column>
      )}
      <Row className={classes.skip} justifyContent="flex-end">
        <Text
          text="Skip intro animation >>>"
          tx="start.03"
          color="white"
          preset="h5"
          onClick={onSkip}
        />
      </Row>
    </Column>
  )
}
