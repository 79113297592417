import React, { FC, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { ModelListWithPriceType } from '../../providers'
import { Column } from '../column'
import { ModelItemWithPrice } from '../model-item-with-price'

const useStyle = createUseStyles({
  container: {
    width: '100%'
  },
  opacityModel: {
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
      transition: 'opacity 0.5 ease-int-out'
    },
    transition: 'opacity 0.5 ease-int-out'
  },
  categoryContainer: {
    opacity: ({ loading }) => (loading ? 0 : 1),
    transition: 'opacity 0.5s ease-in-out 0s'
  },
  categoryTextContainer: {
    marginTop: 12
  },
  '@media screen and (max-width: 1024px)': {
    opacityModel: {
      opacity: 1
    },
    categoryContainer: {
      width: '100%',
      marginBottom: '80px'
    }
  }
})

export type ModelListWithPriceProps = {
  data: ModelListWithPriceType
}

export const ModelListWithPrice: FC<ModelListWithPriceProps> = ({ data }) => {
  const [loading, changeLoading] = useState(true)
  useEffect(() => {
    changeLoading(true)
    const timeout = setTimeout(() => {
      changeLoading(false)
    }, 500)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [data])
  const classes = useStyle({ loading })
  const familyName = data.categoryName.toLowerCase()
  return (
    <Column className={classes.container}>
      <Column
        key={`category_${data.id}`}
        className={classes.categoryContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {data.items.map((model) => (
          <ModelItemWithPrice
            key={`category_${data.id}_model_${model.id}`}
            className={classes.opacityModel}
            familyName={familyName}
            id={model.id}
            image={model.image}
            price={model.price}
            catalogPrice={model.catalogPrice}
            text={model.text}
            value={model.value}
          />
        ))}
      </Column>
    </Column>
  )
}
