import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Back: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={8} cy={8} r={8} fill={theme.colors.title} />
      <path
        d="M9.06641 11.2L5.86641 7.98663L9.06641 4.79995"
        stroke={props.fill || theme.colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Back
