import React, { FC } from 'react'
import Lottie, { Options } from 'react-lottie'

export type AnimationProps = {
  data: any
  paused: boolean
}

export const LottieIcon: FC<AnimationProps> = ({ data, paused }) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: false,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Lottie
      isClickToPauseDisabled
      options={defaultOptions}
      height="100%"
      width={180}
      isStopped={paused}
    />
  )
}
