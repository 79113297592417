import React, { createContext, FC, useContext } from 'react'

import { useUnity } from '../unity-context'
import { AudioOptions, useAudio } from './background-audio'

type AudioBackgroundContextValues = {
  muted: boolean
  handleOnMute: (state: boolean) => void
  handleChangeAudio: (url: string, options?: AudioOptions) => void
  play: (state?: boolean) => void
}

type AudioProps = {
  audio: string
}

const initialState: AudioBackgroundContextValues = {
  muted: false,
  play: (state?: boolean) => {
    return state
  },
  handleOnMute: (state: boolean) => {
    return state
  },
  handleChangeAudio: (url: string) => {
    return url
  }
}

const values = (value: AudioBackgroundContextValues) => {
  return value
}

const AudioContext = createContext(values(initialState))

export const useAudioBackground = () => useContext(AudioContext)

export const AudioBackgroundProvider: FC<AudioProps> = ({
  children,
  audio
}) => {
  const { toggle: play, handleMuted, muted, handleChangeAudio } = useAudio(
    audio,
    {
      muted: false,
      playing: false,
      loop: false
    }
  )

  const { context: unityContext } = useUnity()

  const handleOnMute = (state: boolean) => {
    handleMuted(state)
    unityContext.send('ProjectContext(Clone)', 'SetMuted', state ? 1 : 0)
  }

  return (
    <AudioContext.Provider
      value={values({
        muted,
        play,
        handleOnMute,
        handleChangeAudio
      })}
    >
      {children}
    </AudioContext.Provider>
  )
}
