import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const BeginArrow: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={40}
      viewBox="0 0 64 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M56 20l-8.366 6.985a1.015 1.015 0 01-.994.184A1.003 1.003 0 0145.99 26l.033-.113L51 21H8v-2h43l-5.009-4.827a1 1 0 01.105-.881l.08-.107c.336-.389.905-.46 1.324-.185l.101.076L56 20z"
          fill={props.fill || theme.colors.white}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill={props.fill || theme.colors.white}
            transform="rotate(90 32 32)"
            d="M0 0h40v64H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BeginArrow
