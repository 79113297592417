import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { createUseStyles } from 'react-jss'
import { useRouteMatch } from 'react-router-dom'

import { Header, ModelMenu, SwitcherItem } from '../../components'
import { Locale } from '../../services'
import { useAudioBackground, useCars, useWhisbi } from '../../providers'

const useStyle = createUseStyles({
  container: {},
  chatContainer: {
    position: 'fixed',
    bottom: 32,
    right: 32,
    zIndex: 2
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      left: 16,
      right: 16,
      top: 16
    },
    chatContainer: {
      bottom: 16,
      right: 16
    }
  }
})

export enum NavigatePath {
  HUB = 'hub',
  FAMILY = 'family',
  CAR = 'car',
  INTERIOR = 'interior'
}

export type NavigateState = {
  path: NavigatePath
  familyId?: string
  carId?: string
}

export type MainPageProps = {
  ar?: string
  back: boolean
  backLink?: string
  className?: string
  classNameHeader?: string
  onOpenHub?: () => void
  onBack?: () => void
}

const languageData: SwitcherItem[] = [
  { id: '0', value: 'fr', name: Locale.FR },
  { id: '1', value: 'nl', name: Locale.NL }
]

export const MainPage: FC<MainPageProps> = ({
  ar,
  back,
  className,
  classNameHeader,
  onOpenHub,
  onBack,
  children
}) => {
  const { handleChangeAudio } = useAudioBackground()
  const { audio, categories } = useCars()
  const { openWhisbi, hideWhisbi } = useWhisbi()

  const classes = useStyle()
  const match = useRouteMatch({
    path: '/',
    strict: true,
    sensitive: true
  })
  const [menuVisible, changeMenuVisible] = useState(false)
  const [testMenuVisible, changeTestMenuVisible] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    const audioUrl = audio[0]
    handleChangeAudio(audioUrl.audio, { volume: 0.75, loop: true })
    const isMatch = Boolean(match?.isExact)
    if (isMatch && onOpenHub) {
      onOpenHub()
    }
  }, [])
  const visitors = 175
  const message = intl.formatMessage({
    id: 'main.visitors',
    defaultMessage: 'visitors in the showroom right now'
  })

  const visitorsCounterMessage = `${visitors} ${message}`

  const handleOnOpenStateMenu = () => {
    changeMenuVisible(!menuVisible)
    if (!menuVisible) {
      hideWhisbi()
    } else {
      openWhisbi()
    }
  }

  const handleOnBack = () => {
    if (onBack) {
      onBack()
    }
  }

  const handleOnTestDriveClick = () => {
    changeTestMenuVisible(!testMenuVisible)
    if (!testMenuVisible) {
      hideWhisbi()
    } else {
      openWhisbi()
    }
  }

  return (
    <div className={`${className} ${classes.container}`}>
      <Header
        menu
        ar={ar}
        className={classNameHeader}
        back={back}
        visitors={visitorsCounterMessage}
        onClick={handleOnOpenStateMenu}
        onBackClick={handleOnBack}
        onTestDriveClick={handleOnTestDriveClick}
      />
      {children}
      <ModelMenu
        data={categories}
        languages={languageData}
        visible={menuVisible}
        onClose={handleOnOpenStateMenu}
      />
      <ModelMenu
        testDrive
        data={categories}
        languages={languageData}
        visible={testMenuVisible}
        onClose={handleOnTestDriveClick}
      />
    </div>
  )
}
