import { createContext, useContext } from 'react'

enum TagType {
  SHOWROOM_MODEL = 'SHOWROOM_MODEL',
  SHOWROOM_FUELTYPE = 'SHOWROOM_FUELTYPE',
  SHOWROOM_CATEGORY = 'SHOWROOM_CATEGORY'
}

type Tag = {
  tag: TagType
  value: string
}

type BTQueueOption = {
  tags?: Tag[]
  isEvent: boolean
  isTargeting: boolean
  async?: boolean
}

interface SelligentScript extends HTMLScriptElement {
  bt_queue: BTQueueOption[]
  afterInit: () => void
}

export const selligentInit = () => {
  const selligentElement = document.createElement('script') as SelligentScript
  // @ts-ignore
  window.wa = selligentElement
  const selligentScript: Element = document.getElementsByTagName('script')[0]
  selligentElement.src =
    'https://targetemsecure.blob.core.windows.net/5d190604-b64d-4dc2-9cbc-9ea3d08911ac/5d190604b64d4dc29cbc9ea3d08911ac_1.js'
  selligentElement.type = 'text/javascript'
  if (selligentScript.parentNode) {
    selligentScript.parentNode.insertBefore(selligentElement, selligentScript)
  }
  selligentElement.bt_queue = []
  selligentElement.afterInit = () => {
    selligentElement.bt_queue.push({
      isEvent: false,
      isTargeting: true,
      async: true
    })
  }

  const scriptSitePostTag = (
    model: string,
    fueltype: string,
    category: string
  ) => {
    selligentElement.bt_queue.push({
      tags: [
        { tag: TagType.SHOWROOM_MODEL, value: model },
        { tag: TagType.SHOWROOM_FUELTYPE, value: fueltype },
        { tag: TagType.SHOWROOM_CATEGORY, value: category }
      ],
      isEvent: true,
      isTargeting: true
    })
  }

  return { selligentElement, selligentScript, scriptSitePostTag }
}

export const selligentContext = selligentInit()

export const SelligentContextProvider = createContext(selligentContext)

export const useSelligent = () =>
  useContext<typeof selligentContext>(SelligentContextProvider)
