import React, { FC, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { HeaderSmall } from './header.small'
import { HeaderBig } from './header.big'
import { HeaderProps } from './header.types'

export const Header: FC<HeaderProps> = ({
  ar,
  className,
  back,
  backLink,
  menu,
  visitors,
  onClick,
  onBackClick,
  onTestDriveClick
}) => {
  const isSmall = window.innerWidth < 1028
  const [isMobileDevice, changeMobileDevice] = useState(isSmall || isMobile)

  const handleOnResize = () => {
    changeMobileDevice(window.innerWidth < 1028 || isMobile)
  }

  window.addEventListener('resize', handleOnResize)
  return (
    <>
      {isMobileDevice && (
        <HeaderSmall
          ar={ar}
          backLink={backLink}
          menu={menu}
          className={className}
          back={back}
          visitors={visitors}
          onClick={onClick}
          onBackClick={onBackClick}
          onTestDriveClick={onTestDriveClick}
        />
      )}
      {!isMobileDevice && (
        <HeaderBig
          ar={ar}
          menu={menu}
          className={className}
          back={back}
          visitors={visitors}
          onClick={onClick}
          onBackClick={onBackClick}
          onTestDriveClick={onTestDriveClick}
        />
      )}
    </>
  )
}
