import React, { createContext, FC, useContext } from 'react'
import { isMobile, isTablet } from 'react-device-detect'

import { Locale } from '../../services'
import { config } from './config'

interface WhisbiScript extends HTMLScriptElement {
  _config: {
    events: {}
    oneToMany: {
      styles: {
        liveHelpRibbon: {
          backgroundColor: string
        }
      }
    }
  }
  updateConfig: (config: any) => void
  setup: (config: any) => void
}

type GlobalThis = typeof globalThis

declare global {
  const whisbi: WhisbiScript
  interface Window extends GlobalThis {
    whisbi: WhisbiScript
  }
}

const hideWhisbi = () => {
  const widget = document.getElementsByClassName('noselect')[0] as HTMLElement
  if (widget) {
    widget.style.zIndex = '0'
  }
}

const openWhisbi = () => {
  const widget = document.getElementsByClassName('noselect')[0] as HTMLElement
  if (widget) {
    widget.style.zIndex = '2'
  }
}

const changeRightPosition = (right: string) => {
  const widget = document.getElementsByClassName('noselect')[0] as HTMLElement
  if (widget) {
    widget.style.right = right
  }
}

const otoClosed = () => {
  window.focus()
  if (!isMobile || !isTablet) {
    changeRightPosition('354px')
  }
}

const floatingButtonShown = () => {
  if (!isMobile || !isTablet) {
    changeRightPosition('354px')
  }
}

export const whisbiInit = (language: Locale) => {
  const whisbiElement = document.createElement('script') as WhisbiScript
  whisbiElement.type = 'text/javascript'
  whisbiElement.src = `//static.whisbi.com/3b3b630f-f34f-4621-9c23-3c5425d5ca1e/connect.js?origin=broadcast&mode=mixed-chatbot&lang=${language}&secondorigin=assistant`

  whisbiElement.onload = () => {
    let whisbiLauncher: HTMLScriptElement
    const timer = setInterval(() => {
      const scripts = document.getElementsByTagName('script')
      // @ts-ignore
      whisbiLauncher = scripts.whisbi_launcher
      if (whisbiLauncher) {
        whisbiLauncher.onload = () => {
          window.whisbi.setup({
            // eslint-disable-next-line no-underscore-dangle
            ...config(language),
            events: {
              otoClosed,
              otmClosed: otoClosed,
              floatingButtonShown,
              floatingButtonUnfolded: floatingButtonShown,
              floatingButtonFolded: otoClosed
            }
          })
        }
        clearInterval(timer)
      }
    }, 100)
  }

  document.getElementsByTagName('body')[0].appendChild(whisbiElement)

  return { whisbiScript: whisbiElement, wishbi: window.whisbi }
}

const whisbiReinit = (language: Locale) => {
  if (whisbi) {
    whisbi.updateConfig(config(language))
  }
}

export const whisbiContext = {
  whisbiInit,
  whisbiReinit,
  hideWhisbi,
  openWhisbi,
  changeRightPosition
}

export const WhisbiContextProvider = createContext(whisbiContext)

export const WhisbiProvider: FC = ({ children }) => {
  return (
    <WhisbiContextProvider.Provider value={whisbiContext}>
      {children}
    </WhisbiContextProvider.Provider>
  )
}

export const useWhisbi = () =>
  useContext<typeof whisbiContext>(WhisbiContextProvider)
