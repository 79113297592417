import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { createUseStyles, useTheme } from 'react-jss'
import { useSwipeable } from 'react-swipeable'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyle = createUseStyles((theme: LightTheme) => ({
  detailsButton: {
    zIndex: 3,
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    padding: 16,
    position: 'fixed',
    left: 0,
    transition: 'top 0.5s ease-in-out 0s',
    bottom: 0,
    transform: 'rotate(0deg)',
    transformOrigin: '0',
    width: 'calc(100% - 32px)',
    maxWidth: 396,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    writingMode: 'initial'
  },
  icon: {
    transition: 'transform 0.5s ease-in-out 0s',
    transform: ({ open }) => (open ? 'rotate(90deg)' : 'rotate(270deg)')
  },
  text: {
    cursor: 'pointer',
    padding: 4,
    animationName: '$fadeIn',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '0.5s'
  },
  textOpacity: {
    cursor: 'pointer',
    padding: 4,
    animationName: '$fadeOut',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '0.5s'
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  '@keyframes fadeOut': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  '@media screen and (max-width: 1023px)': {
    detailsButton: {
      top: ({ open }) => (open ? 0 : 'calc(100% - 110px)')
    }
  },
  '@media screen and (orientation:landscape)': {
    detailsButton: {
      top: ({ open }) => (open ? 0 : 'calc(100% - 110px)')
    }
  },
  '@media screen and (max-width: 455px)': {
    detailsButton: {
      maxWidth: '100%',
      top: ({ open }) => (open ? 142 : 'calc(100% - 110px)')
    }
  }
}))

export type DetailsButtonHeaderProps = {
  open: boolean
  onClick: (state?: boolean) => void
}

export const DetailsButtonHeader: FC<DetailsButtonHeaderProps> = ({
  children,
  open,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ open, theme })
  const config = {
    delta: 20, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: isMobile, // track touch input
    trackMouse: !isMobile, // track mouse input
    rotationAngle: 0 // set a rotation angle
  }

  const handlers = useSwipeable({
    onSwipedUp: () => {
      onClick(true)
    },
    onSwipedDown: () => {
      onClick(false)
    },
    ...config
  })

  const handleOnClick = () => {
    onClick(!open)
  }

  const textClassName = open ? classes.text : classes.textOpacity
  const tx = open ? 'family.details.close' : 'family.details.open'
  const text = open ? 'CLOSE DETAILS' : 'OPEN DETAILS'

  return (
    <Column
      className={classes.detailsButton}
      alignItems="flex-start"
      justifyContent="flex-start"
      {...handlers}
    >
      <Row justifyContent="flex-start" onClick={handleOnClick}>
        <Icon
          className={classes.icon}
          name={IconName.ARROW}
          width={24}
          height={24}
          fill={theme.colors.title}
        />
        <Text
          className={textClassName}
          tx={tx}
          text={text}
          color="title"
          preset="title"
        />
      </Row>
      {children}
    </Column>
  )
}
