import React, {
  ReactNode,
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef
} from 'react'
import { createUseStyles } from 'react-jss'
import { useSpring, animated } from 'react-spring'

const useStyle = createUseStyles({
  column: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: (props: ColumnProps) => props.alignItems || 'center',
    justifyContent: (props: ColumnProps) => props.justifyContent || 'center'
  }
})

export type SpringProps = Record<string, number>

export interface ColumnProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: ReactNode
  alignItems?: string
  justifyContent?: string
  className?: string
  animated?: boolean
  springFrom?: SpringProps
  springTo?: SpringProps
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  (
    {
      alignItems,
      children,
      className,
      justifyContent,
      springFrom,
      springTo,
      ...props
    },
    ref
  ) => {
    const classes = useStyle({
      config: { duration: 100 },
      alignItems,
      justifyContent
    })

    if (springFrom && springTo) {
      const style = useSpring({ ...springTo, from: springFrom })
      return (
        <animated.div
          className={`${className || ''} ${classes.column}`}
          style={style}
        >
          {children}
        </animated.div>
      )
    }
    return (
      <div
        className={`${className || ''} ${classes.column}`}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  }
)
