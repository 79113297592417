import React, { forwardRef } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { ModelTypeBlockType } from '../../providers/data-context/data-context.types'

import { Locale } from '../../services'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { useLocale } from '../locale'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.pink,
    borderRadius: 10,
    '&:not(:first-child)': {
      marginLeft: 8
    },
    cursor: 'pointer',
    minWidth: 248,
    height: 248
  },
  title: {
    marginTop: 16
  },
  description: {
    padding: '12px 16px',
    textAlign: 'center'
  },
  price: {
    marginTop: 12,
    padding: '0px 32px'
  },
  image: {
    width: 72,
    height: 80,
    objectFit: 'cover'
  },
  catalog: {
    textDecoration: 'line-through'
  }
}))

export interface ModelTypeBlockProps extends ModelTypeBlockType {
  onClick: () => void
}

export const ModelTypeBlock = forwardRef<HTMLDivElement, ModelTypeBlockProps>(
  ({ title, fuelsNl, fuelsFr, price, catalog, onClick, ...props }, ref) => {
    const { currentLocale } = useLocale()
    const fuels = currentLocale === Locale.FR ? fuelsFr : fuelsNl
    const theme = useTheme()
    const classes = useStyle({ theme })
    return (
      <Column
        {...props}
        className={classes.container}
        ref={ref}
        onClick={onClick}
      >
        <Text className={classes.title} preset="h4" text={title} />
        <Row>
          {fuels.map((item, index) => (
            <img key={`img_${index}`} className={classes.image} src={item} />
          ))}
        </Row>
        <Column className={classes.title}>
          <Text
            preset="h5"
            text="From"
            tx="city.details.price.from"
            color="second"
          />
          <Row>
            <Text preset="h5" text={price} color="second" />
            <Text
              className={classes.catalog}
              preset="h5"
              text={catalog}
              color="second"
            />
          </Row>
        </Column>
      </Column>
    )
  }
)
