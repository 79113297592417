import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Close: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  const [hover, changeHover] = useState(false)

  const handleOnHover = (state: boolean) => () => {
    changeHover(state)
  }

  const color = hover ? theme.colors.lightBlue : theme.colors.second

  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.279 11.75a1 1 0 000 1.415L18.114 20l-6.835 6.835a1 1 0 000 1.415l.471.471a1 1 0 001.415 0L20 21.886l6.835 6.835a1 1 0 001.414 0l.472-.471a1 1 0 000-1.415L21.886 20l6.835-6.835a1 1 0 000-1.415l-.472-.471a1 1 0 00-1.414 0L20 18.114l-6.835-6.835a1 1 0 00-1.415 0l-.471.471z"
        fill={props.fill || color}
      />
    </svg>
  )
}

export default Close
