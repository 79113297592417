import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    right: ({ open }) => (open ? 456 : 80),
    transition: 'right 0.25s ease-in-out 0s'
  },
  categoryTextContainer: {
    marginTop: 12
  },
  titleCategory: {
    margin: '0'
  },
  title: {
    textAlign: 'left'
  },
  price: {
    marginTop: 4
  },
  catalogPrice: {
    textDecoration: 'line-through',
    marginLeft: 4
  },
  '@media screen and (max-width: 1024px)': {
    titleCategory: {
      margin: '8px 0'
    },
    container: {
      right: () => 0,
      transition: 'none'
    }
  }
})
