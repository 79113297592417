import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'

import {
  DetailsButton,
  DetailsButtonHeader,
  DetailsMenu,
  DetailsMenuModal,
  ModelInfo,
  Palette,
  Row,
  Text,
  TextPresets,
  useLocale
} from '../../components'
import { useCars, useSelligent, useUnity } from '../../providers'
import { MainPage } from '../main'
import { config } from '../../config'
import { useStyle } from './city.styles'
import { meta } from '../../seo-strings'

export type CityPageProps = {
  onOpen: (state: boolean) => void
  onOpenCity: (id: string) => void
}

export const CityPage: FC<CityPageProps> = ({ onOpen, onOpenCity }) => {
  const isSmall = window.innerWidth < 1028
  const [isMobileDevice, changeMobileDevice] = useState(isSmall || isMobile)

  const handleOnResize = () => {
    changeMobileDevice(window.innerWidth < 1028 || isMobile)
  }

  window.addEventListener('resize', handleOnResize)

  const [scroll] = useState(0)
  const [open, changeOpen] = useState(false)

  const intl = useIntl()
  const location = useLocation()
  const history = useHistory()
  const { getCarByName } = useCars()
  const { scriptSitePostTag } = useSelligent()
  const { context: unityContext, changeBack, clearWebGL } = useUnity()

  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]
  const carData = getCarByName(familyName, carName)
  if (!carData) {
    return <></>
  }

  const handleOnClick = (state?: boolean) => {
    if (typeof state === 'boolean') {
      changeOpen(state)
      onOpen(state)
    } else {
      onOpen(!open)
      changeOpen(!open)
    }
  }

  useEffect(() => {
    changeBack(true)

    onOpen(false)
    const timeout =
      window.innerWidth > 1023 && setTimeout(() => handleOnClick(true), 5000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    clearWebGL()
    unityContext.send('ProjectContext(Clone)', 'SetCarState', carData.id)
    scriptSitePostTag(carData.title, '', 'City')
    const tagManagerArgs = {
      dataLayer: {
        virtualPageTitle: carData.title,
        virtualPageURL: `${config.publicURL}/family/${familyName}/car/${carName}/`,
        event: 'VirtualPageview'
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)

    onOpenCity(carData.id)
  }, [carName])

  const detailsRef = useRef<HTMLDivElement>(null)
  const heightScreen = window.innerHeight
  const heightComponent = detailsRef.current
    ? detailsRef.current.offsetHeight
    : 0
  const maxBottom = heightScreen - heightComponent
  const maxScroll = maxBottom < 0 ? maxBottom : 0

  const classes = useStyle({ open, scroll, maxScroll })

  const { currentLocale } = useLocale()
  let title = meta.home.title[currentLocale]
  let desc = meta.home.desc[currentLocale]
  try {
    title = meta[carName].title[currentLocale]
    desc = meta[carName].desc[currentLocale]
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  const handleOnChangeColor = (color: string) => {
    unityContext.send('ProjectContext(Clone)', 'SetCarColor', color)
  }

  const handleOnBack = () => {
    history.push('/')
  }

  const handleOnNavigateTestDrive = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    history.push(`/family/${familyName}/car/${carName}/test-drive-request`)
    event.preventDefault()
    event.stopPropagation()
  }

  const fromTx = intl.formatMessage({
    id: 'city.details.price.from',
    defaultMessage: 'From'
  })
  const { catalogPrice, price } = carData
  const formatedPrice = `${fromTx} € ${price} - `

  const titleComponent = (small: boolean) => {
    const titlePreset: TextPresets = small ? 'h4' : 'h1'
    return (
      <>
        <Text
          text={carData.title}
          preset={titlePreset}
          color="main"
          className={classes.title}
        />
        <Row className={classes.price}>
          <Text text={formatedPrice} preset="h5" color="second" />
          <Text
            className={classes.catalogPrice}
            text={catalogPrice}
            preset="h5"
            color="second"
          />
        </Row>
      </>
    )
  }

  const backLink = isMobile ? '/' : undefined
  return (
    <MainPage
      back
      ar={carData.ar}
      backLink={backLink}
      classNameHeader={classes.container}
      onBack={handleOnBack}
    >
      <Palette
        defaultColor={carData.defaultColor}
        colors={carData.colorsWithName}
        className={classes.palette}
        carName={carName}
        onChange={handleOnChangeColor}
      />
      {isMobileDevice ? (
        <DetailsButtonHeader open={open} onClick={handleOnClick}>
          {titleComponent(isMobileDevice)}
        </DetailsButtonHeader>
      ) : (
        <DetailsButton open={open} onClick={handleOnClick} />
      )}
      <DetailsMenuModal>
        <DetailsMenu open={open}>
          {!isMobileDevice && titleComponent(isMobileDevice)}
          <ModelInfo
            open={open}
            {...carData}
            onOpenTest={handleOnNavigateTestDrive}
          />
        </DetailsMenu>
      </DetailsMenuModal>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
    </MainPage>
  )
}
