import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'

import { Column } from '../column'
import { Link } from '../link'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    width: 'calc(100% - 44px)',
    padding: '13px 16px',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.pink}`,
    borderRadius: 4
  },
  placeholder: {
    marginTop: 4
  },
  checkbox: {
    width: 12,
    height: 12,
    padding: 4,
    border: `1px solid ${theme.colors.second}`,
    borderRadius: 4
  },
  checkboxDot: {
    width: 12,
    height: 12,
    borderRadius: 3,
    backgroundColor: ({ active }) =>
      active ? theme.colors.second : theme.colors.lightGrey
  }
}))

export type CheckboxProps = {
  className?: string
  label: string
  labelTx?: string
  href?: string
  placeholder: string
  placeholderTx?: string
  onChange: (state: boolean) => void
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  href,
  label,
  labelTx,
  placeholder,
  placeholderTx,
  onChange
}) => {
  const [active, setActive] = useState(false)

  const theme = useTheme()
  const classes = useStyle({ theme, active })

  const handleOnChange = () => {
    setActive(!active)
    onChange(!active)
  }

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
      onClick={handleOnChange}
    >
      <Column alignItems="flex-start">
        <Text text={label} tx={labelTx} preset="h7" color="main" />
        {href ? (
          <Link
            className={classes.placeholder}
            text={placeholder}
            tx={placeholderTx}
            preset="h7"
            color="title"
            href={href}
          />
        ) : (
          <Text
            className={classes.placeholder}
            text={placeholder}
            tx={placeholderTx}
            preset="h7"
            color="title"
          />
        )}
      </Column>
      <div className={classes.checkbox}>
        <div className={classes.checkboxDot} />
      </div>
    </Row>
  )
}
