import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { IntlProvider } from 'react-intl'

import { config } from '../../config'
import { create, Locale } from '../../services'

type StructuredMessages = Record<string, string>

const localeEN: StructuredMessages = require('../../i18n/en.json')

const localeData: Record<Locale, Record<string, string>> = {
  // Default language
  [Locale.EN]: localeEN,
  [Locale.FR]: {},
  [Locale.NL]: {}
}

export const localeNames: Record<Locale, string> = {
  [Locale.EN]: 'English',
  [Locale.NL]: 'Dutch',
  [Locale.FR]: 'French'
}

const defaultLocale = Locale.EN

export type LocaleProviderProps = {
  children: ReactNode
  locale?: Locale
}

export type LocaleContextValue = {
  currentLocale: Locale
  localeData: Record<Locale, Record<string, string>>
  handleOnGetLocale: (locale: Locale) => Promise<void> | null
}

const api = create(config.apiURL)

const getLocale = async (locale: Locale) => {
  const response = await api.getLocale(locale)

  return response
}

const oneOfLocale = (value: string) => {
  const defaultLanguage = config.country === 'LUX' ? Locale.FR : Locale.NL
  switch (value) {
    case Locale.FR: {
      return Locale.FR
    }
    case Locale.NL: {
      return Locale.NL
    }
    default: {
      return defaultLanguage
    }
  }
}

export const LocaleContext = createContext<LocaleContextValue>({
  localeData,
  handleOnGetLocale: () => null,
  currentLocale: Locale.EN
})

export const LocaleProvider: FC<LocaleProviderProps> = ({
  locale: localeOverride,
  children
}) => {
  const isLux = config.country === 'LUX'
  const locale = localeOverride || Locale.EN
  const [loading, setLoading] = useState(true)
  const [currentLocale, setCurrentLocale] = useState(locale)
  const [locales, setLocales] = useState(localeData)

  const handleOnGetLocale = async (nextLocale: Locale) => {
    const response = await getLocale(nextLocale)

    if (response.kind === 'ok') {
      setLocales({
        ...locales,
        [nextLocale]: {
          ...localeEN,
          ...locales[nextLocale],
          ...response.locale
        }
      })
      setCurrentLocale(nextLocale)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (navigator.language && !isLux) {
      const isoLanguage = navigator.language.slice(0, 2)
      const language = oneOfLocale(isoLanguage)
      handleOnGetLocale(language)
    } else {
      handleOnGetLocale(currentLocale)
    }
    setLoading(true)
  }, [])

  const contextState = { localeData: locales, handleOnGetLocale, currentLocale }
  return (
    <LocaleContext.Provider value={contextState}>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={currentLocale}
        messages={locales[currentLocale]}
        key={locale}
      >
        {!loading && children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
