import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

const useStyle = createUseStyles((theme: LightTheme) => ({
  line: {
    stroke: theme.colors.white
  },
  lineHover: {
    stroke: theme.colors.lightBlue
  },
  secondLine: {
    transform: 'translateX(2px)',
    transition: 'transform 0.25s ease-in-out'
  },
  secondLineHover: {
    transform: 'translateX(10px)',
    transition: 'transform 0.25s ease-in-out'
  }
}))

export const Options: FC<IconProps> = (props) => {
  const [hover, changeHover] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const handleOnHover = (state: boolean) => () => {
    changeHover(state)
  }

  const lineStyle = hover ? classes.lineHover : classes.line
  const secondLineStyle = hover ? classes.secondLineHover : classes.secondLine
  return (
    <svg
      width={32}
      height={32}
      viewBox="4 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
      {...props}
    >
      <path
        className={lineStyle}
        d="M9 10H29"
        strokeOpacity="0.87"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className={`${lineStyle} ${secondLineStyle}`}
        d="M3 16H23"
        strokeOpacity="0.87"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className={lineStyle}
        d="M9 22H29"
        strokeOpacity="0.87"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Options
