import { GeneralApiProblem } from './api-problem'

export enum Locale {
  EN = 'eng',
  NL = 'nl',
  FR = 'fr'
}

export type TestDriveData = {
  clientType: string
  gender: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  description: string
  dealer: string
  model: string
  policy: boolean
  language: string
  country: string
}

export type CarAdditional = {
  id: string
  modelName: string
  modelTrim: string
  uspNl: string[]
  uspFr: string[]
  catalog: string
  margin: string
  discount: string
  ecobon: string
  stock: number
  tradeIn: string
  roundUpBe: number
  roundUpLu: number
  fuelsNl: string[]
  fuelsFr: string[]
  totalPrice: string
  subtotalPrice: string
  specialJanuaryOffer: string
}

export type CarColor = {
  name: string
  value: string
}

export type Car = {
  id: string
  modelName: string
  defaultModelTrim: CarAdditional
  defaultModelTrimLu: CarAdditional
  modelTrims: CarAdditional[]
  modelTrimsLu: CarAdditional[]
  isEco: boolean
  arKit?: string
  arCore?: string
  introNl: string
  introFr: string
  family: string
  previewImage?: string
  deeplink?: string
  colors: string[]
  colorsWithName: CarColor[]
  defaultColor: string
  productInformationPageNl: string
  productInformationPageFr: string
  offerPagesNl: string
  offerPagesFr: string
  configuratorNl: string
  configuratorFr: string
  discountTime: string
}

export type Family = {
  id: string
  name: string
  models: Car[]
}

export interface List<T> {
  count: number
  next: string
  previous: string
  results: T[]
}

export type ListOptions = {
  offset: number
  limit: number
}

export type Dealer = {
  address1: number
  address2: number
  address3: number
  address4: number
  attributes: number
  averageReviewRating: number
  businessHours: string
  categoryIds: string
  categoryNames: string
  city: string
  complexAttributes: number
  contactName: number
  contactPosition: number
  contactUrl: number
  countryCode: string
  countryName: string
  coupons: number
  dailyHoursList: number
  distance: number
  emailAddress: number
  events: number
  faceBookPageId: number
  faxNumber: number
  geoCodeStatusId: number
  googlePlaceId: number
  id: number
  imageFileUrl: number
  internetLocation: number
  isLeadManager: number
  isStoreLocator: number
  latitude: number
  lmServiceId: number
  locationTypeIconUrl: number
  locationTypeName: string
  longitude: number
  mobileNumber: number
  name: string
  phoneNumber: number
  postCode: string
  state: number
  thirdPartyId: string
  timeZone: number
  totalReviews: number
  url: number
  yelpPlaceId: number
}

export type GetCarsResponse = List<Car>

export type GetFamilyResponse = List<Family>

export type GetCarsResults =
  | {
      kind?: string
      cars: GetCarsResponse
    }
  | GeneralApiProblem

export type GetFamilyResults =
  | {
      kind?: string
      family: GetFamilyResponse
    }
  | GeneralApiProblem

export type Color = {
  id: number
  name: string
}

export type Audio = {
  id: number
  audio: string
  isActive: boolean
  position: number
}

export type GetAudioResponse = List<Audio>

export type GetColorsResponse = List<Color>

export type GetColorsResults =
  | {
      kind?: string
      colors: GetColorsResponse
    }
  | GeneralApiProblem

export type GetLocaleResponse = Record<string, string>

export type GetLocaleResults =
  | {
      kind?: string
      locale: GetLocaleResponse
    }
  | GeneralApiProblem

export type GetAudioResults =
  | { kind?: string; audio: GetAudioResponse }
  | GeneralApiProblem

export type GetDealersResponse = List<Dealer>

export type GetDealersResults =
  | { kind?: string; dealers: Dealer[] }
  | GeneralApiProblem
