import React, { FC, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { Column } from '../column'
import { LottieIcon } from '../lootie-icon'
import { Text } from '../text'

const useStyle = createUseStyles({
  container: {
    width: 180,
    height: 180
  },
  text: {
    marginTop: 8,
    alignText: 'center',
    height: 64,
    width: 140
  },
  '@media screen and (max-width: 455px) and (max-height: 712px)': {
    container: {
      width: 230,
      height: 122
    }
  },
  '@media screen and (max-width: 455px) and (min-height: 712px)': {
    container: {
      width: 230,
      height: 142
    }
  }
})

export type IntroPanelItemProps = {
  className?: string
  data: any
  tx?: string
  text: string
  time: number
}

export const IntroPanelItem: FC<IntroPanelItemProps> = ({
  className = '',
  data,
  time,
  text,
  tx
}) => {
  const [paused, changePaused] = useState(true)
  const classes = useStyle()

  useEffect(() => {
    const timeout = setTimeout(() => {
      changePaused(false)
    }, time)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Column
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
    >
      <LottieIcon data={data} paused={paused} />
      <Text className={classes.text} text={text} tx={tx} preset="h7" />
    </Column>
  )
}
