import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'

import { Text, TextProps } from '../text'

const useStyle = createUseStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  text: {
    cursor: 'pointer'
  }
})

export interface LinkProps extends TextProps {
  suffix?: boolean
  className?: string
  href: string
  textClassName?: string
}

export const Link: FC<LinkProps> = ({
  className = '',
  href,
  suffix,
  color,
  textClassName = '',
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const fill = color ? theme.colors[color] : undefined
  return (
    <a
      className={`${className} ${classes.link}`}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Text
        {...props}
        color={color}
        className={`${textClassName} ${classes.text}`}
      />
      {suffix && (
        <Icon name={IconName.ARROW} fill={fill} width={16} height={16} />
      )}
    </a>
  )
}
