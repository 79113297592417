import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { isMobile, isTablet } from 'react-device-detect'

import { LightTheme } from '../../theme'
import { FullLogo } from '../full-logo'
import { Icon, IconName, IconProps } from '../icon'
import { Row } from '../row'
import { TextProps } from '../text'
import { ButtonWithIcon } from '../button-with-icon'
import { HeaderProps } from './header.types'
import { Button } from '../button'
import { useAudioBackground } from '../../providers'

const useStyle = createUseStyles((theme: LightTheme) => ({
  counterContainer: {
    position: 'absolute',
    top: 40,
    left: 40,
    right: 40,
    zIndex: 2
  },
  panelContainer: {
    borderRadius: 32,
    padding: '4px 12px',
    backgroundColor: ({ menu }) => menu && theme.colors.main
  },
  panelIconContainer: {
    padding: '0px 12px',
    cursor: 'pointer'
  },
  logoContainer: {
    position: 'fixed',
    left: '40%',
    right: '40%'
  },
  button: {
    width: 140
  }
}))

export const HeaderBig: FC<HeaderProps> = ({
  ar,
  className,
  back,
  menu,
  onClick,
  onBackClick,
  onTestDriveClick
}) => {
  const { muted, handleOnMute } = useAudioBackground()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ menu, theme })
  const backButtonTextProps: TextProps = {
    text: 'BACK',
    tx: 'header.back',
    color: 'title'
  }
  const testDriveButtonTextProps: TextProps = {
    text: 'Test Drive',
    tx: 'testdrive.extra.button.01',
    color: 'white'
  }
  const icon: IconProps = {
    name: IconName.BACK,
    color: theme.colors.white
  }

  const iconMuteUrl = muted ? IconName.SOUND_OFF : IconName.SOUND_ON

  const handleOnMuted = () => {
    handleOnMute(!muted)
  }

  const devices = isMobile || isTablet

  const backButton = back ? (
    <ButtonWithIcon
      text={backButtonTextProps}
      icon={icon}
      onClick={onBackClick}
    />
  ) : (
    <Button
      backgroundColor="second"
      className={classes.button}
      preset="mini-filled"
      text={testDriveButtonTextProps}
      onClick={onTestDriveClick}
    />
  )
  return (
    <Row
      className={`${className || ''} ${classes.counterContainer}`}
      justifyContent="space-between"
    >
      {backButton}
      <Row className={classes.logoContainer}>
        <FullLogo />
      </Row>
      <Row className={classes.panelContainer}>
        {menu && (
          <>
            <Icon
              className={classes.panelIconContainer}
              name={iconMuteUrl}
              onClick={handleOnMuted}
            />

            {devices && ar && (
              <a href={ar}>
                <Icon
                  className={classes.panelIconContainer}
                  name={IconName.AR}
                />
              </a>
            )}
            <Icon
              className={classes.panelIconContainer}
              name={IconName.OPTIONS}
              onClick={onClick}
            />
          </>
        )}
      </Row>
    </Row>
  )
}
