import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'

import {
  AudioPlayer,
  DetailsButton,
  DetailsButtonHeader,
  DetailsMenu,
  DetailsMenuModal,
  ModelInfo,
  Row,
  Text,
  TextPresets
} from '../../components'
import { MainPage } from '../main'
import {
  useAudioBackground,
  useCars,
  useSelligent,
  useUnity
} from '../../providers'
import { useStyle } from './interior.styles'

export type InteriorPageProps = {
  onOpen: (state: boolean) => void
}

export const InteriorPage: FC<InteriorPageProps> = ({ onOpen }) => {
  const isSmall = window.innerWidth < 1028
  const [isMobileDevice, changeMobileDevice] = useState(isSmall || isMobile)

  const handleOnResize = () => {
    changeMobileDevice(window.innerWidth < 1028 || isMobile)
  }

  window.addEventListener('resize', handleOnResize)

  const [scroll] = useState(0)
  const [open, changeOpen] = useState(false)
  const [voice, setVoice] = useState(false)
  const intl = useIntl()

  const location = useLocation()
  const history = useHistory()
  const { getCarByName, audio: audioTheme } = useCars()
  const { scriptSitePostTag } = useSelligent()
  const { context: unityContext, changeBack } = useUnity()
  const { handleChangeAudio } = useAudioBackground()

  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]
  const carData = getCarByName(familyName, carName)
  if (!carData) {
    return <></>
  }

  const handleOnClick = (state?: boolean) => {
    if (typeof state === 'boolean') {
      changeOpen(state)
      onOpen(state)
    } else {
      onOpen(!open)
      changeOpen(!open)
    }
  }

  useEffect(() => {
    handleChangeAudio(audioTheme[1].audio, { volume: 0.75, loop: true })
    changeBack(true)
    scriptSitePostTag(carData.title, '', 'City')

    onOpen(false)
    const timeout =
      window.innerWidth > 1023 && setTimeout(() => handleOnClick(true), 5000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const detailsRef = useRef<HTMLDivElement>(null)
  const heightScreen = window.innerHeight
  const heightComponent = detailsRef.current
    ? detailsRef.current.offsetHeight
    : 0
  const maxBottom = heightScreen - heightComponent
  const maxScroll = maxBottom < 0 ? maxBottom : 0

  const classes = useStyle({ open, scroll, maxScroll })

  const handleOnBack = () => {
    unityContext.send('ProjectContext(Clone)', 'SetCarState', carData.id)
    history.push(`/family/${familyName}/car/${carName}/`)
  }

  const handleOnNavigateTestDrive = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    history.push(`/family/${familyName}/car/${carName}/test-drive-request`)
    event.stopPropagation()
    event.preventDefault()
  }

  const handleOnStartVoice = () => {
    setVoice(true)
  }

  unityContext.on('InteriorLoadCompleteHandler', handleOnStartVoice)

  const fromTx = intl.formatMessage({
    id: 'city.details.price.from',
    defaultMessage: 'From'
  })
  const { catalogPrice, price } = carData
  const formatedPrice = `${fromTx} € ${price} - `

  const titleComponent = (small: boolean) => {
    const titlePreset: TextPresets = small ? 'h4' : 'h1'
    return (
      <>
        <Text
          text={carData.title}
          preset={titlePreset}
          color="main"
          className={classes.title}
        />
        <Row className={classes.price}>
          <Text text={formatedPrice} preset="h5" color="second" />
          <Text
            className={classes.catalogPrice}
            text={catalogPrice}
            preset="h5"
            color="second"
          />
        </Row>
      </>
    )
  }
  return (
    <MainPage
      back
      ar={carData.ar}
      classNameHeader={classes.container}
      onBack={handleOnBack}
    >
      {isMobileDevice ? (
        <DetailsButtonHeader open={open} onClick={handleOnClick}>
          {titleComponent(isMobileDevice)}
        </DetailsButtonHeader>
      ) : (
        <DetailsButton open={open} onClick={handleOnClick} />
      )}
      <DetailsMenuModal>
        <DetailsMenu open={open}>
          {!isMobileDevice && titleComponent(isMobileDevice)}
          {open && (
            <ModelInfo
              open={open}
              {...carData}
              onOpenTest={handleOnNavigateTestDrive}
            />
          )}
        </DetailsMenu>
      </DetailsMenuModal>
      {voice && <AudioPlayer audioUrl={carData.intro} />}
    </MainPage>
  )
}
