import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  text: {
    ...theme.typography.subscription,
    color: theme.colors.black,
    cursor: 'default'
  }
}))

export const PointerButton: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={180}
      height={73}
      viewBox="0 0 180 73"
      fill="none"
      {...props}
    >
      <g>
        <rect
          x={3.5}
          y={1.5}
          width={172}
          height={38}
          rx={19}
          stroke={props.stroke || theme.colors.black}
          strokeOpacity={0.87}
          strokeWidth={3}
        />
        <text
          x="30"
          y="25"
          className={classes.text}
          fill={props.fill || theme.colors.black}
        >
          {props.value}
        </text>
      </g>
      <path
        d="M56.625 62.082c-2.19 6.017-8.886 9.124-14.927 6.925a11.6 11.6 0 01-6.576-6.005l-4.941-11.58a1.719 1.719 0 01.832-2.286c1.247-.58 2.207.303 2.563.71l3.39 3.875-.594 1.632a.94.94 0 00.564 1.203.94.94 0 001.205-.559l.782-2.15 5.81-15.96a1.724 1.724 0 012.209-1.025 1.724 1.724 0 011.034 2.205l-4.74 13.025a.94.94 0 00.563 1.203.94.94 0 001.205-.56l1.657-4.55a1.723 1.723 0 012.209-1.025 1.724 1.724 0 011.033 2.205l-1.87 5.139a.94.94 0 00.563 1.202.94.94 0 001.206-.559l1.442-3.963a1.723 1.723 0 012.21-1.025 1.724 1.724 0 011.033 2.205l-1.657 4.55a.94.94 0 00.564 1.204.94.94 0 001.205-.56l.802-2.202a1.724 1.724 0 012.209-1.024 1.724 1.724 0 011.033 2.204l-2.018 5.546z"
        fill={props.fill || theme.colors.background}
      />
      <path
        d="M33.742 63.591l.01.025.012.024a13.1 13.1 0 007.42 6.776c6.815 2.48 14.375-1.02 16.85-7.82l2.019-5.547a3.224 3.224 0 00-4.179-4.112l.022-.06a3.223 3.223 0 00-1.93-4.128 3.222 3.222 0 00-2.46.104 3.229 3.229 0 00-4.372-3.088l2.252-6.185a3.224 3.224 0 00-1.931-4.128 3.224 3.224 0 00-4.132 1.923L37.839 52.44l-3.134-3.582-.001-.002a4.4 4.4 0 00-1.458-1.102 3.365 3.365 0 00-2.865.02v.001a3.219 3.219 0 00-1.571 4.256l4.932 11.56z"
        stroke={props.stroke || theme.colors.black}
        strokeOpacity={0.87}
        strokeWidth={3}
      />
    </svg>
  )
}

export default PointerButton
