export enum IconName {
  LOADER = 'loader',
  LOGO = 'logo',
  LOGO_TEXT = 'logo-text',
  SOUND_OFF = 'sound-off',
  SOUND_ON = 'sound-on',
  OPTIONS = 'options',
  CLOSE = 'close',
  HUINDAY = 'huinday',
  CLOSE_ROUND = 'close-round',
  ARROW = 'arrow',
  BACK = 'back',
  ECO = 'eco',
  SEARCH = 'search',
  BEGIN_ARROW = 'begin-arrow',
  PLAY = 'play',
  AR = 'ar',
  POINTER_TRANSLATE = 'pointer-translate',
  POINTER_BUTTON = 'pointer-button',
  POINTER_USER = 'pointer-user'
}

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName
  value?: string
}
