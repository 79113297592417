import React, { FC, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useWhisbi } from '../../providers'
import { LightTheme } from '../../theme'
import { Icon, IconName, Row, Text } from '..'

const useStyle = createUseStyles((theme: LightTheme) => ({
  detailsButton: {
    backgroundColor: theme.colors.pink,
    cursor: 'pointer',
    position: 'absolute',
    right: ({ open }) => (open ? 376 : 0),
    top: 32,
    transition: 'right 0.25s ease-in-out 0s',
    zIndex: 2
  },
  innerDetailsButton: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    padding: 8
  },
  icon: {
    transition: 'transform 0.5s ease-in-out 0s',
    transform: ({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')
  },
  text: {
    cursor: 'pointer',
    padding: 4,
    animationName: '$fadeIn',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '0.5s'
  },
  textOpacity: {
    cursor: 'pointer',
    padding: 4,
    animationName: '$fadeOut',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '0.5s'
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  '@keyframes fadeOut': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}))

export type DetailsButtonProps = {
  open: boolean
  onClick: () => void
}

export const DetailsButton: FC<DetailsButtonProps> = ({ open, onClick }) => {
  const { changeRightPosition } = useWhisbi()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ open, theme })

  useEffect(() => {
    const rightWhisbiPosition = open ? '354px' : '80px'
    changeRightPosition(rightWhisbiPosition)
  }, [open])

  const textClassName = open ? classes.text : classes.textOpacity
  const tx = open ? 'family.details.close' : 'family.details.open'
  const text = open ? 'CLOSE DETAILS' : 'OPEN DETAILS'

  return (
    <Row className={classes.detailsButton} onClick={onClick}>
      <Row
        className={classes.innerDetailsButton}
        justifyContent="space-between"
      >
        <Icon
          className={classes.icon}
          name={IconName.ARROW}
          width={24}
          height={24}
          fill={theme.colors.second}
        />
        <Text
          className={textClassName}
          tx={tx}
          text={text}
          color="title"
          preset="title"
        />
      </Row>
    </Row>
  )
}
