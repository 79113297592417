import React, { FC, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { ModelMenuBig } from './model-menu.big'
import { ModelMenuSmall } from './model-menu.small'
import { ModelMenuProps } from './model-menu.types'

export const ModelMenu: FC<ModelMenuProps> = ({
  data,
  languages,
  testDrive,
  visible,
  onClose
}) => {
  const isSmall = window.innerWidth < 1028
  const [isMobileDevice, changeMobileDevice] = useState(isSmall || isMobile)

  const handleOnResize = () => {
    changeMobileDevice(window.innerWidth < 1028 || isMobile)
  }

  window.addEventListener('resize', handleOnResize)

  return (
    <>
      {isMobileDevice && (
        <ModelMenuSmall
          testDrive={testDrive}
          languages={languages}
          data={data}
          visible={visible}
          onClose={onClose}
        />
      )}
      {!isMobileDevice && (
        <ModelMenuBig
          testDrive={testDrive}
          languages={languages}
          data={data}
          visible={visible}
          onClose={onClose}
        />
      )}
    </>
  )
}
