import React, { FC } from 'react'

export type AndroidArItemProps = {
  link: string
}

export const AndroidArItem: FC<AndroidArItemProps> = ({ link }) => {
  return (
    <div>
      <a
        href={`intent://arvr.google.com/scene-viewer/1.0?file=${link}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}
      >
        {link}
      </a>
    </div>
  )
}
