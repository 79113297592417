import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useCookies } from 'react-cookie'
import { isMobile } from 'react-device-detect'

import { useAudioBackground, useCars, useUnity } from '../../providers'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { FooterLoader } from '../footer-loading'
import { ProgressBar } from '../progress-bar'
import { useLocale } from '../locale'
import { TextProps } from '../text'
import { Icon, IconName } from '../icon'
import { Button } from '../button'
import { CookiesBanner } from '../cookies-banner'
import { Animation } from '../logo-animation'
import { config } from '../../config'

const useStyles = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    backgroundColor: theme.colors.main,
    zIndex: 3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: ({ fadeOut }) => (fadeOut ? 0 : 1),
    transition: 'opacity 0.5s ease-in-out'
  },
  containerButton: {
    cursor: 'pointer'
  },
  mutedIcon: {
    position: 'absolute',
    top: 44,
    right: 120,
    cursor: 'pointer',
    zIndex: 3
  },
  containerStart: {
    backgroundColor: theme.colors.main,
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    opacity: ({ fadeOut }) => (fadeOut ? 0 : 1),
    transition: 'opacity 0.5s ease-in-out'
  },
  bottomBlockContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 2
  },
  cookies: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    right: 0,
    zIndex: 2
  },
  lottie: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  topBlockContainer: {
    flex: 1,
    width: '100vw',
    height: '100vh',
    position: 'relative'
  },
  button: {
    width: 280,
    pointerEvents: 'none',
    color: ({ hover }) => (hover ? theme.colors.white : theme.colors.second),
    backgroundColor: ({ hover }) =>
      hover ? theme.colors.lightBlue : theme.colors.transparent,
    borderColor: ({ hover }) =>
      hover ? theme.colors.lightBlue : theme.colors.second
  },
  arrow: {
    marginTop: 16,
    '&:focus $containerButton': {
      fill: theme.colors.second
    }
  },
  progress: {},
  '@media screen and (max-width: 1024px)': {
    mutedIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      cursor: 'pointer',
      zIndex: 3
    },
    cookie: {
      bottom: 224
    }
  },
  '@media screen and (max-height: 455px)': {
    progress: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2
    }
  }
}))

export type OverflowLoaderProps = {
  loading: boolean
  valueLoading?: string
  onComplete: (state: boolean) => void
  onChangeLoadingPercent: (loading: string) => void
}

export const OverflowLoader: FC<OverflowLoaderProps> = ({
  loading,
  valueLoading = '100%',
  onComplete,
  onChangeLoadingPercent,
  children,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const [cookies, setCookie] = useCookies(['enabled', 'secondLoading'])
  const { currentLocale } = useLocale()
  const { context: unityContext } = useUnity()
  const { play, handleOnMute, muted, handleChangeAudio } = useAudioBackground()
  const { audio } = useCars()
  const [fadeOut, changeFadeOut] = useState(false)
  const [visible, changeVisible] = useState(true)
  const [start, changeStart] = useState(false)
  const [hover, changeHover] = useState(false)

  const buttonTextProps: TextProps = {
    tx: 'start.02',
    text: 'Bezoek de showroom',
    color: 'second',
    preset: 'h7'
  }

  useEffect(() => {
    if (!loading && valueLoading === '100%') {
      changeFadeOut(true)
    }
  }, [loading, valueLoading])

  useEffect(() => {
    const timeout =
      fadeOut &&
      setTimeout(() => {
        changeVisible(false)
      }, 2000)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [fadeOut])

  const styleProps = {
    fadeOut,
    ...props,
    theme,
    hover
  }
  const classes = useStyles(styleProps)

  const moreInfoLink = isMobile
    ? `https://m.hyundai.be/${currentLocale}/legal`
    : `https://www.hyundai.be/${currentLocale}/legal.html`

  const handleOnLoad = () => {
    onComplete(false)
    setCookie('secondLoading', true, { path: '/' })
  }

  const handleOnStart = () => {
    if (cookies.enabled) {
      changeStart(true)
      if (!cookies.secondLoading) {
        play(true)
      } else {
        handleChangeAudio(audio[0].audio, { volume: 0.75, loop: true })
      }
    }
  }

  const handleOnSkipIntro = () => {
    handleOnLoad()
    handleChangeAudio(audio[0].audio, { volume: 0.75, loop: true })
  }

  const handleChangeOnMuted = () => {
    handleOnMute(!muted)
  }

  const handleOnProgress = (progression: number) => {
    const loadingPercentValue = Number(valueLoading.split('%')[0])
    if (progression !== loadingPercentValue && progression < 1) {
      const value = `${(progression * 100).toFixed(0)}%`
      onChangeLoadingPercent(value)
    }
  }

  const handleOnComplete = () => {
    const { publicURL, env } = config
    const publicUrl =
      env === 'development' ? 'http://localhost:3000' : publicURL

    onChangeLoadingPercent('100%')
    unityContext.send('ProjectContext(Clone)', 'SetMuted', muted ? 1 : 0)
    unityContext.send('ProjectContext(Clone)', 'SetPublicUrl', publicUrl)
  }

  unityContext.on('UnityLoadCompleteHandler', handleOnComplete)
  unityContext.on('progress', handleOnProgress)

  const iconName = muted ? IconName.SOUND_OFF : IconName.SOUND_ON
  const arrowFill = hover ? theme.colors.second : theme.colors.white

  const handleOnSubmitCookies = () => {
    setCookie('enabled', true, { path: '/' })
  }

  const handleOnHover = (state: boolean) => () => {
    if (cookies.enabled) {
      changeHover(state)
    }
  }

  if (visible) {
    return (
      <Column className={classes.container} justifyContent="flex-end">
        <Icon
          className={classes.mutedIcon}
          name={iconName}
          onClick={handleChangeOnMuted}
        />
        {!start && (
          <Column className={classes.containerStart}>
            <Column
              className={classes.containerButton}
              onMouseOver={handleOnHover(true)}
              onMouseLeave={handleOnHover(false)}
              onClick={handleOnStart}
            >
              <Button
                className={classes.button}
                text={buttonTextProps}
                preset="outlined-big"
              />
              <Icon
                className={classes.arrow}
                fill={arrowFill}
                name={IconName.BEGIN_ARROW}
              />
            </Column>
          </Column>
        )}
        {start && (
          <Animation
            secondStart={cookies.secondLoading}
            currentLocale={currentLocale}
            onLoad={handleOnLoad}
          />
        )}
        <Column className={classes.bottomBlockContainer}>
          <ProgressBar
            className={classes.progress}
            skipIntro={!cookies.secondLoading && start}
            value={valueLoading}
            onSkip={handleOnSkipIntro}
          />
          <FooterLoader />
        </Column>
        {!cookies.enabled && (
          <Column className={classes.cookies}>
            <CookiesBanner
              moreInfoLink={moreInfoLink}
              onSubmit={handleOnSubmitCookies}
            />
          </Column>
        )}
      </Column>
    )
  }
  return <></>
}
