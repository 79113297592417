import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState
} from 'react'
import TagManager from 'react-gtm-module'
import { useHistory, useLocation } from 'react-router-dom'
import { UnityContext } from 'react-unity-webgl'

import { config } from '../../config'
import { useCars } from '../data-context'

const frameworkUrl = `${config.publicURL}/Build/Build.framework.js`
const loaderUrl = `${config.publicURL}/Build/Build.loader.js`
const dataUrl = `${config.publicURL}/Build/Build.data`
const codeUrl = `${config.publicURL}/Build/Build.wasm`
const streamingAssetsUrl = `${config.publicURL}/StreamingAssets/`

export const unityContext = new UnityContext({
  frameworkUrl,
  loaderUrl,
  dataUrl,
  codeUrl,
  streamingAssetsUrl
})

export enum NavigatePath {
  HUB = 'hub',
  FAMILY = 'family',
  CAR = 'car',
  INTERIOR = 'interior'
}

export type NavigateState = {
  path: NavigatePath
  familyId?: string
  carId?: string
}

export type UnityContextProviderProps = {
  context: UnityContext
  route: NavigateState
  back: boolean
  changeBack: (state: boolean) => void
  clearWebGL: () => void
}

export const UnityContextProvider = createContext<UnityContextProviderProps>({
  context: unityContext,
  route: { path: NavigatePath.HUB },
  back: false,
  changeBack: () => null,
  clearWebGL: () => null
})

export const useUnity = () =>
  useContext<UnityContextProviderProps>(UnityContextProvider)

export const UnityProvider: FC = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { getCarById, getFamilyByName, getCarByName } = useCars()
  const [back, changeBack] = useState(false)
  const [route, changeRoute] = useState<NavigateState>({
    path: NavigatePath.HUB
  })

  const handleOnOpenHub = () => {
    unityContext.send('ProjectContext(Clone)', 'SetHubState')
  }

  const splitUrl = location.pathname.split('/')
  const carName = location.pathname.includes('car') ? splitUrl[4] : ''
  const familyName = location.pathname.includes('family') ? splitUrl[2] : ''

  const clearWebGL = () => {
    // @ts-ignore
    const canvas = unityContext.unityComponentReference
      .htmlCanvasElementReference as HTMLCanvasElement
    const gl2 = canvas.getContext('webgl2')
    if (!gl2) {
      const gl = canvas.getContext('webgl') as WebGL2RenderingContext
      gl.clear(gl.COLOR_BUFFER_BIT)
      gl.clear(gl.DEPTH_BUFFER_BIT)
      gl.clear(gl.STENCIL_BUFFER_BIT)
    } else {
      gl2.clear(gl2.COLOR_BUFFER_BIT)
      gl2.clear(gl2.DEPTH_BUFFER_BIT)
      gl2.clear(gl2.STENCIL_BUFFER_BIT)
    }
  }

  useEffect(() => {
    if (!carName && !familyName) {
      handleOnOpenHub()
    }
  }, [])

  useEffect(() => {
    // @ts-ignore
    const canvas = unityContext.unityComponentReference
      .htmlCanvasElementReference as HTMLCanvasElement

    const { devicePixelRatio } = window
    canvas.height = 600 * devicePixelRatio || 1
    canvas.width = 900 * devicePixelRatio || 1

    clearWebGL()
  }, [unityContext])

  const handleOnNavigate = (stateJSON: string) => {
    const state: NavigateState = JSON.parse(stateJSON)
    const familyData = getFamilyByName(familyName)
    const carData = state.carId
      ? getCarById(familyData.id, state.carId)
      : getCarByName(familyName, carName)

    switch (state.path) {
      case NavigatePath.HUB: {
        const tagManagerArgs = {
          dataLayer: {
            virtualPageTitle: 'Hub',
            virtualPageURL: `${config.publicURL}/`,
            event: 'VirtualPageview'
          },
          dataLayerName: 'dataLayer'
        }

        TagManager.dataLayer(tagManagerArgs)
        history.push('/')
        break
      }
      case NavigatePath.CAR: {
        if (carData) {
          history.push(`/family/${familyName}/car/${carData.value}`)
        }
        break
      }
      case NavigatePath.INTERIOR: {
        if (carData) {
          history.push(`/family/${familyName}/car/${carData.value}/interior`)
        }
        break
      }
      default:
        break
    }

    changeRoute(state)
  }

  unityContext.on('navigate', handleOnNavigate)
  const value = {
    context: unityContext,
    route,
    back,
    changeBack,
    clearWebGL
  }

  return (
    <UnityContextProvider.Provider value={value}>
      {children}
    </UnityContextProvider.Provider>
  )
}
