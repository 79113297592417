import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { ButtonWithIcon } from '../button-with-icon'
import { Column } from '../column'
import { IconName, IconProps } from '../icon'
import { TextProps } from '../text'

const useStyle = createUseStyles({
  iconBottomScrollButton: {
    display: 'inline-block',
    transform: 'rotate(90deg)'
  },
  bottomTextScrollButton: {
    display: 'inline-block',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed'
  },
  scrollButtonsContainer: {
    right: 0,
    minWidth: 32,
    height: 'calc(100% - 92px)',
    padding: '46px 0'
  },
  scrollButtonsPosition: {
    height: 'calc(100% - 92px)',
    right: 16,
    position: 'fixed'
  }
})

export type ScrollIndicatorProps = {
  className?: string
  open: boolean
}

export const ScrollIndicator: FC<ScrollIndicatorProps> = ({
  className,
  open
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const buttonBottomScrollText: TextProps = {
    className: classes.bottomTextScrollButton,
    text: 'SCROLL',
    tx: 'family.details.scroll',
    preset: 'title',
    color: 'title'
  }
  const bottomButtonIconScroll: IconProps = {
    className: classes.iconBottomScrollButton,
    name: IconName.ARROW,
    fill: theme.colors.title,
    width: 24,
    height: 24
  }

  if (!open) {
    return <></>
  }

  return (
    <Column
      className={`${className || ''} ${classes.scrollButtonsContainer}`}
      justifyContent="space-between"
    >
      <Column
        className={classes.scrollButtonsPosition}
        justifyContent="flex-end"
      >
        <ButtonWithIcon
          preset="transparent"
          text={buttonBottomScrollText}
          iconRight={bottomButtonIconScroll}
        />
      </Column>
    </Column>
  )
}
