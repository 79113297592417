import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Play: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8.657 8.348a1 1 0 011.547-.837l5.829 3.809a1 1 0 010 1.674l-5.829 3.809a1 1 0 01-1.547-.837V8.348z"
        stroke={props.fill || theme.colors.white}
      />
    </svg>
  )
}

export default Play
