import React, { FC } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import invert from 'invert-color'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Text } from '../text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 32,
    backgroundColor: ({ color }) => color,
    border: '1px solid',
    borderColor: ({ border, color }) => (border ? theme.colors.title : color),
    cursor: 'pointer',
    width: ({ active }) => (active ? 112 : 30),
    height: 30,
    transition: 'width 0.5s ease-in-out',
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  text: {
    color: ({ color }) => invert(color, true),
    writingMode: 'horizontal-tb',
    textOrientation: 'mixed'
  }
}))

export type PaletteItemProps = {
  active: boolean
  border?: boolean
  color: string
  text?: string
  onClick: (color: string) => void
}

export const PaletteItemHorizontal: FC<PaletteItemProps> = ({
  active,
  border,
  color,
  text,
  onClick
}) => {
  const theme = useTheme()
  const classes = useStyle({ active, border, color, theme })
  const handleOnClick = () => {
    onClick(color)
  }
  return (
    <Column className={classes.container} onClick={handleOnClick}>
      {active && (
        <Text className={classes.text} text={text || color} preset="title" />
      )}
    </Column>
  )
}
