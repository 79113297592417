import React from 'react'

import { Column, IOSArItem, Row, Text } from '../../components'
import { AndroidArItem } from '../../components/android-ar-item'

const dataIOS = [
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_Tucson.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_Santa_Fe.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_Kona_HEV.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_Kona_EV.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_IONIQ_HEV.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_IONIQ_phev.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_ioniq_ev.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_i30.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_i30_wagon.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_i30_fastback.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_i20.usdz',
  'https://3d.aidinar.com/m/usdz_asset_bundle/Hyundai_i10.usdz'
]

const dataAndroid = [
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_Tucson.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_Santa_Fe.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_Kona_HEV.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_Kona_EV.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_IONIQ_HEV.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_IONIQ_phev.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_ioniq_ev.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_i30.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_i30_wagon.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_i30_fastback.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_i20.glb',
  'https://3d.aidinar.com/m/glb_asset_bundle/Hyundai_i10.glb'
]

export const ArTest = () => {
  return (
    <Column style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
      <Row>
        <Text text="IOS Links" />
      </Row>
      {dataIOS.map((link, index) => (
        <IOSArItem key={`ios_ar_${index}`} link={link} />
      ))}
      <Row>
        <Text text="Android Links" />
      </Row>
      {dataAndroid.map((link, index) => (
        <AndroidArItem key={`android_ar_${index}`} link={link} />
      ))}
    </Column>
  )
}
