import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const CloseRound: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={7.5} fill={theme.colors.title} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.828 9.172a.444.444 0 00-.628 0l-2.2 2.2-2.2-2.2a.444.444 0 10-.628.628l2.2 2.2-2.2 2.2a.444.444 0 10.628.629l2.2-2.2 2.2 2.2a.444.444 0 10.628-.629l-2.2-2.2 2.2-2.2a.444.444 0 000-.628z"
        fill={props.fill || theme.colors.white}
      />
    </svg>
  )
}

export default CloseRound
