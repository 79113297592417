import React, { FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Row } from '../row'
import { TextProps } from '../text'
import { CityCategoryPickerItem } from './city-category-picker-item'

const useStyle = createUseStyles({
  container: {
    margin: '24px 0',
    width: '100%',
    minHeight: 48
  },
  left: {
    position: 'absolute',
    left: 0
  },
  right: {
    position: 'absolute',
    right: 0
  },
  active: {
    zIndex: 1
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      margin: '12px 0'
    }
  }
})

export type CityCategoryPickerProps = {
  firstElement?: TextProps
  secondElement?: TextProps
  defaultActive?: number
  onClick?: (id: number) => void
}

export const CityCategoryPicker: FC<CityCategoryPickerProps> = ({
  firstElement,
  secondElement,
  defaultActive = 0,
  onClick
}) => {
  const [active, changeActive] = useState(defaultActive)
  const classes = useStyle()

  const getActive = (index: number) => {
    if (active === index) {
      return classes.active
    }
    return ''
  }

  const handleOnActive = (id: number) => () => {
    changeActive(id)
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <Row className={classes.container} justifyContent="flex-start">
      <CityCategoryPickerItem
        active={active === 0}
        className={`${getActive(0)} ${classes.left}`}
        tx="family.details.all"
        text="All"
        {...firstElement}
        onClick={handleOnActive(0)}
      />
      <CityCategoryPickerItem
        eco
        className={`${getActive(1)} ${classes.right}`}
        active={active === 1}
        tx="family.details.eco"
        text="ECO"
        {...secondElement}
        onClick={handleOnActive(1)}
      />
    </Row>
  )
}
