import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { createUseStyles, useTheme } from 'react-jss'
import he from 'he'
import { Color, LightTheme } from '../../theme'

export type TextComponentProps = {
  children: ReactNode
  className?: string
}

interface TextPropsWithColor extends TextProps {
  color: Color
}

const colorByProps = (theme: LightTheme, defaultColor?: string) => (
  props: TextPropsWithColor
) => theme.colors[props.color] || defaultColor || theme.colors.black

const useStyle = createUseStyles((theme: LightTheme) => ({
  h1: {
    ...theme.typography.h1,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h2: {
    ...theme.typography.h2,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h3: {
    ...theme.typography.h3,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h4: {
    ...theme.typography.h4,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h5: {
    ...theme.typography.h5,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h6: {
    ...theme.typography.h6,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  h7: {
    ...theme.typography.h7,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  time: {
    ...theme.typography.bigNumber,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  'big-number': {
    ...theme.typography.bigNumber,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  text: {
    ...theme.typography.text,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  successMain: {
    ...theme.typography.success,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  statusLabel: {
    ...theme.typography.boldTitle,
    cursor: 'default',
    padding: '12px 18px',
    borderRadius: '16px',
    backgroundColor: theme.colors.second,
    color: colorByProps(theme, theme.colors.white),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  greyPlaceholder: {
    ...theme.typography.boldTitle,
    cursor: 'default',
    color: theme.colors.title,
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  miniStatusLabel: {
    ...theme.typography.boldTitle,
    cursor: 'default',
    padding: '2px 18px',
    borderRadius: '16px',
    backgroundColor: theme.colors.red,
    color: colorByProps(theme, theme.colors.white),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  title: {
    ...theme.typography.title,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  subscription: {
    ...theme.typography.subscription,
    cursor: 'default',
    color: colorByProps(theme),
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  }
}))

const spanComponent = ({ children, ...props }: TextComponentProps) => (
  <span {...props}>{children}</span>
)

const presetComponents = {
  time: spanComponent,
  'big-number': spanComponent,
  h1: ({ children, ...props }: TextComponentProps) => (
    <h1 {...props}>{children}</h1>
  ),
  h2: ({ children, ...props }: TextComponentProps) => (
    <h2 {...props}>{children}</h2>
  ),
  h3: ({ children, ...props }: TextComponentProps) => (
    <h3 {...props}>{children}</h3>
  ),
  h4: ({ children, ...props }: TextComponentProps) => (
    <h4 {...props}>{children}</h4>
  ),
  h5: ({ children, ...props }: TextComponentProps) => (
    <h5 {...props}>{children}</h5>
  ),
  h6: ({ children, ...props }: TextComponentProps) => (
    <h6 {...props}>{children}</h6>
  ),
  h7: spanComponent,
  text: spanComponent,
  statusLabel: spanComponent,
  successMain: spanComponent,
  greyPlaceholder: spanComponent,
  miniStatusLabel: spanComponent,
  title: spanComponent,
  subscription: spanComponent
}

export type TextPresets = keyof typeof presetComponents

export interface TextProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color?: Color
  className?: string
  description?: string
  text: string
  tx?: string
  values?: any
  preset?: TextPresets
  innerHtml?: boolean
}

export type PresetComponents = keyof typeof presetComponents

const removeBackSlash = (str: string) => {
  return str.replace(/\\/g, '')
}

export const Text: FC<TextProps> = ({
  className = '',
  description,
  text = '',
  tx,
  values,
  preset = 'text',
  innerHtml,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyle({ ...props, theme })
  const Component = presetComponents[preset]

  if (!tx) {
    return (
      <Component className={`${className} ${classes[preset]}`} {...props}>
        {removeBackSlash(he.decode(text))}
      </Component>
    )
  }

  return (
    <Component className={`${className} ${classes[preset]}`} {...props}>
      <FormattedMessage
        id={tx || text}
        description={description}
        defaultMessage={text}
        values={values}
      />
    </Component>
  )
}
