import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Search: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 23a8 8 0 116.904-3.956.665.665 0 01.234.151l2.667 2.667a.667.667 0 11-.943.943l-2.667-2.667a.657.657 0 01-.033-.036A7.983 7.983 0 0115 23zm0-2a6 6 0 100-12 6 6 0 000 12z"
        fill={props.fill || theme.colors.second}
      />
    </svg>
  )
}

export default Search
