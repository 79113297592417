import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const SoundOff: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  const [hover, changeHover] = useState(false)

  const handleOnHover = (state: boolean) => () => {
    changeHover(state)
  }

  const color = hover ? theme.colors.lightBlue : theme.colors.white
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.867 7.495c.742-.546 1.744-.655 2.56-.254.854.4 1.336 1.237 1.373 2.147v12.81c0 .91-.52 1.747-1.336 2.147a2.23 2.23 0 01-1.076.255 2.52 2.52 0 01-1.484-.51l-4.637-3.493h-2.04C5.001 20.597 4 19.614 4 18.413v-5.24c0-1.201 1.002-2.184 2.226-2.184H8.23l4.637-3.494zm1.596 14.885c.11-.073.11-.145.11-.182V9.351c0-.036 0-.109-.11-.182-.112-.036-.186 0-.223.037l-4.934 3.712a1.049 1.049 0 01-.668.218H6.226v5.277l2.412.037c.222 0 .482.072.668.218l4.934 3.676c.074.072.148.072.223.036z"
        fill={color}
      />
      <path
        d="M26.872 12.924c-.431-.432-1.114-.432-1.51 0l-1.364 1.366-1.366-1.366c-.431-.432-1.114-.432-1.509 0-.431.431-.431 1.114 0 1.51l1.365 1.366-1.365 1.366c-.431.432-.431 1.115 0 1.51.216.216.467.324.755.324.287 0 .539-.108.754-.324l1.365-1.366 1.366 1.366c.215.216.467.324.754.324.288 0 .54-.108.755-.324.431-.431.431-1.114 0-1.51l-1.33-1.366 1.366-1.366c.395-.396.395-1.079-.036-1.51z"
        fill={color}
      />
    </svg>
  )
}

export default SoundOff
