import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    right: ({ open }) => (open ? 456 : 80),
    transition: 'right 0.25s ease-in-out 0s'
  },
  palette: {
    position: 'fixed',
    left: '10%',
    top: '20%',
    bottom: 0
  },
  title: {
    textAlign: 'left'
  },
  price: {
    marginTop: 4
  },
  catalogPrice: {
    textDecoration: 'line-through',
    marginLeft: 4
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      right: () => 0,
      transition: 'none'
    },
    palette: {
      position: 'fixed',
      left: 24,
      top: 72,
      bottom: 0
    }
  },
  '@media screen and (max-height: 455px)': {
    palette: {
      position: 'fixed',
      left: 16,
      top: 100,
      bottom: 'auto'
    }
  }
})
