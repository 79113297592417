import React, { FC, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useUnity } from '../../providers'
import { CarColor } from '../../services/api.types'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Text } from '../text'
import { PaletteItem } from './palette-item'
import { PaletteItemHorizontal } from './palette-item-horizontal'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 8,
    backgroundColor: theme.colors.white,
    borderRadius: 25,
    visibility: ({ open }) => (open ? 'visible' : 'hidden'),
    opacity: ({ open }) => (open ? 1 : 0),
    transition: 'opacity 0.5s, visibility 0.5s ease-in-out'
  },
  text: {
    marginRight: 4,
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    cursor: 'pointer'
  },
  close: {
    cursor: 'pointer',
    transform: 'rotate(180deg)'
  },
  '@media screen and (max-height: 455px)': {
    container: {
      flexDirection: 'row'
    },
    text: {
      writingMode: 'horizontal-tb',
      textOrientation: 'mixed'
    },
    close: {
      flexDirection: 'row-reverse',
      transform: 'rotate(0deg)'
    }
  }
}))

export type PaletteProps = {
  defaultColor?: string
  className?: string
  colors: CarColor[]
  carName: string
  onChange: (color: string) => void
}

export const Palette: FC<PaletteProps> = ({
  defaultColor,
  colors,
  className,
  carName,
  onChange
}) => {
  const isSmall = window.innerHeight < 429
  const [isMobileHorizontalDevice, changeHorizontalDevice] = useState(isSmall)

  const handleOnResize = () => {
    changeHorizontalDevice(window.innerHeight < 429)
  }

  window.addEventListener('resize', handleOnResize)

  const { context: unityContext, route } = useUnity()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    onChange(defaultColor || colors[0].value)
    return () => {
      setOpen(false)
    }
  }, [])
  useEffect(() => {
    onChange(defaultColor || colors[0].value)
    setOpen(false)
  }, [route, carName])
  const [color, changeColor] = useState(defaultColor || colors[0].value)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, open })
  const handleOnClick = (currentColor: string) => {
    changeColor(currentColor)
    onChange(currentColor)
  }

  const handleOnOpen = () => {
    setOpen(!open)
  }

  unityContext.on('openPalette', handleOnOpen)

  return (
    <Column className={className} justifyContent="flex-start">
      <Column className={classes.container}>
        <Column className={classes.close} onClick={handleOnOpen}>
          <Text
            className={classes.text}
            tx="city.palette.close"
            text="CLOSE"
            color="title"
          />
          <Icon name={IconName.CLOSE_ROUND} />
        </Column>
        {colors.map((colorItem, index) => {
          const border =
            theme.colors.white.toLowerCase() === colorItem.value.toLowerCase()
          return isMobileHorizontalDevice ? (
            <PaletteItemHorizontal
              key={`color_${index}`}
              active={colorItem.value === color}
              border={border}
              text={colorItem.name}
              color={colorItem.value}
              onClick={handleOnClick}
            />
          ) : (
            <PaletteItem
              key={`color_${index}`}
              active={colorItem.value === color}
              border={border}
              text={colorItem.name}
              color={colorItem.value}
              onClick={handleOnClick}
            />
          )
        })}
      </Column>
    </Column>
  )
}
