import React from 'react'
import { createUseStyles } from 'react-jss'

import { useLocale } from '../locale'
import { Row } from '../row'

import warantyFr from '../../assets/5_waranty_fr.png'
import warantyNl from '../../assets/5_waranty_nl.png'
import { FullLogo } from '../full-logo'
import { Locale } from '../../services'

const useStyle = createUseStyles({
  row: {
    width: 'calc(100% - 48px)',
    padding: 24
  },
  text: {
    color: '#fff',
    marginTop: 16
  },
  img: {
    width: 278,
    height: 70,
    objectFit: 'cover'
  },
  '@media screen and (max-width: 1024px)': {
    img: {
      width: 158,
      height: 40
    },
    row: {
      width: 'calc(100% - 32px)',
      padding: 16
    }
  }
})

export type FooterLoaderProps = {}

export const FooterLoader = () => {
  const { currentLocale } = useLocale()
  const classes = useStyle()
  const img = currentLocale === Locale.FR ? warantyFr : warantyNl
  return (
    <Row
      alignItems="flex-end"
      justifyContent="space-between"
      className={classes.row}
    >
      <FullLogo color="white" />
      <img className={classes.img} src={img} />
    </Row>
  )
}
