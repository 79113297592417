import React, { FC, MouseEvent, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles({
  container: {
    margin: '8px 0',
    width: 676
  },
  titleContainer: {
    padding: '8px 0',
    width: '100%'
  },
  itemsContainer: {
    overflowY: 'hidden',
    height: ({ open }) => (open ? '100%' : 0)
  },
  description: {
    opacity: ({ open }) => (open ? 1 : 0),
    transition: 'opacity 0.5s ease-in-out 0s',
    textTransform: 'uppercase'
  },
  arrow: {
    marginRight: 8,
    transform: ({ open }) => (open ? 'rotate(0deg)' : 'rotate(90deg)'),
    transition: 'transform 0.5s ease-in-out 0s'
  },
  '@media screen and (max-width: 1024px)': {
    container: {
      margin: '0',
      width: '100%'
    },
    titleContainer: {
      padding: '0'
    }
  }
})

export type ModelSliderRef = {
  close: () => void
  open: () => void
}

export type ModelSliderProps = {
  id: string
  open: boolean
  tx?: string
  name: string
  children: ReactNode
  onOpen: (id: string) => void
  onClose: () => void
  onCloseModal: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const ModelSlider: FC<ModelSliderProps> = ({
  name,
  children,
  id,
  open,
  onOpen,
  onClose,
  onCloseModal
}) => {
  const history = useHistory()
  const classes = useStyle({ open })

  const discoverValues = {
    family_name: name
  }

  const handleOnChange = () => {
    if (open) {
      onClose()
    } else {
      onOpen(id)
    }
  }

  const handleOnClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (open) {
      history.push(`/family/${id}`)
      onCloseModal(event)
      event.stopPropagation()
    }
  }

  return (
    <Column
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Row
        className={classes.titleContainer}
        justifyContent="space-between"
        alignItems="flex-end"
        onClick={handleOnChange}
      >
        <Text text={name} preset="h4" />
        <Row>
          <Text
            className={classes.description}
            color="title"
            text="Discover all"
            tx="menu.discover.all"
            values={discoverValues}
            onClick={handleOnClick}
          />
          <Icon className={classes.arrow} name={IconName.ARROW} />
        </Row>
      </Row>
      <Column className={classes.itemsContainer} justifyContent="flex-start">
        {children}
      </Column>
    </Column>
  )
}
