import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState
} from 'react'

import { ModelCategoriesItem, useLocale } from '../../components'
import { config } from '../../config'
import { create, Locale, TestDriveData } from '../../services'
import { ModelInfoType, ModelListWithPriceType } from './data-context.types'
import {
  Audio,
  Car,
  Color,
  Dealer,
  Family,
  ListOptions
} from '../../services/api.types'
import {
  formatPrice,
  getModelItemFromCar,
  getValueFromModelName
} from './utils'

type CarsContextValues = {
  audio: Audio[]
  loading: boolean
  data: Car[]
  family: Family[]
  categories: ModelCategoriesItem[]
  colors: Color[]
  dealers: Dealer[]
  getFamilyById: (id: string) => ModelListWithPriceType
  getCarById: (familyId: string, id: string) => ModelInfoType | null
  sentRequestToTestDrive: (data: TestDriveData) => void
  getCarByName: (familyName: string, name: string) => ModelInfoType | null
  getFamilyByName: (name: string) => ModelListWithPriceType
  getDealers: (city: string) => void
}

const defaultFamily = {
  id: '',
  categoryName: '',
  eco: false,
  items: []
}

const initialState: CarsContextValues = {
  audio: [],
  loading: false,
  data: [],
  family: [],
  categories: [],
  colors: [],
  dealers: [],
  getFamilyById: () => defaultFamily,
  getFamilyByName: () => defaultFamily,
  getCarById: () => null,
  getCarByName: () => null,
  sentRequestToTestDrive: () => null,
  getDealers: () => null
}

const values = (value: CarsContextValues) => {
  return value
}

const CarsContext = createContext(values(initialState))
const api = create(config.apiURL)

export const useCars = () => useContext(CarsContext)

export const actions = {
  getCars: async () => {
    const options: ListOptions = {
      limit: 1000,
      offset: 0
    }
    const response = await api.getCars(options)

    return response
  },
  getFamily: async () => {
    const options: ListOptions = {
      limit: 1000,
      offset: 0
    }
    const response = await api.getFamily(options)

    return response
  },
  getColors: async () => {
    const options: ListOptions = {
      limit: 1000,
      offset: 0
    }
    const response = await api.getColors(options)

    return response
  },
  getAmbientSound: async () => {
    const response = await api.getAmbientSound()

    return response
  },

  sentRequestToTestDrive: async (data: TestDriveData) => {
    const response = await api.sentRequestToTestDrive(data)

    return response
  },
  sentCityOnNearDealer: async (data: string) => {
    const response = await api.sentCityOnNearDealer(data)

    return response
  }
}

export const normalizeCategory = () => {}

export const CarsProvider: FC = ({ children }) => {
  const isLux = config.country === 'LUX'
  const { currentLocale } = useLocale()
  const isFr = currentLocale === Locale.FR

  const [data, setData] = useState<Car[]>([])
  const [family, setFamily] = useState<Family[]>([])
  const [categories, setCategories] = useState<ModelCategoriesItem[]>([])
  const [loading, setLoading] = useState(true)
  const [colors, setColors] = useState<Color[]>([])
  const [audio, setAudio] = useState<Audio[]>([])
  const [dealers, setDealers] = useState<Dealer[]>([])

  const getCars = async () => {
    const response = await actions.getCars()

    if (response.kind === 'ok') {
      setData(response.cars.results)
    }
  }

  const getFamily = async () => {
    const response = await actions.getFamily()

    if (response.kind === 'ok') {
      const families = response.family.results
      const currentCategories: ModelCategoriesItem[] = families.map(
        (familyItem) => ({
          id: familyItem.id,
          name: familyItem.name,
          data: familyItem.models.map((model) => ({
            id: model.id,
            value: getValueFromModelName(model.modelName),
            image:
              model.previewImage ||
              'https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/Kona/Gallery/pc/Hyundai-Kona-Electric-Gallery-1.jpg',
            text: model.modelName
          }))
        })
      )
      setCategories(currentCategories)
      setFamily(families)
    }
  }

  const getFamilyFindByName = (name: string) => {
    const currentFamily = family.find(
      (familyItem) => familyItem.name.toLowerCase() === name.toLowerCase()
    )

    return currentFamily
  }

  const getCarFindByName = (cars: Family, name: string) => {
    const currentCar = cars.models.find(
      (car) => getValueFromModelName(car.modelName) === name
    )

    return currentCar
  }

  const getFamilyFindId = (id: string) => {
    const currentFamily = family.find((familyItem) => familyItem.id === id)

    return currentFamily
  }

  const getCarFindId = (cars: Family, id: string) => {
    const currentCar = cars.models.find((car) => car.id === id)

    return currentCar
  }

  const getFamilyById = (id: string): ModelListWithPriceType => {
    const currentFamily = getFamilyFindId(id)

    if (currentFamily) {
      const currentCategory = {
        id: currentFamily.id,
        categoryName: currentFamily.name,
        eco: currentFamily.models[0].isEco,
        items: currentFamily.models.map((model) => {
          const trim = isLux ? model.defaultModelTrimLu : model.defaultModelTrim

          return {
            eco: model.isEco,
            id: model.id,
            image:
              model.previewImage ||
              'https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/Kona/Gallery/pc/Hyundai-Kona-Electric-Gallery-1.jpg',
            text: model.modelName,
            price: `${formatPrice(trim.totalPrice)} -`,
            catalogPrice: `(${formatPrice(trim.catalog)})`,
            value: getValueFromModelName(model.modelName)
          }
        })
      }
      return currentCategory
    }

    return defaultFamily
  }

  const getFamilyByName = (name: string): ModelListWithPriceType => {
    const currentFamily = getFamilyFindByName(name)

    if (currentFamily) {
      const currentCategory = {
        id: currentFamily.id,
        categoryName: currentFamily.name,
        eco: currentFamily.models[0].isEco,
        items: currentFamily.models.map((model) => {
          const trim = isLux ? model.defaultModelTrimLu : model.defaultModelTrim

          return {
            eco: model.isEco,
            id: model.id,
            image:
              model.previewImage ||
              'https://www.hyundai.com/content/dam/hyundai/in/en/data/find-a-car/Kona/Gallery/pc/Hyundai-Kona-Electric-Gallery-1.jpg',
            text: model.modelName,
            price: `${formatPrice(trim.totalPrice)} -`,
            catalogPrice: `(${formatPrice(trim.catalog)})`,
            value: getValueFromModelName(model.modelName)
          }
        })
      }
      return currentCategory
    }

    return defaultFamily
  }

  const getCarById = (familyId: string, id: string): ModelInfoType | null => {
    const currentFamily = getFamilyFindId(familyId)

    if (currentFamily) {
      const currentCar = getCarFindId(currentFamily, id)
      return getModelItemFromCar(currentCar, { isFr, isLux })
    }

    return null
  }

  const getCarByName = (
    familyName: string,
    name: string
  ): ModelInfoType | null => {
    const currentFamily = getFamilyFindByName(familyName)

    if (currentFamily) {
      const currentCar = getCarFindByName(currentFamily, name)
      return getModelItemFromCar(currentCar, { isFr, isLux })
    }

    return null
  }

  const getColors = async () => {
    const response = await actions.getColors()

    if (response.kind === 'ok') {
      const colorsCar = response.colors.results

      setColors(colorsCar)
    }
  }

  const getAmbientSound = async () => {
    const response = await actions.getAmbientSound()

    if (response.kind === 'ok') {
      const audioResults = response.audio.results

      setAudio(audioResults)
    }

    setLoading(false)
  }

  const getDealers = async (city: string) => {
    const response = await actions.sentCityOnNearDealer(city)
    if (response.kind === 'ok') {
      setDealers(response.dealers)
    }
  }

  const sentRequestToTestDrive = async (testDriveData: TestDriveData) => {
    await actions.sentRequestToTestDrive(testDriveData)
  }

  useEffect(() => {
    setLoading(true)
    getAmbientSound()
    getFamily()
    getCars()
    getColors()
  }, [])

  useEffect(() => {
    getCars()
  }, [currentLocale])

  return (
    <CarsContext.Provider
      value={values({
        audio,
        loading,
        data,
        family,
        categories,
        getFamilyById,
        getCarById,
        colors,
        dealers,
        sentRequestToTestDrive,
        getCarByName,
        getFamilyByName,
        getDealers
      })}
    >
      {!loading && children}
    </CarsContext.Provider>
  )
}
