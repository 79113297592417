import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const SoundOn: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  const [hover, changeHover] = useState(false)

  const handleOnHover = (state: boolean) => () => {
    changeHover(state)
  }

  const color = hover ? theme.colors.lightBlue : theme.colors.white
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.867 7.495c.742-.546 1.744-.655 2.56-.254.854.4 1.336 1.237 1.373 2.147v12.81c0 .91-.52 1.747-1.336 2.147a2.23 2.23 0 01-1.076.255 2.52 2.52 0 01-1.484-.51l-4.637-3.493h-2.04C5.001 20.597 4 19.614 4 18.413v-5.24c0-1.201 1.002-2.184 2.226-2.184H8.23l4.637-3.494zm1.596 14.885c.11-.073.11-.145.11-.182V9.351c0-.036 0-.109-.11-.182-.112-.036-.186 0-.223.037l-4.934 3.712a1.049 1.049 0 01-.668.218H6.226v5.277l2.412.037c.222 0 .482.072.668.218l4.934 3.676c.074.072.148.072.223.036z"
        fill={color}
        fillOpacity={0.87}
      />
      <path
        d="M21.25 21.427c0 .593.483 1.082 1.069.988a6.743 6.743 0 003.704-1.892 6.75 6.75 0 00-3.704-11.438c-.586-.094-1.069.395-1.069.988s.487 1.06 1.064 1.198a4.617 4.617 0 012.191 1.224 4.604 4.604 0 01-2.191 7.734c-.577.137-1.064.605-1.064 1.198z"
        fill={color}
        fillOpacity={0.87}
      />
      <path
        d="M18.718 18.947c.178.552.778.864 1.274.564a4.501 4.501 0 00.232-7.549c-.477-.33-1.095-.054-1.306.485-.211.54.082 1.138.482 1.557a2.405 2.405 0 01.181 3.102c-.086.114-.182.22-.286.317-.424.394-.754.972-.577 1.524z"
        fill={color}
        fillOpacity={0.87}
      />
    </svg>
  )
}

export default SoundOn
