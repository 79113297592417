import React, { forwardRef, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

import { Column } from '../column'
import { Row } from '../row'
import { ScrollIndicator } from '../scroll-indicator'

const useStyle = createUseStyles({
  detailsContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100vh',
    width: ({ open }) => (open ? 376 : 0),
    transition: ({ open }) =>
      open ? 'width 0.25s ease-in-out 0s' : 'width 0.25s ease-in-out 0.25s'
  },
  detailsInfoContainer: {
    width: '100%',
    padding: '24px 12px 24px 24px'
  },
  scroll: {
    display: 'flex'
  },
  '@media screen and (max-width: 1024px)': {
    detailsInfoContainer: {
      height: '100%',
      padding: 12
    },
    detailsContainer: {
      justifyContent: 'center',
      overflowY: 'hidden',
      maxWidth: 382,
      width: () => '100%',
      height: '100%',
      opacity: ({ open }) => (open ? 1 : 0),
      transition: ({ open }) =>
        open
          ? 'opacity 0.5s ease-in-out 0.5s, height 0.5s ease-in-out 0s'
          : 'opacity 0.5s ease-in-out 0s, height 0.5s ease-in-out 0.5s',
      paddingBottom: 32
    },
    scroll: {
      display: 'none'
    }
  }
})

export type DetailsMenuProps = {
  open: boolean
  children?: ReactNode
}

export const DetailsMenu = forwardRef<HTMLDivElement, DetailsMenuProps>(
  ({ open, children }, ref) => {
    const classes = useStyle({ open })
    return (
      <Row alignItems="flex-start" className={classes.detailsContainer}>
        <Column
          className={classes.detailsInfoContainer}
          justifyContent="flex-start"
          alignItems="flex-start"
          ref={ref}
        >
          {children}
        </Column>
        <ScrollIndicator className={classes.scroll} open={open} />
      </Row>
    )
  }
)
