import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { IconProps } from './types'

export const Huinday: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 1a1 1 0 011-1h30a1 1 0 011 1v23.5a1 1 0 01-1 1h-6.645a1 1 0 00-.63.224l-7.094 5.764a1 1 0 01-1.262 0l-7.093-5.764a1 1 0 00-.631-.224H1a1 1 0 01-1-1V1z"
        fill={theme.colors.lightBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.734 8.028c1.615.94 3.443 2.59 3.252 4.792-.22 2.04-2.01 3.465-3.61 4.323-4.668 2.372-11.225 2.445-16.046.372-1.63-.72-3.458-1.959-4.143-3.82-.525-1.53.114-3.125 1.188-4.185 2.247-2.17 5.11-2.915 8.058-3.344 3.198-.412 6.564-.048 9.428.996.64.251 1.272.526 1.873.866zm-2.711.02c.112.068.246.094.314.221.06.145-.06.238-.127.331-1.548 1.375-3.32 2.14-5.13 2.81-.92.254-1.847.713-2.871.458a.976.976 0 01-.591-.51c-.254-.797.09-1.629.389-2.325.396-.874 1.1-1.901 2.071-1.918 2.094-.017 4.068.34 5.945.934zm-9.905-.102l-.03.119c-1.97 2.377-3.265 5.135-4.493 7.877-.24.238-.472-.08-.69-.183-1.093-.763-2.081-1.995-1.797-3.457.457-1.908 2.307-2.926 3.85-3.617.913-.366 1.864-.66 2.867-.827.105 0 .24-.016.293.088zm13.28 1.898c.931.812 1.772 1.918 1.487 3.254-.465 1.974-2.492 3.024-4.106 3.692-.878.31-1.763.63-2.724.724-.06-.008-.157.008-.173-.087l.023-.112c1.366-1.63 2.402-3.46 3.31-5.315.42-.835.81-1.694 1.17-2.538a.382.382 0 01.166-.12c.337.033.578.311.848.502zm-6.966 4.282c.18 1.047-.307 1.917-.711 2.761-.45.786-1.192 1.461-2.083 1.36-2-.034-3.956-.38-5.769-.996a.361.361 0 01-.21-.17c-.037-.118.016-.227.09-.303 1.656-1.512 3.626-2.213 5.574-2.948.727-.236 1.498-.49 2.315-.371.337.05.674.312.794.667z"
        fill={theme.colors.lightGrey}
      />
    </svg>
  )
}

export default Huinday
