import React, { FC, useRef, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useSwipeable } from 'react-swipeable'

import { ModelTypeBlockType } from '../../providers'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { ModelTypeBlock } from '../model-type-block'
import { OptionsDot } from '../options-dot'
import { Row } from '../row'
import { Text } from '../text'

const useStyle = createUseStyles({
  modelInfoContainer: {
    width: '100%'
  },
  descriptionContainer: {
    margin: 0,
    marginTop: 40,
    padding: '0 18px'
  },
  desctiptionItem: {
    textAlign: 'left'
  },
  dotsContainer: {
    width: '100%',
    marginTop: 16
  },
  modelTypeContainer: {
    marginTop: 16,
    position: 'relative',
    width: '100%',
    height: 248,
    overflow: 'hidden'
  },
  modelTypeSlideContainer: {
    width: '100%',
    left: ({ scroll }) => -scroll,
    position: 'absolute',
    transition: 'left 0.5s ease-in-out'
  },
  leftArrow: {
    position: 'absolute',
    left: 0,
    right: 'auto',
    transform: 'rotate(180deg)'
  },
  arrow: {
    position: 'absolute',
    top: 100,
    right: 0,
    zIndex: 2,
    cursor: 'pointer'
  }
})

export type ModelInfoEquipmentProps = {
  options: ModelTypeBlockType[]
}

export const ModelInfoEquipment: FC<ModelInfoEquipmentProps> = ({
  options
}) => {
  if (!options[0]) {
    return <></>
  }
  const modelTypeBlockContainerRef = useRef<HTMLDivElement>(null)
  const [active, changeActive] = useState(options[0])
  const [activeIndex, changeActiveIndex] = useState(0)
  const [scroll, changeScroll] = useState(0)
  const theme: LightTheme = useTheme()

  const classes = useStyle({ scroll })

  const handleOnChangeActiveOption = (
    option: ModelTypeBlockType,
    index: number
  ) => () => {
    const parent = modelTypeBlockContainerRef.current
    if (parent) {
      const element = parent.children[index] as HTMLDivElement
      if (element) {
        if (element.offsetLeft === 0) {
          changeScroll(0)
        } else {
          changeScroll(element.offsetLeft - 20)
        }
      }
    }
    changeActiveIndex(index)
    changeActive(option)
  }

  const config = {
    delta: 20, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0 // set a rotation angle
  }

  const handleOnSwipedLeft = () => {
    const nextIndex =
      options.length > activeIndex + 1 ? activeIndex + 1 : activeIndex
    const option = options[nextIndex]
    handleOnChangeActiveOption(option, nextIndex)()
  }

  const handleOnSwipedRight = () => {
    const nextIndex = activeIndex > 0 ? activeIndex - 1 : activeIndex
    const option = options[nextIndex]
    handleOnChangeActiveOption(option, nextIndex)()
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnSwipedLeft,
    onSwipedRight: handleOnSwipedRight,
    ...config
  })

  return (
    <Column className={classes.modelInfoContainer}>
      <Row className={classes.modelTypeContainer} {...handlers}>
        <Icon
          className={`${classes.leftArrow} ${classes.arrow}`}
          name={IconName.ARROW}
          fill={theme.colors.second}
          onClick={handleOnSwipedRight}
        />
        <Row
          className={classes.modelTypeSlideContainer}
          justifyContent="flex-start"
          ref={modelTypeBlockContainerRef}
        >
          {options.map((option, index) => (
            <ModelTypeBlock
              key={`option_${option.id}`}
              id={option.id}
              title={option.title}
              description={option.description}
              catalog={option.catalog}
              price={option.price}
              fuelsNl={option.fuelsNl}
              fuelsFr={option.fuelsFr}
              onClick={handleOnChangeActiveOption(option, index)}
            />
          ))}
        </Row>
        <Icon
          className={classes.arrow}
          name={IconName.ARROW}
          fill={theme.colors.second}
          onClick={handleOnSwipedLeft}
        />
      </Row>
      <Row className={classes.dotsContainer} justifyContent="center">
        {options.map((option, index) => (
          <OptionsDot
            key={`option_dot_${option.id}`}
            active={option.id === active.id}
            onClick={handleOnChangeActiveOption(option, index)}
          />
        ))}
      </Row>
      <ul className={classes.descriptionContainer}>
        {active.description.map((descriptionProp, index) => (
          <li key={`desription_${index}`} className={classes.desctiptionItem}>
            <Text innerHtml text={descriptionProp} preset="subscription" />
          </li>
        ))}
      </ul>
    </Column>
  )
}
