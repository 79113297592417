import { isIOS } from 'react-device-detect'

import { Car } from '../../services/api.types'
import { ModelInfoType } from './data-context.types'

export const formatPrice = (price: string) =>
  new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 }).format(
    Number(price)
  )

export const getValueFromModelName = (modelName: string) =>
  modelName.split(' ').join('_').toLowerCase()

export type GetModelOptions = {
  isLux: boolean
  isFr: boolean
}

export const getModelItemFromCar = (
  car: Car | undefined,
  options: GetModelOptions
): ModelInfoType | null => {
  const { isLux, isFr } = options
  if (car) {
    const {
      id: carId,
      colors: carColors,
      colorsWithName: carColorsWithName,
      modelName,
      modelTrims,
      modelTrimsLu,
      defaultModelTrim,
      defaultModelTrimLu,
      defaultColor,
      discountTime,
      introFr,
      introNl,
      configuratorFr,
      configuratorNl,
      productInformationPageFr,
      productInformationPageNl,
      offerPagesFr,
      offerPagesNl,
      arCore,
      arKit
    } = car

    const trims = isLux ? modelTrimsLu : modelTrims
    const defaultTrim = isLux ? defaultModelTrimLu : defaultModelTrim

    const {
      totalPrice,
      catalog,
      specialJanuaryOffer,
      subtotalPrice,
      ecobon
    } = defaultTrim
    return {
      id: carId,
      ar: isIOS ? arKit : arCore,
      title: modelName,
      value: getValueFromModelName(modelName),
      price: formatPrice(totalPrice),
      catalogPrice: formatPrice(catalog),
      colors: carColors,
      colorsWithName: carColorsWithName,
      defaultColor,
      options: trims.map((trim) => ({
        ...trim,
        id: trim.id,
        title: trim.modelTrim,
        description: isFr ? trim.uspFr : trim.uspNl,
        fuelsNl: trim.fuelsNl,
        fuelsFr: trim.fuelsFr,
        price: `€ ${formatPrice(trim.totalPrice)} -`,
        catalog: ` (${formatPrice(trim.catalog)})`
      })),
      prices: [
        {
          name: 'Prices_01',
          discountName: 'Prices_02',
          value: `€ ${formatPrice(catalog)}`,
          discountPrice: `- € ${formatPrice(specialJanuaryOffer)}`
        },
        {
          name: 'Prices_03',
          discountName: 'Prices_04',
          value: `€ ${formatPrice(subtotalPrice)}`,
          discountPrice: `- € ${formatPrice(ecobon)}`
        }
      ],
      fullPrice: `€ ${formatPrice(totalPrice)}`,
      discountTime: discountTime || '30',
      intro: isFr ? introFr : introNl,
      configurator: isFr ? configuratorFr : configuratorNl,
      productInformationPage: isFr
        ? productInformationPageFr
        : productInformationPageNl,
      offer: isFr ? offerPagesFr : offerPagesNl
    }
  }

  return null
}
