import React, { FC, ChangeEvent, useState, useRef, FocusEvent } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Text } from '../text/text'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(100% - 40px)',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.pink}`,
    padding: '14px 16px',
    borderRadius: 4,
    height: '100%'
  },
  valueText: {
    ...theme.typography.h7,
    backgroundColor: theme.colors.transparent,
    color: theme.colors.main,
    marginTop: 4,
    border: 'none',
    width: '100%',
    zIndex: 2,
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  label: {
    zIndex: 1,
    transform: ({ focus, value }) =>
      focus || value ? 'scale(1)' : 'scale(1.1667) translate(6px,10px)',
    transition: 'transform 0.5s ease-in-out'
  }
}))

export type TextFieldProps = {
  id?: string
  className?: string
  defaultValue?: string
  label: string
  labelTx?: string
  onChange: (value: string) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

export const TextField: FC<TextFieldProps> = ({
  id,
  className = '',
  defaultValue = '',
  label,
  labelTx,
  onChange,
  onFocus,
  onBlur
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>(defaultValue)
  const [focus, setFocus] = useState(false)
  const theme = useTheme()
  const classes = useStyle({ theme, value, focus })

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue } = event.target
    setValue(eventValue)
    onChange(eventValue)

    event.stopPropagation()
  }

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocus(true)
    if (onFocus) {
      onFocus(event)
    }
  }

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocus(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  const handleOnClick = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      alignItems="flex-start"
      onClick={handleOnClick}
    >
      <Text
        className={classes.label}
        preset="text"
        text={label}
        tx={labelTx}
        color="title"
      />
      <input
        id={id}
        className={classes.valueText}
        value={value}
        name={id}
        type="text"
        ref={ref}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </Column>
  )
}
