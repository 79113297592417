import React, { FC, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useIntl } from 'react-intl'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'

import { Header, TestRequestForm } from '../../components'
import { config } from '../../config'
import { useCars } from '../../providers'

const useStyle = createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 4,
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
})

export type TestRequestPageProps = {
  className?: string
  classNameHeader?: string
  chatButton?: boolean
  chatClassName?: string
  onOpenHub?: () => void
  onBack?: () => void
}

export const TestRequestPage: FC<TestRequestPageProps> = ({
  classNameHeader,
  onBack
}) => {
  const location = useLocation()
  const { getCarByName } = useCars()
  const splitUrl = location.pathname.split('/')
  const carName = splitUrl[4]
  const familyName = splitUrl[2]
  const carData = getCarByName(familyName, carName)

  if (!carData) {
    return <></>
  }

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        virtualPageTitle: 'Request Test Drive',
        virtualPageURL: `${config.publicURL}/family/${familyName}/car/${carName}/test-drive-request`,
        event: 'VirtualPageview'
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }, [])
  const classes = useStyle()
  const [menuVisible, changeMenuVisible] = useState(false)
  const intl = useIntl()
  const visitors = 175
  const message = intl.formatMessage({
    id: 'main.visitors',
    defaultMessage: 'visitors in the showroom right now'
  })

  const visitorsCounterMessage = `${visitors} ${message}`

  const handleOnOpenStateMenu = () => {
    changeMenuVisible(!menuVisible)
  }

  const handleOnBack = () => {
    if (onBack) {
      onBack()
    }
  }

  return (
    <div className={classes.container}>
      <Header
        back
        className={classNameHeader}
        visitors={visitorsCounterMessage}
        onClick={handleOnOpenStateMenu}
        onBackClick={handleOnBack}
      />
      <TestRequestForm />
    </div>
  )
}
