import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import './App.css'
import { lightTheme } from './theme'
import { LocaleProvider } from './components'
import { MainContainer } from './containers'
import {
  AudioBackgroundProvider,
  CarsProvider,
  UnityProvider,
  WhisbiProvider
} from './providers'
import { config } from './config'
import { Locale } from './services'

// @ts-ignore
import soundfile from './assets/sound/loader.mp3'

const App = () => {
  const locale = config.country !== 'LUX' ? Locale.NL : Locale.FR

  return (
    <Router>
      <LocaleProvider locale={locale}>
        <WhisbiProvider>
          <CarsProvider>
            <UnityProvider>
              <AudioBackgroundProvider audio={soundfile}>
                <ThemeProvider theme={lightTheme}>
                  <MainContainer />
                </ThemeProvider>
              </AudioBackgroundProvider>
            </UnityProvider>
          </CarsProvider>
        </WhisbiProvider>
      </LocaleProvider>
    </Router>
  )
}

const tagManagerArgs = {
  gtmId: 'GTM-WHXKMSC',
  dataLayerName: 'dataLayer',
  events: {
    VirtualPageview: 'VirtualPageview'
  }
}

TagManager.initialize(tagManagerArgs)

export default App
