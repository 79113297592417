import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { ButtonProps } from '../button'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { ChatAvatar, ChatAvatarImageProps } from './chat-avatar'
import { ChatForm } from './chat-form'

export type ChatWidgetProps = {
  className?: string
}

const useStyle = createUseStyles({
  form: {
    margin: 8
  },
  avatar: {
    margin: 4
  },
  text: {
    margin: 4
  },
  '@media screen and (max-width: 1024px)': {
    form: {
      display: 'none'
    },
    text: {
      display: 'none'
    }
  }
})

export const ChatWidget: FC<ChatWidgetProps> = ({ className }) => {
  const classes = useStyle()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnClick = (_: any) => {}
  const imageProps: ChatAvatarImageProps = {
    src:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/User_font_awesome.svg/1200px-User_font_awesome.svg.png'
  }
  const buttonProps: ButtonProps = {
    text: {
      color: 'second',
      text: "Yes, let's talk now",
      tx: 'chat.form.button'
    },
    preset: 'outlined',
    onClick: handleOnClick
  }
  return (
    <Row className={className} alignItems="flex-end">
      <ChatForm
        name="Robert Dubois"
        question="Do you have any questions? Get answers rights now"
        timeDefaultText="Just now"
        timeTx="chat.form.time"
        button={buttonProps}
        className={classes.form}
      />
      <Column justifyContent="flex-end">
        <Text
          className={classes.text}
          tx="chat.form.live"
          text="LIVE"
          preset="miniStatusLabel"
        />
        <ChatAvatar className={classes.avatar} img={imageProps} />
      </Column>
    </Row>
  )
}
