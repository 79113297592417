import React, { FC, ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../theme'
import { Column } from '../column'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.pink,
    width: 'calc(100% - 40px)',
    padding: '8px 16px',
    borderRadius: 4
  },
  valueText: {
    resize: 'none',
    height: 160,
    ...theme.typography.h7,
    color: theme.colors.main,
    backgroundColor: theme.colors.transparent,
    width: '100%',
    border: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  }
}))

export type TextAreaProps = {
  id?: string
  className?: string
  placeholder: string
  placeholderTx?: string
  onChange: (value: string) => void
}

export const TextArea: FC<TextAreaProps> = ({
  id,
  className = '',
  placeholder,
  placeholderTx,
  onChange
}) => {
  const intl = useIntl()
  const message = intl.formatMessage({
    id: placeholderTx || 'test.description.placeholder',
    defaultMessage: placeholder || 'Write here your remarks'
  })
  const [value, setValue] = useState<string>('')
  const theme = useTheme()
  const classes = useStyle({ theme, value })

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: eventValue } = event.target
    setValue(eventValue)
    onChange(eventValue)

    event.stopPropagation()
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      alignItems="flex-start"
    >
      <textarea
        id={id}
        className={classes.valueText}
        value={value}
        name={id}
        placeholder={message}
        onChange={handleOnChange}
      />
    </Column>
  )
}
