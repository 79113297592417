import React, { FC, MouseEvent, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { config } from '../../config'
import { ModelTypeBlockType } from '../../providers'
import { CarColor } from '../../services/api.types'
import { LightTheme } from '../../theme'
import { Button } from '../button'
import { CityCategoryPicker as CategoryPicker } from '../city-category-picker'
import { Column } from '../column'
import { Link } from '../link'
import { Text, TextProps } from '../text'
import { ModelInfoEquipment } from './model-info-equipment'
import { ModelInfoPrices, ModelInfoPricesProps } from './model-info-prices'

const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    paddingBottom: 120
  },
  secondTitle: {
    marginTop: 40,
    textAlign: 'left'
  },
  secondDescription: {
    width: '100%',
    marginTop: 12,
    marginBottom: 52
  },
  testDriveContainer: {
    textAlign: 'left',
    width: '100%',
    marginTop: 40,
    paddingBottom: 128
  },
  buttonContainer: {
    width: 280,
    position: 'fixed',
    bottom: 0,
    background: `linear-gradient(180deg, ${theme.colors.white}23 -16.02%, ${theme.colors.white} 62.5%);`,
    padding: '40px 16px',
    zIndex: 125
  },
  button: {
    backgroundColor: theme.colors.white,
    width: '100%'
  },
  configurator: {
    display: 'inline-block',
    marginRight: 4,
    textDecoration: 'underline',
    textDecorationColor: theme.colors.second,
    textAlign: 'left'
  },
  configuratorText: {
    display: 'inline-block',
    marginRight: 4,
    textAlign: 'left'
  },
  question: {
    marginTop: 8
  },
  liDot: {
    color: theme.colors.title
  },
  '@media screen and (max-width: 1024px)': {
    buttonContainer: {
      width: 326,
      maxWidth: 'calc(100vw - 56px)'
    }
  }
}))

export interface ModelInfoType extends ModelInfoPricesProps {
  id: string
  ar?: string
  title: string
  value: string
  price: string
  catalogPrice: string
  colors: string[]
  colorsWithName: CarColor[]
  defaultColor: string
  options: ModelTypeBlockType[]
  intro: string
  configurator: string
  productInformationPage: string
  offer: string
}

export interface ModelInfoProps extends ModelInfoType {
  open: boolean
  onOpenTest: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void
}

export const ModelInfo: FC<ModelInfoProps> = ({
  title,
  options,
  prices,
  fullPrice,
  discountTime,
  configurator,
  offer,
  productInformationPage,
  open,
  onOpenTest
}) => {
  const isLux = config.country === 'LUX'
  const questionTx = isLux ? 'disclaimer.06' : 'disclaimer.02'
  const [activeCategory, changeActiveCategory] = useState(0)
  const theme = useTheme()
  const classes = useStyle({ theme })
  const firstElementCategoryPicker = {
    tx: 'city.details.equipment',
    text: 'Equipment'
  }

  const secondElementCategoryPicker = {
    eco: false,
    tx: 'city.details.price',
    text: 'Price'
  }

  const buttonText: TextProps = {
    text: 'Test Drive',
    tx: 'city.details.button',
    color: 'second'
  }

  const handleOnClickCategory = (id: number) => {
    changeActiveCategory(id)
  }

  const txValues = {
    model_name: title
  }

  return (
    <Column
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <CategoryPicker
        firstElement={firstElementCategoryPicker}
        secondElement={secondElementCategoryPicker}
        onClick={handleOnClickCategory}
      />
      {activeCategory === 0 ? (
        <ModelInfoEquipment options={options} />
      ) : (
        <ModelInfoPrices
          prices={prices}
          fullPrice={fullPrice}
          discountTime={discountTime}
          offer={offer}
        />
      )}
      <Text
        tx="Questions?"
        text="Questions?"
        preset="h4"
        className={classes.secondTitle}
      />
      <Column
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.secondDescription}
      >
        <li className={classes.liDot}>
          <Text
            className={classes.question}
            tx="All.Cars.Q1"
            text="All.Cars.Q1"
            preset="title"
            color="title"
          />
        </li>
        <li className={classes.liDot}>
          <Text
            className={classes.question}
            tx="All.Cars.Q2"
            text="All.Cars.Q2"
            preset="title"
            color="title"
          />
        </li>
        <li className={classes.liDot}>
          <Text
            className={classes.question}
            tx="All.Cars.Q3"
            text="All.Cars.Q3"
            preset="title"
            color="title"
          />
        </li>
      </Column>
      <Column
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.testDriveContainer}
      >
        <Text
          tx="city.details.test.title"
          text="Looking for more information"
          preset="h4"
        />

        <div>
          <Text
            className={classes.configuratorText}
            tx="city.details.test.description.06"
            text="Check the"
            preset="h6"
            color="second"
          />
          <Link
            className={classes.configurator}
            tx="city.details.test.description.07"
            text="Model name"
            href={productInformationPage}
            values={txValues}
            preset="h6"
            color="second"
          />
          <Text
            className={classes.configuratorText}
            tx="city.details.test.description.03"
            text="page or"
            preset="h6"
            color="second"
          />
          <Link
            className={classes.configurator}
            href={configurator}
            tx="city.details.test.description.04"
            text="configure"
            preset="h6"
            color="second"
          />
          <Text
            className={classes.configuratorText}
            tx="city.details.test.description.05"
            text="your own."
            preset="h6"
            color="second"
          />
        </div>
        <Column
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.secondDescription}
        >
          <Text
            className={classes.question}
            tx="disclaimer.01"
            text="disclaimer.01"
            preset="title"
            color="title"
          />
          <Text
            className={classes.question}
            tx={questionTx}
            text={questionTx}
            preset="title"
            color="title"
          />
          <Text
            className={classes.question}
            tx="disclaimer.03"
            text="disclaimer.03"
            preset="title"
            color="title"
          />
          <Text
            className={classes.question}
            tx="disclaimer.04"
            text="disclaimer.04"
            preset="title"
            color="title"
          />
        </Column>
        {open && (
          <Column className={classes.buttonContainer} onClick={onOpenTest}>
            <Button
              className={classes.button}
              borderColor="second"
              text={buttonText}
              preset="outlined-big"
              onClickCapture={onOpenTest}
            />
          </Column>
        )}
      </Column>
    </Column>
  )
}
