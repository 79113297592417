// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from './api-problem'
import * as Types from './api.types'

// our "constructor"
export const create = (
  baseURL = 'http://hyundai-env.eba-vmhi3nup.eu-central-1.elasticbeanstalk.com/api'
) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      'Access-Control-Allow-Headers': 'X-Requested-With'
    },
    // 10 second timeout...
    timeout: 10000
  })
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const getRoot = () => api.get('')

  const getCars = async ({
    limit,
    offset
  }: Types.ListOptions): Promise<Types.GetCarsResults> => {
    const response = await api.get<Types.GetCarsResponse>(
      '/car/model-trim/list',
      {
        limit,
        offset
      }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = camelcaseKeys(response.data || [], {
        deep: true
      }) as Types.GetCarsResponse
      return { kind: 'ok', cars: data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getFamily = async ({
    limit,
    offset
  }: Types.ListOptions): Promise<Types.GetFamilyResults> => {
    const response = await api.get<Types.GetFamilyResponse>(
      '/car/model-family/list',
      {
        limit,
        offset
      }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = camelcaseKeys(response.data || [], {
        deep: true
      }) as Types.GetFamilyResponse
      return { kind: 'ok', family: data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getColors = async ({
    limit,
    offset
  }: Types.ListOptions): Promise<Types.GetColorsResults> => {
    const response = await api.get<Types.GetColorsResponse>('/car/color/list', {
      limit,
      offset
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = camelcaseKeys(response.data || [], {
        deep: true
      }) as Types.GetColorsResponse
      return { kind: 'ok', colors: data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getAmbientSound = async (): Promise<Types.GetAudioResults> => {
    const response = await api.get<Types.GetAudioResponse>(
      '/car/ambient-sound/list',
      {}
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = camelcaseKeys(response.data || [], {
        deep: true
      }) as Types.GetAudioResponse
      return { kind: 'ok', audio: data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getLocale = async (
    locale: Types.Locale
  ): Promise<Types.GetLocaleResults> => {
    const response = await api.get<Types.GetLocaleResponse>(
      '/core/localization',
      {
        language: locale
      }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = response.data as Types.GetLocaleResponse
      return { kind: 'ok', locale: data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const sentRequestToTestDrive = async (
    data: Types.TestDriveData
  ): Promise<void> => {
    await api.post<any>('/car/test-drive', {
      gender: data.gender,
      firstname: data.firstName,
      name: data.lastName,
      mail: data.email,
      phone: data.phoneNumber,
      model: data.model,
      dealer: data.dealer,
      language: data.language,
      country: data.country,
      remarks: data.description,
      optin: data.policy ? 1 : 0
    })
  }

  const sentCityOnNearDealer = async (
    city: string
  ): Promise<Types.GetDealersResults> => {
    const response = await api.get<Types.GetDealersResponse>(
      '/car/dealer/list',
      {
        city
      }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      const data = camelcaseKeys(response.data || [], {
        deep: true
      }) as Types.GetDealersResponse
      return { kind: 'ok', dealers: data.results }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getRate = () => api.get('rate_limit')
  const getUser = (username: string) =>
    api.get('search/users', {
      q: username
    })

  const setHeader = (key: string, prop: any) => {
    const headers = { ...api.headers, [key]: prop }
    api.setHeaders(headers)
  }
  const setToken = (token: string) => {
    if (token) {
      api.setHeader('Authorization', `Bearer ${token}`)
    } else {
      api.setHeader('Authorization', '')
    }
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getCars,
    getFamily,
    getColors,
    getAmbientSound,
    getLocale,
    sentRequestToTestDrive,
    sentCityOnNearDealer,
    getRoot,
    getRate,
    getUser,
    setHeader,
    setToken
  }
}
